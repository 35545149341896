import { AlertProps } from "@chakra-ui/alert"
import { Alert, CloseButton, HStack, Text, VStack } from "@chakra-ui/react"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "../../../../components/Button"

interface ViewProps extends AlertProps {
  onClick: () => void
  onClose: () => void
  success: boolean
}

const ViewTransactionAlert = ({ onClick, onClose, success }: ViewProps) => {
  return (
    <Alert
      data-testid="notification_toast_confirmed"
      variant="ledger"
      w="fit-content"
      ml="auto"
      bgColor={success ? "green.main" : "red.main"}
    >
      <VStack>
        <HStack w="full" justifyContent={"space-between"}>
          <Text ml={1}>{success ? "Transaction Confirmed!" : "Transaciton Failed"}</Text>
          <CloseButton onClick={onClose} />
        </HStack>
        <HStack justifyContent={"space-between"}>
          <Button
            data-testid="view_transaction"
            size="sm"
            bg="transparent"
            onClick={onClick}
            borderRadius="12px"
            border="1px solid white"
            rightIcon={<FontAwesomeIcon icon={faArrowRight} />}
          >
            View transaction
          </Button>
        </HStack>
      </VStack>
    </Alert>
  )
}

export default ViewTransactionAlert
