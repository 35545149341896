import { Box, SimpleGrid, VStack, HeadingProps } from "@chakra-ui/layout"
import { Button, ButtonProps, chakra, Heading, SkeletonText } from "@chakra-ui/react"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faArrowRight, faGlobeAmericas } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { useHistory } from "react-router"
import { ListingCategory, useFindAllCategoriesQuery } from "../../../../generated/graphql"
const CategoryList = ({ ...rest }) => {
  const history = useHistory()
  const { data, loading } = useFindAllCategoriesQuery()
  const categories = (data?.findManyCategory?.categories ?? []) as ListingCategory[]
  const navigate = (searchText?: string) => history.push("/marketplace/listings", { searchText })
  const [isTitle, setIsTitle] = useState(true)
  const [titleValue, setTitleValue] = useState("Browse by category")

  return (
    <VStack align="flex-start" {...rest} my="60px" px="15px" bg="white">
      <Box pos="relative" h="90px" zIndex="1">
        <Heading {...headingStyles} border={isTitle ? "6px solid transparent" : "none"}>
          {titleValue}
        </Heading>
      </Box>
      {loading && (
        <Box py="20px" h="300px" w="full">
          <SkeletonText spacing={8} noOfLines={6} />
        </Box>
      )}
      <Box overflowX="auto" maxW="full" w="full" pb={4} className="webkitbox">
        <SimpleGrid minW="1235px" columns={4} rows={4} px={4} spacing={4}>
          {categories.map((c) => (
            <CategoryButton key={c.id} category={c} navigate={navigate} />
          ))}
          <Button
            data-testid="view_all_listings_btn"
            variant="category"
            leftIcon={<FontAwesomeIcon style={{ marginRight: "8px" }} icon={faGlobeAmericas} />}
            rightIcon={<FontAwesomeIcon icon={faArrowRight} />}
            onClick={() => window.open(`marketplace/listings`)}
          >
            View all listings
          </Button>
        </SimpleGrid>
      </Box>
    </VStack>
  )
}

interface CategoryButtonProps extends ButtonProps {
  category: ListingCategory
  navigate: (categoryText: string) => void
}

const CategoryButton = ({ category }: CategoryButtonProps) => {
  const icon = (category?.icon ?? "ellipsis-h") as IconProp
  return (
    <Button
      data-testid="search_by_category_btn"
      variant="category"
      leftIcon={<FontAwesomeIcon style={{ marginRight: "8px" }} icon={icon} />}
      onClick={() => {
        localStorage.setItem("category", category.name)
        window.open(`marketplace/listings`)
      }}
    >
      <chakra.span textOverflow={"ellipsis"} overflow={"hidden"}>
        {category.name}
      </chakra.span>
    </Button>
  )
}

const headingStyles: HeadingProps = {
  fontSize: "36px",
  color: "#595959",
  fontWeight: "600",
  p: 2,
}
export default CategoryList
