import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import "./styles.css"

type CustomDraggableProps = {
  direction: string
  listClass?: string
  componentPosition?: []
  handleOnDragEnd?: (result: Record<string, unknown>) => void
}

const CustomDraggable = (props: CustomDraggableProps) => {
  const { direction, listClass, componentPosition, handleOnDragEnd } = props

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="components" direction={direction}>
        {(provided) => {
          return (
            <ul
              className={listClass}
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                gridTemplateColumns: "1fr 1fr 1fr",
                gridGap: " 0px",
              }}
            >
              {componentPosition?.map((component, index) => {
                return (
                  <Draggable key={index} draggableId={`${index}`} index={index}>
                    {(provided, snapshot) => {
                      return (
                        <li
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={snapshot.isDragging ? "grab " : "handPointer"}
                        >
                          {component}
                        </li>
                      )
                    }}
                  </Draggable>
                )
              })}
              {provided.placeholder}
            </ul>
          )
        }}
      </Droppable>
    </DragDropContext>
  )
}

export default CustomDraggable
