import axios from "axios"
import config from "../../../config"
export const ImageUpload = async (Images) => {
  const form = new FormData()
  form.append("image", Images)
  try {
    const res = await axios({
      method: "POST",
      url: config.API_ENDPOINTS.UPLOAD,
      data: form,
      headers: { "content-type": "multipart/form-data" },
    })

    return res.data.path
  } catch (e) {
    console.error("upload -- err:", e)
    return { error: true, data: null }
  }
}
