import { Alert, AlertProps, CloseButton, Text } from "@chakra-ui/react"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

interface ConfirmAlertProps extends AlertProps {
  transactionId?: string
  onClose: any
}

const ConfirmingTransactionAlert = ({ onClose }: ConfirmAlertProps) => {
  return (
    <Alert w="full" variant="ledger">
      <FontAwesomeIcon icon={faSpinner} color="white" spin />
      <Text ml={"1em"}>Confirming transaction</Text>
      <CloseButton ml="auto !important" justifySelf="flex-end" onClick={onClose} />
    </Alert>
  )
}

export default ConfirmingTransactionAlert
