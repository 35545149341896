import { Box, BoxProps, Heading, Stack, StackProps } from "@chakra-ui/layout"
import { useEffect, useState } from "react"
import CloudinaryImage from "../../../../components/CloudinaryImage"
import { Catalog, UpdateCatalogDocument } from "../../../../generated/graphql"
import SearchBar from "./SearchBar"
import HeaderButtons from "./HeaderButtons"
import { headingStyle } from "./SharedStyles"
import EditTitle from "./EditTitle"
import { CatalogMutation } from "../CatalogMutation"
import { useMutation } from "@apollo/client"
import { ImageUpload } from "../../utils/ImageUpload"
import { useToast } from "@chakra-ui/react"
interface Props extends BoxProps {
  catalog: Catalog
}

const CatalogFooter = ({ catalog }: Props) => {
  const { footerImage, footerTitle } = catalog
  const [isEdit, setIsEdit] = useState(false)
  const [isTitle, setIsTitle] = useState(true)
  const [inputValue, setInputValue] = useState(footerTitle)
  const [url, setUrl] = useState(footerImage)
  const [catalogInput] = useMutation(UpdateCatalogDocument)
  const toast = useToast()

  useEffect(() => {
    setUrl(footerImage)
    setInputValue(footerTitle)
  }, [catalog, footerImage, footerTitle])

  const handleEdit = () => {
    setIsEdit(true)
    setIsTitle(false)
  }

  const handleImage = async (Images, searchImage) => {
    let updatedCatalog = catalog

    if (searchImage) {
      updatedCatalog = { ...catalog, footerImage: searchImage }
      CatalogMutation(catalogInput, updatedCatalog, toast)
      setUrl(searchImage)
    }
    if (Images) {
      const url = await ImageUpload(Images)
      updatedCatalog = { ...catalog, footerImage: url }
      CatalogMutation(catalogInput, updatedCatalog, toast)
      setUrl(url)
    }
  }

  const handleSubmit = () => {
    if (inputValue) {
      setIsTitle(true)
      setIsEdit(false)
      setInputValue(inputValue)
      let updatedCatalog = catalog
      updatedCatalog = { ...catalog, footerTitle: inputValue }
      CatalogMutation(catalogInput, updatedCatalog, toast)
    }
  }

  return (
    <Stack {...mainStackStyles}>
      <Stack {...stackStyles}>
        <CloudinaryImage
          h={height}
          w="full"
          pos="absolute"
          objectFit="cover"
          rounded="2xl"
          zIndex={-2}
          quality="good"
          src={url}
          filter={isEdit ? "brightness(20%)" : "brightness(50%)"}
        />

        <Stack {...headingStackStyles}>
          <Heading
            color={isTitle ? "black" : "transparent"}
            className="heading"
            {...headingStyle}
            onClick={handleEdit}
            px="2"
          >
            {inputValue ? inputValue : "Footer Title"}
          </Heading>
        </Stack>
        <Box filter={isEdit ? "brightness(40%)" : "brightness(100%)"} zIndex={1}>
          <SearchBar />
        </Box>

        <Stack justifyContent="flex-end" alignItems="flex-end" w="full" pos="absolute">
          <Box mt="18rem" mx="30px">
            <HeaderButtons ImageHandler={handleImage} />
          </Box>
        </Stack>
      </Stack>
      <Box display={isEdit ? "block" : "none"} {...boxStyles}>
        <EditTitle
          setIsTitle={setIsTitle}
          isTitle={isTitle}
          setIsEdit={setIsEdit}
          setValue={setInputValue}
          coverTitle={footerTitle}
          value={inputValue}
          data={{ footerTitle: inputValue }}
          catalogInput={catalogInput}
          catalog={catalog}
          handleSubmit={handleSubmit}
        />
      </Box>
    </Stack>
  )
}

const height = {
  base: "190px",
  md: "400px",
}

const stackStyles: StackProps = {
  position: "relative",
  p: 4,
  h: height,
  justifyContent: "center",
  alignItems: "center",
  w: "full",
  _hover: { shadow: "lg", outline: "12px solid #699dff" },
  outline: "12px solid transparent",
  outlineOffset: "-10px",
  rounded: "16px",
}
const mainStackStyles: StackProps = {
  alignItems: "center",
  justifyContent: "center",
  mt: "50px",
  px: "5",
  mb: "5",
}
const headingStackStyles: StackProps = {
  maxH: "70px",
  pb: "30px",
  alignItems: "center",
  justifyContent: "flex-start",
  w: "600px",
  zIndex: 1,
}
const boxStyles: BoxProps = {
  w: "600px",
  pos: "absolute",
  pb: "10px",
  zIndex: 1,
}
export default CatalogFooter
