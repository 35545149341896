import { Heading } from "@chakra-ui/layout"
import { Container, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react"
import { useRef } from "react"
import { Link, Redirect, useLocation } from "react-router-dom"
import { getPageTitle } from "../../../components/PageTitle"
import { BusinessDirectory } from "../components/BusinessDirectory"
import { ListingDirectory } from "../components/ListingDirectory"

const paths = ["/marketplace/listings", "/marketplace/businesses"]

const DirectoriesPage = () => {
  const containerRef = useRef<HTMLDivElement>(null)
  const currentPath = useLocation().pathname
  const tabNumber = paths.indexOf(currentPath)
  document.title = getPageTitle("Marketplace")

  if (tabNumber === -1) return <Redirect to="/marketplace/listings" />

  return (
    <>
      <Tabs
        id="tabs-ref"
        ref={containerRef}
        index={tabNumber}
        isLazy={true}
        colorScheme="primary"
        variant="line"
      >
        <Container maxW="container.xl" px=" 0">
          <TabList justifyContent={{ base: "center", md: "inherit" }} pt={{ base: 2, md: 8 }}>
            <Tab data-testid="tab_listing">
              <Link to="/marketplace/listings">
                <Heading size="subtitle">Listings</Heading>
              </Link>
            </Tab>
            <Tab data-testid="tab_businesses">
              <Link to="/marketplace/businesses">
                <Heading size="subtitle">Businesses</Heading>
              </Link>
            </Tab>
          </TabList>
        </Container>
        <TabPanels>
          <TabPanel px={0} pb={0}>
            <ListingDirectory isCategory={true} />
          </TabPanel>
          <TabPanel px={0} pb={0}>
            <BusinessDirectory isCategory={true} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  )
}

export default DirectoriesPage
