import { SearchIcon } from "@chakra-ui/icons"
import { Box, Input, InputGroup, InputRightElement, Text } from "@chakra-ui/react"
import { debounce } from "lodash"
import { useCallback, useEffect, useState } from "react"
import { useRecoilState } from "recoil"
import { listingQuerySelector as listingSelector } from "../../../store/listing"
import { cardStyles } from "./BusinessCard"
const ListingSearchBar = ({ isCategory }) => {
  const searchParam = isCategory ? (localStorage.getItem("category") as any) : ""

  const [{ searchText }, setListingQuery] = useRecoilState(listingSelector)
  const [localText, setLocalText] = useState("")
  const search = useCallback(
    (text: string) => {
      setListingQuery((prevState) => ({ ...prevState, page: 1, searchText: text }))
    },
    [setListingQuery],
  )

  useEffect(() => {
    setLocalText(searchText)
  }, [searchText])

  useEffect(() => {
    search(searchParam)
  }, [searchParam])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(debounce(search, 750), [search])

  const handleKeyPressed = (event) => {
    if (event.key === "Enter") search(localText)
  }

  return (
    <Box w={{ base: cardStyles.w, md: "initial" } as any}>
      <Text px={4} mb={3} fontWeight="500">
        I am looking for...
      </Text>

      <InputGroup bg="white">
        <Input
          onChange={(e) => {
            setLocalText(e.target.value)
            debouncedSearch(e.target.value)
          }}
          onKeyPress={handleKeyPressed}
          placeholder={`Search listings`}
          value={localText}
          bg="white"
          border={"1px solid #dddddd"}
          fontSize="18px"
          fontWeight="500"
        />
        <InputRightElement zIndex={1} pointerEvents="none">
          <SearchIcon />
        </InputRightElement>
      </InputGroup>
    </Box>
  )
}

export default ListingSearchBar
