/* eslint-disable react/display-name */
import { useToast, UseToastOptions } from "@chakra-ui/react"

export const useLedgerToast = () => {
  const ledgerOptions: UseToastOptions = {
    position: "top-right",
    duration: 30000,
    isClosable: true,
  }
  return useToast({ ...ledgerOptions })
}
