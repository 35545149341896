import { Center, Heading } from "@chakra-ui/react"

const CommingSoonPage = () => {
  return (
    <Center h="100vh" w="100vw">
      <Heading fontFamily="Pacifico" size={"xl"}>
        Page Coming Soon
      </Heading>
    </Center>
  )
}

export default CommingSoonPage
