import { Box, BoxProps, Heading, Stack, StackProps } from "@chakra-ui/layout"
import CloudinaryImage from "../../../../components/CloudinaryImage"
import { Catalog, UpdateCatalogDocument } from "../../../../generated/graphql"
import SearchBar from "./SearchBar"
import { useEffect, useState } from "react"
import { headingStyle } from "./SharedStyles"
import EditTitle from "./EditTitle"
import HeaderButtons from "./HeaderButtons"
import { useMutation } from "@apollo/client"
import { CatalogMutation } from "../CatalogMutation"
import { ImageUpload } from "../../utils/ImageUpload"
import { useToast } from "@chakra-ui/react"
interface Props extends BoxProps {
  catalog: Catalog
}
const CatalogCover = ({ catalog, ...rest }: Props) => {
  const [isEdit, setIsEdit] = useState(false)
  const [isTitle, setIsTitle] = useState(true)
  const { coverImage, coverTitle } = catalog
  const [url, setUrl] = useState("")
  const [hover, setHover] = useState(false)
  const [inputValue, setInputValue] = useState("")
  const [catalogInput] = useMutation(UpdateCatalogDocument)
  const toast = useToast()

  useEffect(() => {
    setInputValue(coverTitle)
    setUrl(coverImage)
  }, [catalog, coverTitle, coverImage])

  const handleEdit = () => {
    setIsEdit(true)
    setIsTitle(false)
  }
  const handleImage = async (Images, searchImage) => {
    let updatedCatalog = catalog
    if (searchImage) {
      updatedCatalog = { ...catalog, coverImage: searchImage }
      CatalogMutation(catalogInput, updatedCatalog, toast)
      setUrl(searchImage)
    }
    if (Images) {
      const url = await ImageUpload(Images)
      updatedCatalog = { ...catalog, coverImage: url }
      CatalogMutation(catalogInput, updatedCatalog, toast)
      setUrl(url)
    }
  }

  const handleSubmit = () => {
    if (inputValue) {
      setIsTitle(true)
      setIsEdit(false)
      setInputValue(inputValue)
      let updatedCatalog = catalog
      updatedCatalog = { ...catalog, coverTitle: inputValue }
      CatalogMutation(catalogInput, updatedCatalog, toast)
    }
  }
  return (
    <Stack alignItems="center" {...rest} justifyContent="center" rounded={"10px"}>
      <Stack
        {...stackStyles}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <CloudinaryImage
          h={height}
          w="full"
          pos="absolute"
          objectFit="cover"
          zIndex={-2}
          quality="good"
          src={url}
          rounded={hover ? "8px" : "0px"}
          filter={isEdit ? "brightness(20%)" : "brightness(50%)"}
        />

        <Stack maxH="70px" alignItems="center" justifyContent="flex-start" mb="20px">
          <Heading
            color={isTitle ? "black" : "transparent"}
            className="heading"
            {...headingStyle}
            onClick={handleEdit}
            py="1"
            px="2"
            zIndex={1}
          >
            {inputValue ? inputValue : "Title"}
          </Heading>
        </Stack>

        <Box filter={isEdit ? "brightness(40%)" : "brightness(100%)"} zIndex={1}>
          <SearchBar />
        </Box>

        <Stack {...btnBoxStyles} className="buttonbox">
          <Box mt="30rem">
            <HeaderButtons ImageHandler={handleImage} />
          </Box>
        </Stack>
      </Stack>
      <Box display={isEdit ? "block" : "none"} w="600px" pos="absolute" pb="10px" zIndex={1}>
        <EditTitle
          setIsTitle={setIsTitle}
          isTitle={isTitle}
          setIsEdit={setIsEdit}
          setValue={setInputValue}
          coverTitle={coverTitle}
          value={inputValue}
          data={{ coverTitle: inputValue }}
          catalogInput={catalogInput}
          catalog={catalog}
          handleSubmit={handleSubmit}
        />
      </Box>
    </Stack>
  )
}

const height = {
  base: "220px",
  md: "600px",
}
export default CatalogCover

const stackStyles: StackProps = {
  position: "relative",
  p: 4,
  h: height,
  justifyContent: "center",
  alignItems: "center",
  w: "full",
  _hover: { shadow: "lg", outline: "12px solid #699dff" },
  outline: "12px solid transparent",
  outlineOffset: "-10px",
  rounded: "8px",
}
const btnBoxStyles: StackProps = {
  justifyContent: "flex-end",
  alignItems: "flex-end",
  w: "full",
  pos: "absolute",
  px: "6",
}
