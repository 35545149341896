import { Center, Container, Flex, Spinner, Stack } from "@chakra-ui/react"
import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useRecoilState } from "recoil"
import Pagination from "../../../components/Pagination"
import { PurpleBackdrop } from "../../../components/PurpleBackdrop"
import { useSearchBusinessesQuery } from "../../../generated/graphql"
import { businessQuerySelector } from "../../../store/business"
import BusinessSearchBar from "./BusinessSearchBar"
import { DirectoryGrid } from "./DirectoryGrid"
import { MarketFilter } from "./FilterMenus"
import { ListingFulfillmentFilter, ListingTypeFilter } from "./ListingFilters"
export const BusinessDirectory = ({ isCategory }) => {
  const [{ page, pageSize, marketName }, setQuery] = useRecoilState(businessQuerySelector)
  const { businesses, total, loading, called } = useBusinessDirectoryData()
  const history = useHistory()
  const changePage = (page: number) => {
    history.push({ search: `?page=${page}` })
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const page = parseInt(params.get("page") ?? "0")
    if (!page) return history.replace({ search: `?page=1` })
    setQuery((prevState) => ({ ...prevState, page }))
  }, [history.location.search])

  return (
    <>
      <Container maxW="container.xl" px={0}>
        <Stack
          w="full"
          mb={12}
          direction={{ base: "column", md: "row" }}
          alignItems={{ base: "center", md: "flex-end" }}
          spacing={4}
        >
          <BusinessSearchBar />
          <Flex>
            <ListingTypeFilter mx={1} />
            <ListingFulfillmentFilter mx={1} />
            <MarketFilter
              mx={1}
              market={marketName}
              setMarket={(name) => setQuery((p) => ({ ...p, marketName: name }))}
            />
          </Flex>
          <Center pb={2}>
            <Spinner
              test-dataid="business_search_spinner"
              visibility={loading ? "visible" : "hidden"}
            />
          </Center>
          <Pagination
            ml={{ base: "auto" }}
            mr={{ base: "auto", md: 6 }}
            total={total}
            current={page}
            pageSize={pageSize}
            handleChange={changePage}
            h="35px"
          />
        </Stack>
      </Container>
      <PurpleBackdrop>
        <Container maxW="container.xl" px={{ base: 4, md: 6 }}>
          <DirectoryGrid
            called={called}
            loading={loading}
            items={businesses}
            type="business"
            total={total}
            current={page}
            pageSize={pageSize}
            isCategory={isCategory}
          />
          <Container marginTop="2em">
            <Pagination
              total={total}
              current={page}
              pageSize={pageSize}
              handleChange={changePage}
            />
          </Container>
        </Container>
      </PurpleBackdrop>
    </>
  )
}

const useBusinessDirectoryData = () => {
  const [{ page, pageSize, searchText }] = useRecoilState(businessQuerySelector)
  const query = useSearchBusinessesQuery({
    variables: {
      page,
      limit: pageSize,
      query: searchText,
      filters: "isDisabled:false",
    },
  })
  const businesses = query.data?.searchBusinesses.businesses ?? []
  const total = query.data?.searchBusinesses.total ?? 0

  return { businesses, total, ...query }
}
