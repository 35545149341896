import { Box, BoxProps } from "@chakra-ui/layout"
import { Center, HStack } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useRecoilValue } from "recoil"
import GlyphLabel from "../../../components/glyph/GlyphLabel"
import { useManagedCountUp } from "../../../hooks/useManagedCountUp"
import { useGetMe } from "../../../store/useGetMe"
import colors, { gradients } from "../../../theme/foundations/colors"
import { useFetchWalletBalance, walletBalanceAtom } from "../store/walletBalance"
import TransactionAlert from "./alerts/TransactionAlert"
import { useWalletEventListener } from "./hooks/useWalletEventListener"

const creditRef = "walletInfo-credit-ref"
const balanceRef = "walletInfo-balance-ref"

const pillContainerStyles: BoxProps = {
  bgColor: "white",
  borderRadius: "2xl",
  border: "1px solid",
  py: 1,
  px: 2,
}

const WalletInfo = ({ ...rest }: BoxProps) => {
  const history = useHistory()
  const { wallet } = useGetMe().myBusiness
  const { availableCredit, balance } = useRecoilValue(walletBalanceAtom)
  const fetchWalletBalance = useFetchWalletBalance()
  const [loading, setLoading] = useState(true)

  useWalletEventListener()
  useManagedCountUp({ ref: creditRef, end: availableCredit })
  useManagedCountUp({ ref: balanceRef, end: balance })

  useEffect(() => {
    if (!wallet?.id) {
      setLoading(true)
      return
    }
    if (wallet?.id) fetchWalletBalance(wallet?.id)
    if (typeof availableCredit === "string" && typeof balance === "string") {
      setLoading(false)
    } else {
      setLoading(true)
    }
  }, [fetchWalletBalance, availableCredit, balance, wallet])

  return (
    <>
      <Box
        {...rest}
        cursor="pointer"
        data-testid="wallet-info"
        onClick={() => history.push("/wallet")}
      >
        <HStack spacing={-10}>
          <Center
            data-testid="wallet_balance"
            {...pillContainerStyles}
            borderColor="gray.700"
            px={4}
            pr={14}
          >
            <GlyphLabel
              id={balanceRef}
              loading={loading}
              value={balance}
              color={balance > 0 ? colors.green.main : "black"}
            />
          </Center>
          <Center
            data-testid="wallet_credit"
            {...pillContainerStyles}
            borderColor="primary.dark"
            px={4}
          >
            <GlyphLabel
              id={creditRef}
              loading={loading}
              color="purple.main"
              value={availableCredit}
              _label={{ bg: gradients.primary, bgClip: "text" }}
            />
          </Center>
        </HStack>
      </Box>
      <TransactionAlert />
    </>
  )
}

export default WalletInfo
