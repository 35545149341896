import { useApolloClient } from "@apollo/client"
import { useCallback } from "react"
import { useHistory } from "react-router-dom"
import { useResetRecoilState, useSetRecoilState } from "recoil"
import { StreamChat as stream } from "stream-chat"
import { useGetMe } from "../../store/useGetMe"
import { createAnalyticsTrack } from "../analytics"
import { walletAtom } from "../ledger/store/wallet"
import { walletBalanceAtom } from "../ledger/store/walletBalance"
import { setAuthToken } from "./authToken"
import { deleteRefreshToken } from "./useFetchRefreshToken"

export const useSignout = () => {
  const { me } = useGetMe()
  const history = useHistory()
  const resetAtoms = useResetAtoms()
  const resetApollo = useClearApolloStore()
  const trackLogoutEvent = createAnalyticsTrack("client:user_logout")

  return useCallback(async () => {
    await resetAtoms()
    await resetApollo()
    await resetChatClient()
    await resetLocalStorage()
    await trackLogoutEvent({ userId: me?.id })
    await invalidateCookies()
    history.push("/login")
  }, [])
}

const useResetAtoms = () => {
  const resetWalletState = useResetRecoilState(walletAtom)
  const setWalletBalance = useSetRecoilState(walletBalanceAtom)

  return useCallback(() => {
    resetWalletState()
    setWalletBalance({ balance: 0, availableCredit: 0, creditLimit: 0 })

    setAuthToken("")
  }, [])
}

const resetChatClient = () => {
  stream
    .getInstance(process.env.REACT_APP_STREAM_KEY || "")
    .disconnectUser()
    .then(() => console.log("chat disconnected"))
}

const useClearApolloStore = () => {
  const client = useApolloClient()
  return useCallback(async () => {
    await client.clearStore()
  }, [])
}

const resetLocalStorage = () => {
  localStorage.setItem("encryptedPrivateKey", "")
  localStorage.setItem("token", "") // local storage not used for tokens anymore, but still clearing
}

const invalidateCookies = () => {
  deleteRefreshToken()
}
