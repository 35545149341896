import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Heading,
  HStack,
  Box,
  ButtonProps,
} from "@chakra-ui/react"
import { Button, useDisclosure } from "@chakra-ui/react"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { closeModalAtom } from "../../../../store/listing"
import { ListingDirectory } from "../ListingDirectory"

function EditlistModal({ isOpen, onClose }: any) {
  const { onOpen } = useDisclosure()
  const closeModal = useRecoilValue(closeModalAtom)
  const setCloseModal = useSetRecoilState(closeModalAtom)

  if (closeModal) {
    onClose()
    setCloseModal(false)
  }
  const handleClick = () => {
    onOpen()
  }
  return (
    <>
      <Button onClick={handleClick} {...buttonStyles} _hover={{ bg: "#333333", color: "white" }}>
        Edit List
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalBody pt="30px" minHeight="80vh" px="40px">
            <HStack h="70px" spacing={5} px={"14px"}>
              <Heading fontSize="18px" _hover={{ cursor: "pointer" }} color="#7161EF">
                Listings
              </Heading>
            </HStack>
            <Box>
              <ListingDirectory />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

const buttonStyles: ButtonProps = {
  color: "transparent",
  bg: "transparent",
  borderRadius: "0px",
  mr: "20px",
  w: "120px",
  fontWeight: "300",
}
export default EditlistModal
