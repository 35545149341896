import {
  Box,
  Spacer,
  HStack,
  VStack,
  BoxProps,
  Center,
  HeadingProps,
  StackProps,
} from "@chakra-ui/layout"
import { Heading, useToast } from "@chakra-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { useHistory } from "react-router"
import Button from "../../../../components/Button"
import CloudinaryImage from "../../../../components/CloudinaryImage"
import { coverStyles } from "../../../storefront/StorefrontPage"
import { catalogButtonStyles } from "./SearchBar"
import EditTitle from "./EditTitle"
import HeaderButtons from "./HeaderButtons"
import IconPicker from "./IconPicker"
import { MarketplaceList, UpdateCatalogDocument } from "../../../../generated/graphql"
import { ImageUpload } from "../../utils/ImageUpload"
import { useMutation } from "@apollo/client"
import { CatalogMutation } from "../CatalogMutation"
interface Props extends BoxProps {
  list: MarketplaceList
  currentCatalog: any
}
const MarketplaceListCover = ({ id: sublistId, currentCatalog, list, ...rest }: Props) => {
  const history = useHistory()
  const [isEdit, setIsEdit] = useState(false)
  const [isTitle, setIsTitle] = useState(true)
  const [iconClass, setIconClass]: any = useState(list?.icon)
  const [inputValue, setInputValue] = useState(list?.title)
  const [url, setUrl] = useState(list?.coverImage)
  const [catalogInput] = useMutation(UpdateCatalogDocument)
  const [click, setClick] = useState(false)
  const toast = useToast()

  const MutateValue = (value) => {
    const updatedCatalog = JSON.parse(JSON.stringify(currentCatalog))
    updatedCatalog?.lists.map((obj) => {
      if (obj !== undefined) {
        obj.sublists.map((childObj) => {
          if (childObj.id === sublistId) {
            childObj.coverImage = value
          }
          return childObj
        })
        return obj
      }
    })
    CatalogMutation(catalogInput, updatedCatalog, toast)
  }

  useEffect(() => {
    list?.icon ? setIconClass(list.icon) : setIconClass("ellipsis-h")
  }, [list?.icon])

  useEffect(() => {
    setInputValue(list?.title)
    setUrl(list?.coverImage)
  }, [list?.title, list?.coverImage])

  const handleEdit = () => {
    setIsEdit(true)
    setIsTitle(false)
  }

  const handleSubmit = () => {
    if (inputValue) {
      setIsTitle(true)
      setIsEdit(false)
      setInputValue(inputValue)
      const updatedCatalog = JSON.parse(JSON.stringify(currentCatalog))
      updatedCatalog?.lists.map((obj) => {
        if (obj !== undefined) {
          obj.sublists.map((childObj) => {
            if (childObj.id === sublistId) {
              childObj.title = inputValue
            }
            return childObj
          })
          return obj
        }
      })

      CatalogMutation(catalogInput, updatedCatalog, toast)
    }
  }

  const handleImage = async (Images, searchImage) => {
    if (searchImage) {
      setUrl(searchImage)
      MutateValue(searchImage)
    }

    if (Images) {
      const imageUrl: any = await ImageUpload(Images)
      setUrl(imageUrl)
      MutateValue(imageUrl)
    }
  }

  return (
    <Box {...coverStyles} {...rest} position="relative">
      <CloudinaryImage
        {...coverStyles}
        zIndex={-2}
        quality="good"
        src={url}
        filter="brightness(50%)"
      />
      <HStack {...stackStyles}>
        <Button
          {...catalogButtonStyles}
          onClick={() => history.push(`/admin`)}
          leftIcon={<FontAwesomeIcon icon={"arrow-left"} />}
          visibility={{ base: "hidden", md: "initial" }}
        >
          Back
        </Button>
        <Spacer />
        <VStack alignItems="flex-end">
          {isTitle && (
            <Heading
              onClick={handleEdit}
              {...headingStyles}
              _hover={{ bg: "white", color: "black", border: " 6px solid #699DFF" }}
            >
              {inputValue || "Title"}
            </Heading>
          )}

          {isEdit && (
            <Box mt="40px">
              <EditTitle
                setIsTitle={setIsTitle}
                isTitle={isTitle}
                setIsEdit={setIsEdit}
                setValue={setInputValue}
                coverTitle={list.title}
                value={inputValue}
                handleSubmit={handleSubmit}
              />
            </Box>
          )}
        </VStack>
        <Center
          bg={click ? "#B3BEC4" : "transparent"}
          w="60px"
          h="60px"
          rounded={"8px"}
          onClick={() => setClick(!click)}
        >
          {iconClass?.substring(0, 2).trim() === "fa" ? (
            <i
              className={iconClass}
              style={{
                color: "white",
                fontSize: 28,
                textShadow: click ? "0px 8px 8px rgba(0, 0, 0, 0.25)" : "none",
              }}
              aria-hidden="true"
            ></i>
          ) : (
            <FontAwesomeIcon
              className="faIcon"
              icon={iconClass}
              style={{
                textShadow: click ? "0px 8px 8px rgba(0, 0, 0, 0.25)" : "none",
                color: "white",
                fontSize: 28,
              }}
            />
          )}
          <Box position="absolute" w="40px">
            <IconPicker
              setIconClass={setIconClass}
              icon={iconClass}
              currentCatalog={currentCatalog}
              sublistId={sublistId}
            />
          </Box>
        </Center>
      </HStack>
      <HStack {...btnStackStyles}>
        <HeaderButtons ImageHandler={handleImage} />
      </HStack>
    </Box>
  )
}
const headingStyles: HeadingProps = {
  fontSize: "36px",
  color: "white",
  fontWeight: "600",
  borderRadius: "16px",
  px: "2",
}
const stackStyles: StackProps = {
  w: "full",
  px: "40px",
  h: "100px",
  mt: "-300px",
  position: "absolute",
}
const btnStackStyles: StackProps = {
  w: "full",
  justifyContent: "flex-end",
  position: "absolute",
  mt: "-50px",
  px: "49px",
}
export default MarketplaceListCover
