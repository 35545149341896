import * as Sentry from "@sentry/react"
import AES from "crypto-js/aes"
import { ethers } from "ethers"
import { useLoginUserMutation } from "../../../generated/graphql"
import { useSetRecoilState } from "recoil"
import { walletAtom } from "../store/wallet"
import { HDNode } from "ethers/lib/utils"
import { getPiiHash } from "../components/utils"

interface GenerateClientWalletProps {
  email: string
  password: string
  authenticate: boolean
}
export const useGenerateClientWallet = () => {
  const setCryptoWallet = useSetRecoilState(walletAtom)
  const [login] = useLoginUserMutation()

  return async ({ email, password, authenticate }: GenerateClientWalletProps): Promise<HDNode> => {
    let clientWallet
    try {
      try {
        if (authenticate) await login({ variables: { email, password } })
      } catch (error) {
        if (authenticate) throw error
      }

      const piiHash = getPiiHash({ email, password })
      clientWallet = ethers.Wallet.createRandom()
      const pkey = AES.encrypt(clientWallet.privateKey, piiHash).toString()

      localStorage.setItem("encryptedPrivateKey", pkey)
      setCryptoWallet((prevState) => ({
        ...prevState,
        wallet: new ethers.Wallet(clientWallet.privateKey),
      }))
    } catch (e) {
      if (e.message === "Invalid username or password") {
        throw e
      }
      Sentry.captureException(e)
    }
    return clientWallet
  }
}
