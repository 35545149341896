import * as Sentry from "@sentry/react"
import axios, { AxiosRequestConfig } from "axios"
import config from "../../../config"

export const fetchFromKeyStore = async (params: {
  path: "encrypt" | "store" | "retrieve"
  payload: { data: string; walletId?: string }
}) => {
  const { path, payload } = params
  if (!path || !payload) throw new Error("Params 'PATH' & 'PAYLOAD' are both required")

  const axiosConfig: AxiosRequestConfig = {
    method: "POST",
    url: config.KEYS.KEY_STORE + "/" + path,
    headers: {
      Accept: "*/*",
    },
    data: {
      data: payload.data,
      wallet: payload.walletId ? payload.walletId : undefined,
    },
  }

  try {
    const { data } = await axios(axiosConfig)

    if (!data.error) {
      return { ...data, error: false }
    }
  } catch (e) {
    Sentry.captureException(e)
    console.error("Error fetching key from keystore: ", e)
    return { error: true, data: e }
  }
}

export const retrieveEncryptedPrivateKey = async (walletId) => {
  if (!walletId) return null
  return fetchFromKeyStore({
    path: "retrieve",
    payload: { data: "", walletId },
  })
}
