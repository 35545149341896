import { atom } from "recoil"

interface Props {
  state: string
  transactionId: string | null
}

const defaultTransactionAlert: Props = {
  state: "",
  transactionId: null,
}

export const transactionAlertAtom = atom({
  key: "transactionAlertAtom",
  default: defaultTransactionAlert,
})
