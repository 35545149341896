import { Wallet } from "ethers"
import { atom, DefaultValue, selector } from "recoil"

interface Props {
  wallet: Wallet | null
  shouldRegisterListeners: boolean
}
const defaultWalletState: Props = {
  wallet: null,
  shouldRegisterListeners: false,
}

export const walletAtom = atom({
  key: "walletAtom",
  default: defaultWalletState,
})

export const walletSelector = selector({
  key: "walletSelector",
  get: ({ get }) => get(walletAtom).wallet,
  set: ({ set }, { wallet }: any) => {
    set(walletAtom, (prevState) => ({ ...prevState, wallet }))
  },
})

export const walletListenSelector = selector({
  key: "walletListenSelector",
  get: ({ get }) => get(walletAtom).shouldRegisterListeners,
  set: ({ set }, newValue: boolean | DefaultValue) => {
    const shouldRegisterListeners = newValue instanceof DefaultValue ? false : newValue
    set(walletAtom, (prevState) => ({ ...prevState, shouldRegisterListeners }))
  },
})
