import axios, { AxiosRequestConfig } from "axios"
import { config } from "../../../config"

interface RegisterUserWithGuardianProps {
  authToken: string
  userId: string
  email: string
  multiSigAddress: string
  clientAddress: string
}

export const registerUserWithGuardian = async ({
  authToken,
  userId,
  email,
  multiSigAddress,
  clientAddress,
}: RegisterUserWithGuardianProps): Promise<string> => {
  if (!authToken) throw new Error("Error registering with guardian")
  const axiosConfig: AxiosRequestConfig = {
    method: "POST",
    url: config.GUARDIAN_ENDPOINTS.REGISTER,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken,
    },
    data: {
      userId: userId,
      email: email,
      multiSigAddress: multiSigAddress,
      clientAddress: clientAddress,
    },
  }
  const res = await axios(axiosConfig)

  if (res.data.MESSAGE === "USER WITH EMAIL OR USERID EXISTS") {
    throw new Error("USER WITH EMAIL OR USERID EXISTS")
  }

  const guardianAddress = res.data.guardian
  if (!guardianAddress) throw new Error("Unable to register user with guardian")
  return guardianAddress
}
