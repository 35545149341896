import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/react"
import React from "react"
import { useGetMe } from "../../../store/useGetMe"
import { VerifyPasswordForm } from "../../auth/components/VerifyPasswordForm"
import { useProvisionClientWallet } from "../../../services/ledger/wallet/useProvisionClientWallet"

interface ActivateWalletModalProps {
  isOpen: boolean
  onClose: () => void
  setIsActivating?: (value: boolean) => void
}

const ActivateWalletModal = (props: ActivateWalletModalProps) => {
  const { me } = useGetMe()
  const provisionClientWallet = useProvisionClientWallet()
  const firstFieldRef = React.useRef(null)
  const { isOpen, onClose, setIsActivating } = props

  const verifyFunc = async ({ password }) => {
    if (!me?.email) return
    onClose()
    if (setIsActivating) setIsActivating(true)
    await provisionClientWallet({ email: me.email, password })
    if (setIsActivating) setIsActivating(false)
  }

  return (
    <>
      <Modal isOpen={isOpen} initialFocusRef={firstFieldRef} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent padding="2em">
          <VerifyPasswordForm firstFieldRef={firstFieldRef} onSubmit={verifyFunc} activate={true} />
        </ModalContent>
      </Modal>
    </>
  )
}

export default ActivateWalletModal
