import { Badge, HStack, BoxProps, Flex, FlexProps } from "@chakra-ui/layout"
import { Heading, VStack } from "@chakra-ui/react"
import { useState } from "react"
import { useSetRecoilState } from "recoil"
import CloudinaryImage from "../../../../components/CloudinaryImage"
import { MarketplaceList } from "../../../../generated/graphql"
import { SelectedListAtom } from "../../../../store/listing"
import { baseThumbnailStyles } from "./SharedStyles"
import "./styles.scss"
interface Props extends BoxProps {
  list: MarketplaceList
}

const FeaturedList = ({ list: parentList, ...rest }: Props) => {
  const list = parentList.sublists[0]
  const { coverImage } = list
  const [hover, setHover] = useState(false)
  const setSelectedList = useSetRecoilState(SelectedListAtom)

  const handleClick = () => {
    setSelectedList(list)
  }
  return (
    <VStack
      {...rest}
      pl={4}
      px="30px"
      h={height}
      onClick={handleClick}
      {...baseThumbnailStyles}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      to={`/marketplace/edit/list/${list.id}`}
      alignItems={"flex-start"}
    >
      <CloudinaryImage
        h={height}
        w="full"
        zIndex={-1}
        rounded="2xl"
        quality="good"
        src={coverImage}
        objectFit="cover"
        filter="brightness(60%)"
      />
      <VStack spacing={4} alignItems={"flex-start"} pos="absolute" pt="50px" pl="50px">
        <Heading color="white" fontWeight="600" fontSize="36px" textAlign="left" mb="3">
          {list.title || "Title"}
        </Heading>
        <Badge colorScheme="orange" variant="outline" px={2} py={1} rounded="md">
          FEATURED
        </Badge>
      </VStack>
      <HStack w="full">
        <Flex
          {...editListStyles}
          bg={hover ? "rgba(255, 255, 255, 0.15)" : "transparent"}
          color={hover ? "white" : "transparent"}
        >
          Edit list
        </Flex>
      </HStack>
    </VStack>
  )
}

const height = {
  base: "220px",
  md: "500px",
}
const editListStyles: FlexProps = {
  h: "50px",
  alignItems: "center",
  justifyContent: "flex-end",
  w: "full",
  px: "6",
  mt: "-7rem",
}

export default FeaturedList
