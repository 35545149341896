import { IconButton, useBreakpointValue, useDisclosure } from "@chakra-ui/react"
import { faComments } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { useHistory } from "react-router"
import CallToActionModal from "../components/CallToActionModal"
import IconWithBadge from "../components/IconWithBadge"
import { useAuth } from "../services/auth/AuthProvider"
import colors from "../theme/foundations/colors"

const ChatNavIcon = () => {
  const { isSignedIn } = useAuth()
  const signInAlert = useDisclosure()
  const [unreadMessages, setUnreadMessages] = useState(0)
  const isMobile = useBreakpointValue({ base: true, md: false })

  return (
    <>
      <IconButton
        size={isMobile ? "lg" : "md"}
        data-testid="chat_btn"
        variant="ghost"
        borderRadius="2xl"
        aria-label="show new mails"
        onClick={() =>
          isSignedIn ? window.open(`https://staging.resourcenetwork.co/chat`) : signInAlert.onOpen()
        }
      >
        <IconWithBadge value={(unreadMessages ?? 0) as number}>
          <FontAwesomeIcon
            color={colors.gray[isMobile ? 500 : 900]}
            style={{ marginTop: "2px" }}
            icon={faComments}
          />
        </IconWithBadge>
      </IconButton>
      <CallToActionModal isOpen={signInAlert.isOpen} onClose={signInAlert.onClose} />
    </>
  )
}

export default ChatNavIcon
