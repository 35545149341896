import { useApolloClient } from "@apollo/client"
import { Box, BoxProps } from "@chakra-ui/layout"
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogProps,
  Button,
  Center,
  HStack,
  IconButton,
  Stack,
  Text,
  useBreakpointValue,
  useClipboard,
  useDisclosure,
  useToast,
  VStack,
  Flex,
  Spacer,
  StackProps,
} from "@chakra-ui/react"
import { faPen, faShare } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useRef, useState } from "react"
import { FaRegHeart } from "react-icons/fa"
import { Link } from "react-router-dom"
import CloudinaryImage from "../../../components/CloudinaryImage"
import GlyphLabel from "../../../components/glyph/GlyphLabel"
import {
  FindBusinessByHandleDocument,
  Listing,
  useToggleListingMutation,
} from "../../../generated/graphql"
import colors from "../../../theme/foundations/colors"
import EditListingModal from "../../storefront/components/EditListingModal"
import { useIsOwnBusiness } from "../../storefront/utils/useIsOwnedBusiness"
import RemoveModal from "./catalog/RemoveModal"
export interface ListingCardProps extends BoxProps {
  listing: Listing
  layout?: "mobile" | "desktop"
  isRemove?: boolean
  isListing?: boolean
  isCategory?: boolean
}
export const ListingCard = ({
  listing,
  layout,
  isRemove,
  isListing,
  isCategory,
  ...rest
}: ListingCardProps) => {
  const isMobile = useBreakpointValue({ base: true, sm: false })
  const listingPath = `https://staging.resourcenetwork.co/${
    listing.business?.handle ?? ""
  }/listing/${listing.id}`
  const fullUrl = `${window.location.origin}${listingPath}`
  const { isOwnBusiness } = useIsOwnBusiness()
  const { onCopy } = useClipboard(fullUrl)
  const toast = useToast()
  const [remove, setRemove] = useState(false)
  const [hover, setHover] = useState(false)

  const BusinessInfo = (props: BoxProps) => (
    <HStack
      {...props}
      w="150px"
      h="40px"
      bg="rgba(255, 255, 255, 0.9)"
      borderRadius="8px 0px"
      px="1"
    >
      <CloudinaryImage
        h="25px"
        w="25px"
        rounded="full"
        src={listing?.business?.logoUrl ?? ""}
        _hover={{ shadow: "md" }}
      />
      <Text noOfLines={1} fontSize="14px" fontFamily="Poppins" fontWeight="400">
        {listing?.business?.name}
      </Text>
    </HStack>
  )
  const Image = () => (
    <Flex w="full" h="full" fit="cover" bgImage={listing.imageUrl ?? ""} rounded="8px">
      <BusinessInfo />
    </Flex>
  )
  const PriceTag = () => {
    return (
      <Stack
        bg="linear-gradient(to top, #3f51b1 0%, #5a55ae 13%, #7b5fac 25%, #8f6aae 38%, #a86aa4 50%, #cc6b8e 62%, #f18271 75%, #f3a469 87%, #f7c978 100%)"
        rounded="full"
        p="1px"
      >
        <Center rounded="full" px={2} py={"1px"} bg="white">
          {listing.cost > 0 ? (
            <GlyphLabel value={listing.cost} size={"sm"} />
          ) : (
            <Text lineHeight={1.7} noOfLines={1}>
              contact for price
            </Text>
          )}
        </Center>
      </Stack>
    )
  }
  const CopyButton = () => (
    <IconButton
      size="sm"
      padding="2px !important"
      rounded="full"
      bgColor="white"
      aria-label="share"
      variant="ghost"
      icon={<FontAwesomeIcon icon={faShare} />}
      onClick={(e) => {
        onCopy()
        toast({ description: "Link copied" })
        e.preventDefault()
        e.stopPropagation()
      }}
    />
  )
  const FavouriteButton = () => (
    <IconButton
      size="sm"
      padding="2px !important"
      rounded="full"
      bgColor="white"
      aria-label="share"
      variant="ghost"
      icon={<FaRegHeart />}
      onClick={(e) => {
        toast({ description: "Marked as Favourite" })
        e.preventDefault()
        e.stopPropagation()
      }}
    />
  )

  const MobileLayout = () => {
    return (
      <Stack
        direction={"row"}
        as={Link}
        spacing={3}
        px={1}
        py={2}
        borderTop={`1px solid ${colors.gray[500]}`}
        rounded="0px"
        align="stretch"
        // to={isModal ? null : listingPath}
        _last={{ borderBottom: { base: `1px solid ${colors.gray[500]}`, sm: "initial" } }}
        {...rest}
      >
        <Box
          marginInlineStart="0px !important"
          position="relative"
          minW="120px"
          h={"120px"}
          w={"120px"}
        >
          <Image />
          {isOwnBusiness && (
            <HStack zIndex={2} position={"absolute"} right="0px" m={2}>
              <PublishContainer listing={listing} />
              <EditContainer listing={listing} />
            </HStack>
          )}
        </Box>
        <VStack w="full" alignItems="stretch" justify="space-between">
          <BusinessInfo />
          <Text noOfLines={2} h="40px">
            {listing.title}
          </Text>
          <HStack w="full" justify="space-between">
            <CopyButton />
            <PriceTag />
          </HStack>
        </VStack>
      </Stack>
    )
  }
  const DesktopLayout = () => (
    <Flex justify={"center"} align="center" direction={"column"} h="360px">
      <Stack
        {...mainStackStyles}
        bg={isListing ? "none" : "#faf8fe"}
        onClick={() => {
          isCategory &&
            window.open(
              `https://staging.resourcenetwork.co/${listing.business?.handle ?? ""}/listing/${
                listing.id
              }`,
            )
        }}
        border={hover ? "12px solid #699DFF" : "12px solid transparent"}
        _hover={{
          bgColor: "white",
          border: hover ? "12px solid #699DFF" : "12px solid #699DFF",
          cursor: "pointer",
        }}
        {...rest}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Box marginInlineStart="0px !important" h={"220px"} minW="120px" position="relative">
          <Image />
          {isOwnBusiness && (
            <HStack zIndex={2} position={"absolute"} right="0px" m={2}>
              <PublishContainer listing={listing} />
              <EditContainer listing={listing} />
            </HStack>
          )}
        </Box>
        <VStack w="full" alignItems="stretch">
          <Text data-testid="listing_card_title" noOfLines={2} h="40px">
            {listing.title}
          </Text>
          <HStack w="full" justify="space-between">
            <HStack>
              <CopyButton /> <FavouriteButton />
            </HStack>

            <Spacer />

            <PriceTag />
          </HStack>
        </VStack>
      </Stack>
      {isRemove && (
        <RemoveModal
          id={listing.id}
          setRemove={setRemove}
          isMarket={true}
          remove={remove}
          setHover={setHover}
          hover={hover}
        />
      )}
    </Flex>
  )
  if (layout === "desktop") return DesktopLayout()
  if (layout === "mobile") return MobileLayout()
  return isMobile ? MobileLayout() : DesktopLayout()
}
export const EditContainer = ({ listing }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Box
        px="6px"
        py="1px"
        background="blue.main"
        borderRadius="lg"
        _hover={{ bgColor: "blue.500" }}
        onClick={(e) => {
          e.preventDefault()
          onOpen()
        }}
      >
        <FontAwesomeIcon size="xs" color="white" icon={faPen} />
      </Box>
      {isOpen && <EditListingModal isOpen={isOpen} onClose={onClose} listing={listing} />}
    </>
  )
}
export const PublishContainer = ({ listing }) => {
  const toast = useToast()
  const client = useApolloClient()
  const publishAlert = useDisclosure()
  const [isLoading, setIsLoading] = useState(false)
  const [toggleListing] = useToggleListingMutation()
  const cancelRef = useRef<HTMLButtonElement>(null)
  if (!listing) return null
  const toggleIsDisable = async (listing) => {
    try {
      setIsLoading(true)
      const result = await toggleListing({ variables: { id: listing.id } })
      if (result.errors) return
      await client.refetchQueries({ include: [FindBusinessByHandleDocument] })
      publishAlert.onClose()
      toast({
        title: listing.isDisabled ? "Listing published" : "Listing unpublished",
        description: listing.isDisabled
          ? "Your listing is now live in the marketplace"
          : "Your listing is now hidden from the marketplace",
        status: "success",
      })
    } catch (e) {
      console.log("ListingCard.tsx --  e", e)
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <>
      <Button
        aria-label="Toggle disable"
        isLoading={isLoading}
        background={listing.isDisabled ? "white" : "#F49E4C"}
        color={listing.isDisabled ? "#F49E4C" : "white"}
        size="xs"
        border={listing.isDisabled ? "1px solid #F2F2F2" : "none"}
        onClick={(e) => {
          e.preventDefault()
          publishAlert.onOpen()
        }}
      >
        {listing.isDisabled ? "Publish" : "Published"}
      </Button>
      <ConfirmPublish
        isOpen={publishAlert.isOpen}
        isDisabled={listing.isDisabled as boolean}
        leastDestructiveRef={cancelRef}
        onClose={() => publishAlert.onClose()}
        onConfirm={() => toggleIsDisable?.(listing)}
      />
    </>
  )
}
interface AlertProps extends Omit<AlertDialogProps, "children"> {
  onConfirm: any
  isDisabled: boolean
}
const ConfirmPublish = (props: AlertProps) => {
  const { isOpen, onClose, onConfirm, leastDestructiveRef, isDisabled } = props
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={leastDestructiveRef}
      onClose={onClose}
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {isDisabled ? "Publish listing?" : "Unpublish listing?"}
          </AlertDialogHeader>
          <AlertDialogBody>
            <Text display="inline">
              {isDisabled
                ? "Shoppers will be able to view and purchase this listing in the marketplace"
                : "Shoppers will not be able to view this listing in the marketplace"}
            </Text>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button onClick={onClose} variant="outline">
              Cancel
            </Button>
            <Button type="submit" colorScheme="primary" ml={3} onClick={onConfirm}>
              {isDisabled ? "Publish" : "Unpublish"}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
const mainStackStyles: StackProps = {
  direction: "column",
  spacing: 3,
  px: 2,
  py: 2,
  borderTop: "none",
  rounded: "20px",
  align: "stretch",
  h: "341px",
  w: "236px",
  mb: "4",
  mx: "1",
  cursor: "pointer",
}
