import { Grid, GridItem } from "@chakra-ui/react"
import CatalogPage from "../../marketplace/components/CatalogPage"
function Admin() {
  return (
    <Grid templateColumns="repeat(8, 1fr)" gap={0} w="full">
      <GridItem colSpan={8} w="full" h="full">
        <CatalogPage />
      </GridItem>
    </Grid>
  )
}

export default Admin
