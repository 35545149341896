import {
  AspectRatio,
  Box,
  BoxProps,
  Center,
  Heading,
  HStack,
  VStack,
  Stack,
  Text,
  StackProps,
  HeadingProps,
} from "@chakra-ui/layout"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import CloudinaryImage from "../../../../components/CloudinaryImage"
import { MarketplaceList, UpdateCatalogDocument } from "../../../../generated/graphql"
import { baseThumbnailStyles } from "./SharedStyles"
import EditTitle from "./EditTitle"
import { useMutation } from "@apollo/client"
import { SelectedCatalogAtom, SelectedListAtom } from "../../../../store/listing"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { CatalogMutation } from "../CatalogMutation"
import { useToast } from "@chakra-ui/react"
interface Props extends BoxProps {
  list: MarketplaceList
}

const FourWideList = ({ list }: Props) => {
  const { title, sublists } = list
  const [isTitleEditAble, setTitleEditable] = useState(false)
  const [isTitle, setIsTitle] = useState(true)
  const [titleValue, setTitleValue] = useState("")
  const [inputValue, setInputValue] = useState("")
  const [catalogInput] = useMutation(UpdateCatalogDocument)
  const selectedCatalog: any = useRecoilValue(SelectedCatalogAtom)
  const [id, setId] = useState("")
  const toast = useToast()

  useEffect(() => {
    setTitleValue(title)
  }, [title])
  const handleSubmit = () => {
    setIsTitle(true)
    setTitleEditable(false)
    setInputValue(inputValue)
    const updatedCatalog = JSON.parse(JSON.stringify(selectedCatalog))
    updatedCatalog?.lists.map((obj) => {
      if (obj !== undefined) {
        if (obj.id === id) {
          obj.title = titleValue
          return obj
        }
      }
    })

    CatalogMutation(catalogInput, updatedCatalog, toast)
  }

  return (
    <VStack align="flex-start" px="20px" py="50px">
      <Box pos="relative" h="80px">
        <Heading
          border={isTitle ? "6px solid transparent" : "none"}
          {...headingStyles}
          _hover={{
            border: isTitle ? "6px solid #699DFF" : "none",
            borderRadius: "8px",
            bg: "#F2F2F2",
          }}
          onClick={() => {
            setTitleEditable(true)
            setIsTitle(false)
            setId(list.id)
          }}
        >
          {titleValue || "Title"}
        </Heading>

        {isTitleEditAble && (
          <Box pos="absolute" top={2} width="100%" minW="400px" zIndex="1">
            <EditTitle
              setIsEdit={setTitleEditable}
              setIsTitle={setIsTitle}
              setValue={setTitleValue}
              coverTitle={title}
              value={titleValue}
              catalogInput={catalogInput}
              data={{ fourWideListTitle: title }}
              handleSubmit={handleSubmit}
            />
          </Box>
        )}
      </Box>
      <Box
        maxW="full"
        overflowX="auto"
        pb={3}
        filter={isTitleEditAble ? "blur(2px)" : "none"}
        opacity={isTitleEditAble ? "0.50" : "1"}
        className="webkitbox"
      >
        <HStack spacing={2} w="fit-content">
          <Thumbnail list={sublists[0]} />
          <Thumbnail list={sublists[1]} />
          <Thumbnail list={sublists[2]} />
          <Thumbnail list={sublists[3]} />
        </HStack>
      </Box>
    </VStack>
  )
}

const Thumbnail = ({ list, ...rest }: Props) => {
  const icon: any = list.icon ?? "ellipsis-h"
  const [hover, setHover] = useState(false)
  const setSelectedList = useSetRecoilState(SelectedListAtom)

  const handleClick = () => {
    setSelectedList(list)
  }
  if (!list) return null

  return (
    <AspectRatio
      {...baseThumbnailStyles}
      {...rest}
      w={{ base: "200px", md: "303px" }}
      ratio={1}
      cursor="pointer"
      to={`/marketplace/edit/list/${list.id}`}
      onClick={handleClick}
      _hover={{ shadow: "lg", border: "12px solid #699dff" }}
      border="12px solid transparent"
      borderRadius="35px"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Center position="relative">
        <CloudinaryImage
          w="full"
          h="full"
          fit="cover"
          zIndex={-1}
          rounded="16px"
          quality="good"
          position="absolute"
          src={list.coverImage}
          filter="brightness(50%)"
        />
        <Stack
          {...StackStyles}
          color={hover ? "white" : "transparent"}
          bg={hover ? "rgba(255, 255, 255, 0.20)" : "transparent"}
        >
          <Text>EDIT LIST</Text>
        </Stack>

        <VStack>
          {icon?.substring(0, 2).trim() === "fa" ? (
            <i
              className={icon}
              style={{
                color: "white",
                fontSize: 28,
              }}
              aria-hidden="true"
            ></i>
          ) : (
            <FontAwesomeIcon
              className="faIcon"
              icon={icon}
              style={{
                color: "white",
                fontSize: 28,
              }}
            />
          )}

          <Heading color="white" size="header" textAlign="center">
            {list.title}
          </Heading>
        </VStack>
      </Center>
    </AspectRatio>
  )
}
const StackStyles: StackProps = {
  w: "100%",
  px: "5",
  h: "53px",
  justifyContent: "center",
  align: "flex-end",
  fontWeight: "400",
  fontSize: "14px",
  fontFamily: "Poppins",
  position: "absolute",
  mt: "11rem",
}
const headingStyles: HeadingProps = {
  fontSize: "36px",
  color: "#595959",
  fontWeight: "600",
  py: 2,
  px: "1",
}

export default React.memo(FourWideList)
