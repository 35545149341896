import { Button, ButtonProps } from "@chakra-ui/react"
import { FaEye } from "react-icons/fa"

const PreviewButton = ({ currentMarket }) => {
  return (
    <Button
      leftIcon={<FaEye />}
      {...btnStyles}
      onClick={() => {
        window.open(
          `https://staging.resourcenetwork.co/marketplace?market=${currentMarket.market.name}`,
        )
      }}
    >
      Preview
    </Button>
  )
}
const btnStyles: ButtonProps = {
  borderRadius: "12px",
  colorScheme: "purple",
  variant: "solid",
  fontWeight: "700",
  px: "18px",
  py: "16px",
  minW: "136px",
  fontSize: "18px",
}
export default PreviewButton
