import { FormControl, FormLabel, useToast, FormControlProps } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { Switch } from "@chakra-ui/react"
import { CatalogMutation } from "../CatalogMutation"
import { useMutation } from "@apollo/client"
import { UpdateCatalogDocument } from "../../../../generated/graphql"
const IsActive = (props) => {
  const { catalog } = props
  const [isActive, setIsActive]: any = useState()
  const [catalogInput] = useMutation(UpdateCatalogDocument)
  const toast = useToast()

  useEffect(() => {
    setIsActive(catalog?.isActive)
  }, [catalog])

  const handleSwitch = () => {
    let updatedCatalog = catalog
    updatedCatalog = { ...catalog, isActive: !isActive }
    CatalogMutation(catalogInput, updatedCatalog, toast)
    setIsActive(!isActive)
  }
  return (
    <FormControl {...formControlStyles}>
      <FormLabel
        htmlFor="isActive"
        whiteSpace="nowrap"
        color="#595959"
        fontSize="14px"
        fontWeight="400"
        pt="1"
      >
        Active in marketplace
      </FormLabel>
      <Switch
        id="isActive"
        size="lg"
        colorScheme="purple"
        isChecked={isActive}
        onChange={handleSwitch}
      />
    </FormControl>
  )
}

const formControlStyles: FormControlProps = {
  display: "flex",
  alignItems: "center",
  maxW: "fit-content",
  h: "48px",
  mx: "4",
  pt: "5px",
}
export default IsActive
