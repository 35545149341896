import { gql } from "@apollo/client"
import * as Apollo from "@apollo/client"
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
const defaultOptions = {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  DateTime: any
  /** Decimal custom scalar type */
  Decimal: any
}

export type AcceptInviteInput = {
  code: Scalars["String"]
  email: Scalars["String"]
  password: Scalars["String"]
}

export type AdminUser = {
  __typename?: "AdminUser"
  ambassador?: Maybe<Ambassador>
  avatarUrl?: Maybe<Scalars["String"]>
  business?: Maybe<Business>
  campaign?: Maybe<Scalars["String"]>
  createdAt: Scalars["DateTime"]
  email: Scalars["String"]
  firstName?: Maybe<Scalars["String"]>
  id: Scalars["String"]
  isAdmin: Scalars["Boolean"]
  isDisabled: Scalars["Boolean"]
  isEmailValidated: Scalars["Boolean"]
  lastLogin?: Maybe<Scalars["DateTime"]>
  lastName?: Maybe<Scalars["String"]>
  password: Scalars["String"]
  requestedCategories?: Maybe<Array<Maybe<Scalars["String"]>>>
  role: Role
  shouldResetPassword: Scalars["Boolean"]
  type?: Maybe<UserType>
  updatedAt: Scalars["DateTime"]
  validateEmailToken?: Maybe<Scalars["String"]>
}

export type Ambassador = {
  __typename?: "Ambassador"
  businesses?: Maybe<Array<Maybe<Business>>>
  createdAt?: Maybe<Scalars["DateTime"]>
  creditAllowance?: Maybe<Scalars["Int"]>
  handle: Scalars["String"]
  id: Scalars["String"]
  owner?: Maybe<User>
  ownerId: Scalars["String"]
  refCode: Scalars["String"]
  updatedAt?: Maybe<Scalars["DateTime"]>
}

export type AmbassadorUpdateInput = {
  creditAllowance?: Maybe<Scalars["Int"]>
  handle?: Maybe<Scalars["String"]>
}

export type AmbassadorUpdateManyMutationInput = {
  creditAllowance?: Maybe<IntUpdateInput>
}

export type AmbassadorWhereInput = {
  AND?: Maybe<Array<Maybe<AmbassadorWhereInput>>>
  NOT?: Maybe<Array<Maybe<AmbassadorWhereInput>>>
  OR?: Maybe<Array<Maybe<AmbassadorWhereInput>>>
  handle?: Maybe<StringFilter>
}

export type AttachCategoriesInput = {
  requestedCategories: Array<Maybe<Scalars["String"]>>
}

export type AuthPayload = {
  __typename?: "AuthPayload"
  authToken: Scalars["String"]
  user: User
}

export type BatchCount = {
  __typename?: "BatchCount"
  count: Scalars["Int"]
}

export type BoolFilter = {
  equals?: Maybe<Scalars["Boolean"]>
  not?: Maybe<NestedBoolFilter>
}

export type BoolUpdateInput = {
  set?: Maybe<Scalars["Boolean"]>
}

export type BugFormToSlack = {
  __typename?: "BugFormToSlack"
  action: Scalars["String"]
  businessEmail: Scalars["String"]
  businessName: Scalars["String"]
  businessPhone: Scalars["String"]
  contact: Scalars["String"]
  expectation: Scalars["String"]
  frequency: Scalars["String"]
  priority: Scalars["String"]
  user: Scalars["String"]
}

export type BugFormToSlackInput = {
  action: Scalars["String"]
  businessEmail: Scalars["String"]
  businessName: Scalars["String"]
  businessPhone: Scalars["String"]
  contact: Scalars["String"]
  expectation: Scalars["String"]
  frequency: Scalars["String"]
  priority: Scalars["String"]
  user: Scalars["String"]
}

export type Business = {
  __typename?: "Business"
  address?: Maybe<Scalars["String"]>
  ambassador?: Maybe<Ambassador>
  ambassadorId?: Maybe<Scalars["String"]>
  approved?: Maybe<Scalars["Boolean"]>
  coverUrl?: Maybe<Scalars["String"]>
  createdAt?: Maybe<Scalars["DateTime"]>
  description?: Maybe<Scalars["String"]>
  email?: Maybe<Scalars["String"]>
  emailNotifications?: Maybe<Scalars["Boolean"]>
  externalLinks?: Maybe<Array<Maybe<Scalars["String"]>>>
  handle: Scalars["String"]
  id: Scalars["String"]
  isDisabled?: Maybe<Scalars["Boolean"]>
  isEmailValidated?: Maybe<Scalars["Boolean"]>
  isSmsValidated?: Maybe<Scalars["Boolean"]>
  kmsTaxId?: Maybe<Scalars["String"]>
  listings?: Maybe<Array<Maybe<Listing>>>
  logoUrl?: Maybe<Scalars["String"]>
  markets: Array<Market>
  name: Scalars["String"]
  notificationEmail?: Maybe<Scalars["String"]>
  notificationPhone?: Maybe<Scalars["String"]>
  owner?: Maybe<User>
  ownerId: Scalars["String"]
  phoneNumber?: Maybe<Scalars["String"]>
  settings?: Maybe<BusinessSettings>
  smsNotifications?: Maybe<Scalars["Boolean"]>
  tagline?: Maybe<Scalars["String"]>
  transactions?: Maybe<Array<Maybe<Transaction>>>
  updatedAt?: Maybe<Scalars["DateTime"]>
  wallet?: Maybe<Wallet>
}

export type BusinessListRelationFilter = {
  every?: Maybe<BusinessWhereInput>
  none?: Maybe<BusinessWhereInput>
  some?: Maybe<BusinessWhereInput>
}

export type BusinessOrderByInput = {
  handle?: Maybe<OrderBy>
}

export type BusinessSettings = {
  __typename?: "BusinessSettings"
  business?: Maybe<Business>
  businessId?: Maybe<Scalars["String"]>
  createdAt: Scalars["DateTime"]
  emailChat?: Maybe<Scalars["Boolean"]>
  emailMarketing?: Maybe<Scalars["Boolean"]>
  emailNotifications?: Maybe<Scalars["Boolean"]>
  emailProductUpdates?: Maybe<Scalars["Boolean"]>
  id: Scalars["String"]
  isEmailValidated?: Maybe<Scalars["Boolean"]>
  isSmsValidated?: Maybe<Scalars["Boolean"]>
  languages?: Maybe<Array<Maybe<Languages>>>
  notificationEmail?: Maybe<Scalars["String"]>
  notificationPhone?: Maybe<Scalars["String"]>
  oneTimePassword?: Maybe<Scalars["String"]>
  showSmsOptIn?: Maybe<Scalars["Boolean"]>
  smsChat?: Maybe<Scalars["Boolean"]>
  smsNotifications?: Maybe<Scalars["Boolean"]>
  smsTransaction?: Maybe<Scalars["Boolean"]>
  timezone?: Maybe<Scalars["String"]>
  udpatedAt: Scalars["DateTime"]
}

export type BusinessSettingsWhereInput = {
  businessId?: Maybe<StringFilter>
  createdAt?: Maybe<Scalars["DateTime"]>
  emailChat?: Maybe<Scalars["Boolean"]>
  emailMarketing?: Maybe<Scalars["Boolean"]>
  emailNotifications?: Maybe<Scalars["Boolean"]>
  emailProductUpdates?: Maybe<Scalars["Boolean"]>
  id?: Maybe<Scalars["String"]>
  isEmailValidated?: Maybe<Scalars["Boolean"]>
  isSmsValidated?: Maybe<Scalars["Boolean"]>
  notificationEmail?: Maybe<StringFilter>
  notificationPhone?: Maybe<StringFilter>
  oneTimePassword?: Maybe<StringFilter>
  showSmsOptIn?: Maybe<Scalars["Boolean"]>
  smsChat?: Maybe<Scalars["Boolean"]>
  smsNotifications?: Maybe<Scalars["Boolean"]>
  smsTransaction?: Maybe<Scalars["Boolean"]>
  timezone?: Maybe<StringFilter>
  udpatedAt?: Maybe<Scalars["DateTime"]>
}

export type BusinessUpdateInput = {
  address?: Maybe<Scalars["String"]>
  ambassadorCode?: Maybe<Scalars["String"]>
  ambassadorId?: Maybe<Scalars["String"]>
  coverUrl?: Maybe<Scalars["String"]>
  description?: Maybe<Scalars["String"]>
  email?: Maybe<Scalars["String"]>
  emailNotifications?: Maybe<Scalars["Boolean"]>
  externalLinks?: Maybe<Array<Maybe<Scalars["String"]>>>
  handle?: Maybe<Scalars["String"]>
  isEmailValidated?: Maybe<Scalars["Boolean"]>
  isSmsValidated?: Maybe<Scalars["Boolean"]>
  kmsTaxId?: Maybe<Scalars["String"]>
  logoUrl?: Maybe<Scalars["String"]>
  marketName?: Maybe<Scalars["String"]>
  name?: Maybe<Scalars["String"]>
  notificationEmail?: Maybe<Scalars["String"]>
  notificationPhone?: Maybe<Scalars["String"]>
  phoneNumber?: Maybe<Scalars["String"]>
  showSmsOptIn?: Maybe<Scalars["Boolean"]>
  smsNotifications?: Maybe<Scalars["Boolean"]>
  tagline?: Maybe<Scalars["String"]>
}

export type BusinessUpdateManyMutationInput = {
  creditLimit?: Maybe<IntUpdateInput>
  isDisabled?: Maybe<BoolUpdateInput>
}

export type BusinessWhereInput = {
  AND?: Maybe<Array<Maybe<BusinessWhereInput>>>
  NOT?: Maybe<Array<Maybe<BusinessWhereInput>>>
  OR?: Maybe<Array<Maybe<BusinessWhereInput>>>
  address?: Maybe<StringFilter>
  ambassadorId?: Maybe<StringFilter>
  approved?: Maybe<BoolFilter>
  coverUrl?: Maybe<StringFilter>
  createdAt?: Maybe<DateTimeFilter>
  description?: Maybe<StringFilter>
  email?: Maybe<StringFilter>
  handle?: Maybe<StringFilter>
  id?: Maybe<StringFilter>
  isDisabled?: Maybe<BoolFilter>
  markets?: Maybe<Array<MarketWhereInput>>
  name?: Maybe<StringFilter>
  ownerId?: Maybe<StringFilter>
  phoneNumber?: Maybe<StringFilter>
  settings?: Maybe<BusinessSettingsWhereInput>
  showSmsOptIn?: Maybe<BoolFilter>
  tagline?: Maybe<StringFilter>
  updatedAt?: Maybe<DateTimeFilter>
  wallet?: Maybe<WalletWhereInput>
}

export type Catalog = {
  __typename?: "Catalog"
  coverImage: Scalars["String"]
  coverTitle: Scalars["String"]
  createdAt: Scalars["DateTime"]
  footerImage: Scalars["String"]
  footerTitle: Scalars["String"]
  id: Scalars["String"]
  isActive: Scalars["Boolean"]
  lists: Array<MarketplaceList>
  market?: Maybe<Market>
  updatedAt: Scalars["DateTime"]
}

export type CatalogWhereInput = {
  AND?: Maybe<Array<Maybe<CatalogWhereInput>>>
  NOT?: Maybe<Array<Maybe<CatalogWhereInput>>>
  OR?: Maybe<Array<Maybe<CatalogWhereInput>>>
  coverTitle?: Maybe<StringFilter>
  id?: Maybe<Scalars["String"]>
  isActive?: Maybe<Scalars["Boolean"]>
  market?: Maybe<MarketWhereInput>
}

export type CategoryUpdateInput = {
  icon?: Maybe<Scalars["String"]>
  name?: Maybe<Scalars["String"]>
}

export type CategoryWhereInput = {
  createdAt?: Maybe<DateTimeFilter>
  icon?: Maybe<StringFilter>
  id?: Maybe<StringFilter>
  name?: Maybe<StringFilter>
  parentId?: Maybe<StringFilter>
  updatedAt?: Maybe<DateTimeFilter>
}

export type Channel = {
  __typename?: "Channel"
  id?: Maybe<Scalars["String"]>
}

export type CipherPayload = {
  __typename?: "CipherPayload"
  data?: Maybe<Scalars["String"]>
}

export type CreateAmbassadorInput = {
  creditAllowance?: Maybe<Scalars["Int"]>
  handle: Scalars["String"]
  ownerId: Scalars["String"]
}

export type CreateBusinessInput = {
  address?: Maybe<Scalars["String"]>
  ambassadorCode?: Maybe<Scalars["String"]>
  ambassadorId?: Maybe<Scalars["String"]>
  coverUrl?: Maybe<Scalars["String"]>
  description?: Maybe<Scalars["String"]>
  email?: Maybe<Scalars["String"]>
  emailNotifications?: Maybe<Scalars["Boolean"]>
  externalLinks?: Maybe<Array<Maybe<Scalars["String"]>>>
  handle: Scalars["String"]
  isEmailValidated?: Maybe<Scalars["Boolean"]>
  isSmsValidated?: Maybe<Scalars["Boolean"]>
  kmsTaxId: Scalars["String"]
  logoUrl?: Maybe<Scalars["String"]>
  marketName?: Maybe<Scalars["String"]>
  name: Scalars["String"]
  notificationEmail?: Maybe<Scalars["String"]>
  notificationPhone?: Maybe<Scalars["String"]>
  ownerId: Scalars["String"]
  phoneNumber?: Maybe<Scalars["String"]>
  smsNotifications?: Maybe<Scalars["Boolean"]>
  tagline?: Maybe<Scalars["String"]>
}

export type CreateBusinessInviteInput = {
  address?: Maybe<Scalars["String"]>
  ambassadorCode?: Maybe<Scalars["String"]>
  ambassadorId?: Maybe<Scalars["String"]>
  coverUrl?: Maybe<Scalars["String"]>
  description?: Maybe<Scalars["String"]>
  email?: Maybe<Scalars["String"]>
  emailNotifications?: Maybe<Scalars["Boolean"]>
  externalLinks?: Maybe<Array<Maybe<Scalars["String"]>>>
  handle: Scalars["String"]
  isEmailValidated?: Maybe<Scalars["Boolean"]>
  isSmsValidated?: Maybe<Scalars["Boolean"]>
  kmsTaxId?: Maybe<Scalars["String"]>
  logoUrl?: Maybe<Scalars["String"]>
  name: Scalars["String"]
  notificationEmail?: Maybe<Scalars["String"]>
  notificationPhone?: Maybe<Scalars["String"]>
  phoneNumber?: Maybe<Scalars["String"]>
  smsNotifications?: Maybe<Scalars["Boolean"]>
  tagline?: Maybe<Scalars["String"]>
}

export type CreateBusinessWithListingsInput = {
  business: CreateBusinessInput
  listings?: Maybe<Array<Maybe<CreateListingWithoutBusinessInput>>>
}

export type CreateCategoryInput = {
  icon?: Maybe<Scalars["String"]>
  name?: Maybe<Scalars["String"]>
}

export type CreateListingInput = {
  address?: Maybe<Scalars["String"]>
  availability?: Maybe<Scalars["String"]>
  businessId: Scalars["String"]
  calendarLink?: Maybe<Scalars["String"]>
  categoryId?: Maybe<Scalars["String"]>
  cost?: Maybe<Scalars["Decimal"]>
  deliveryNotes?: Maybe<Scalars["String"]>
  description?: Maybe<Scalars["String"]>
  imageUrl?: Maybe<Scalars["String"]>
  isDeliverable?: Maybe<Scalars["Boolean"]>
  isLocal?: Maybe<Scalars["Boolean"]>
  isVirtual?: Maybe<Scalars["Boolean"]>
  maxPrice?: Maybe<Scalars["Decimal"]>
  minPrice?: Maybe<Scalars["Decimal"]>
  quantity?: Maybe<Scalars["Int"]>
  tags?: Maybe<Array<Maybe<Scalars["String"]>>>
  title?: Maybe<Scalars["String"]>
  type?: Maybe<ListingType>
  videoUrl?: Maybe<Scalars["String"]>
}

export type CreateListingWithoutBusinessInput = {
  address?: Maybe<Scalars["String"]>
  availability?: Maybe<Scalars["String"]>
  calendarLink?: Maybe<Scalars["String"]>
  categoryId?: Maybe<Scalars["String"]>
  cost?: Maybe<Scalars["Decimal"]>
  deliveryNotes?: Maybe<Scalars["String"]>
  description?: Maybe<Scalars["String"]>
  imageUrl?: Maybe<Scalars["String"]>
  isDeliverable?: Maybe<Scalars["Boolean"]>
  isLocal?: Maybe<Scalars["Boolean"]>
  isVirtual?: Maybe<Scalars["Boolean"]>
  maxPrice?: Maybe<Scalars["Decimal"]>
  minPrice?: Maybe<Scalars["Decimal"]>
  quantity?: Maybe<Scalars["Int"]>
  tags?: Maybe<Array<Maybe<Scalars["String"]>>>
  title?: Maybe<Scalars["String"]>
  type?: Maybe<ListingType>
  videoUrl?: Maybe<Scalars["String"]>
}

export type CreateMarketInput = {
  name: Scalars["String"]
}

export type CreateOrderInput = {
  deliveryAddress: Scalars["String"]
  fulfillmentMethod: Scalars["String"]
  listingId: Scalars["String"]
  note: Scalars["String"]
  quantity: Scalars["Int"]
  txData: Scalars["String"]
  txSignature: Scalars["String"]
}

export type CreateRequestFormToSlackInput = {
  businessEmail: Scalars["String"]
  businessName: Scalars["String"]
  businessPhone: Scalars["String"]
  companyLead?: Maybe<Scalars["String"]>
  contactMe: Scalars["String"]
  mainRequest: Scalars["String"]
  requestDetails?: Maybe<Scalars["String"]>
  user: Scalars["String"]
}

export type CreateTransactionInput = {
  amount: Scalars["Decimal"]
  note: Scalars["String"]
  recipientBusinessId: Scalars["String"]
  senderBusinessId: Scalars["String"]
}

export type DateTimeFilter = {
  equals?: Maybe<Scalars["DateTime"]>
  gt?: Maybe<Scalars["DateTime"]>
  gte?: Maybe<Scalars["DateTime"]>
  in?: Maybe<Array<Maybe<Scalars["DateTime"]>>>
  lt?: Maybe<Scalars["DateTime"]>
  lte?: Maybe<Scalars["DateTime"]>
  notIn?: Maybe<Array<Maybe<Scalars["DateTime"]>>>
}

export type DateTimeUpdateInput = {
  set?: Maybe<Scalars["DateTime"]>
}

export type DecimalFilter = {
  equals?: Maybe<Scalars["Decimal"]>
  gt?: Maybe<Scalars["Decimal"]>
  gte?: Maybe<Scalars["Decimal"]>
  in?: Maybe<Array<Maybe<Scalars["Decimal"]>>>
  lt?: Maybe<Scalars["Decimal"]>
  lte?: Maybe<Scalars["Decimal"]>
  not?: Maybe<NestedDecimalFilter>
  notIn?: Maybe<Array<Maybe<Scalars["Decimal"]>>>
}

export type EnumRoleFieldUpdateOperationsInput = {
  set?: Maybe<Role>
}

export type EnumRoleFilter = {
  equals?: Maybe<Role>
  in?: Maybe<Array<Maybe<Role>>>
  not?: Maybe<NestedEnumRoleFilter>
  notIn?: Maybe<Array<Maybe<Role>>>
}

export type EnumStateFieldUpdateOperationsInput = {
  set?: Maybe<OrderStatus>
}

export type FeedbackFormToSlack = {
  __typename?: "FeedbackFormToSlack"
  businessEmail: Scalars["String"]
  businessName: Scalars["String"]
  businessPhone: Scalars["String"]
  details?: Maybe<Scalars["String"]>
  feedback: Scalars["String"]
  priority: Scalars["String"]
  user: Scalars["String"]
}

export type FeedbackFormToSlackInput = {
  businessEmail: Scalars["String"]
  businessName: Scalars["String"]
  businessPhone: Scalars["String"]
  details?: Maybe<Scalars["String"]>
  feedback: Scalars["String"]
  priority: Scalars["String"]
  user: Scalars["String"]
}

export enum FulfillmentMethod {
  Delivery = "DELIVERY",
  Local = "LOCAL",
  Virtual = "VIRTUAL",
}

export type HandleNode = {
  __typename?: "HandleNode"
  handle?: Maybe<Scalars["String"]>
  isUnique?: Maybe<Scalars["Boolean"]>
}

export type IntFilter = {
  equals?: Maybe<Scalars["Int"]>
  gt?: Maybe<Scalars["Int"]>
  gte?: Maybe<Scalars["Int"]>
  in?: Maybe<Array<Maybe<Scalars["Int"]>>>
  lt?: Maybe<Scalars["Int"]>
  lte?: Maybe<Scalars["Int"]>
  not?: Maybe<NestedIntFilter>
  notIn?: Maybe<Array<Maybe<Scalars["Int"]>>>
}

export type IntUpdateInput = {
  decrement?: Maybe<Scalars["Int"]>
  divide?: Maybe<Scalars["Int"]>
  increment?: Maybe<Scalars["Int"]>
  multiply?: Maybe<Scalars["Int"]>
  set?: Maybe<Scalars["Int"]>
}

export type InviteAmbassadorInput = {
  creditAllowance?: Maybe<Scalars["Int"]>
  email: Scalars["String"]
  firstName?: Maybe<Scalars["String"]>
  handle: Scalars["String"]
  lastName?: Maybe<Scalars["String"]>
}

export type InviteBusinessInput = {
  business?: Maybe<CreateBusinessInviteInput>
  campaign?: Maybe<Scalars["String"]>
  email: Scalars["String"]
  firstName?: Maybe<Scalars["String"]>
  lastName?: Maybe<Scalars["String"]>
  type?: Maybe<Scalars["String"]>
}

export enum Languages {
  Afrikaans = "AFRIKAANS",
  Algerian = "ALGERIAN",
  Amharic = "AMHARIC",
  Arabic = "ARABIC",
  Aramaic = "ARAMAIC",
  Armenian = "ARMENIAN",
  Bengali = "BENGALI",
  Berber = "BERBER",
  Bhojpuri = "BHOJPURI",
  Bosnian = "BOSNIAN",
  Brazilian = "BRAZILIAN",
  Bulgarian = "BULGARIAN",
  Burmese = "BURMESE",
  Cantonese = "CANTONESE",
  Catalan = "CATALAN",
  Corsica = "CORSICA",
  Creole = "CREOLE",
  Croatian = "CROATIAN",
  Cypriot = "CYPRIOT",
  Czech = "CZECH",
  Danish = "DANISH",
  Dutch = "DUTCH",
  Egyptian = "EGYPTIAN",
  English = "ENGLISH",
  Esperanto = "ESPERANTO",
  Estonian = "ESTONIAN",
  Filipino = "FILIPINO",
  Finn = "FINN",
  Finnish = "FINNISH",
  Flemish = "FLEMISH",
  French = "FRENCH",
  Georgian = "GEORGIAN",
  German = "GERMAN",
  Greek = "GREEK",
  Guijarati = "GUIJARATI",
  Gypsy = "GYPSY",
  Hawaiian = "HAWAIIAN",
  Hebrew = "HEBREW",
  Hindi = "HINDI",
  Huasa = "HUASA",
  Hungarian = "HUNGARIAN",
  Icelandic = "ICELANDIC",
  Indonesian = "INDONESIAN",
  Inuit = "INUIT",
  Irish = "IRISH",
  Italian = "ITALIAN",
  Japanese = "JAPANESE",
  Javanese = "JAVANESE",
  Kannada = "KANNADA",
  Korean = "KOREAN",
  Latin = "LATIN",
  Lithuanian = "LITHUANIAN",
  Malay = "MALAY",
  Malayalam = "MALAYALAM",
  Mandarin = "MANDARIN",
  Nepalese = "NEPALESE",
  Norwegian = "NORWEGIAN",
  Panjabi = "PANJABI",
  Persian = "PERSIAN",
  Polish = "POLISH",
  Portuguese = "PORTUGUESE",
  Romani = "ROMANI",
  Romanian = "ROMANIAN",
  Russian = "RUSSIAN",
  Sanskrit = "SANSKRIT",
  Scottish = "SCOTTISH",
  Serbian = "SERBIAN",
  Signlanguage = "SIGNLANGUAGE",
  Slovak = "SLOVAK",
  Slovene = "SLOVENE",
  Spanish = "SPANISH",
  Swahili = "SWAHILI",
  Swedish = "SWEDISH",
  Tagalog = "TAGALOG",
  Tahitian = "TAHITIAN",
  Tamil = "TAMIL",
  Telugu = "TELUGU",
  Thai = "THAI",
  Tibetan = "TIBETAN",
  Turkish = "TURKISH",
  Ukrainian = "UKRAINIAN",
  Vietnamese = "VIETNAMESE",
  Welsh = "WELSH",
  Wu = "WU",
  Yoruba = "YORUBA",
}

export type Listing = {
  __typename?: "Listing"
  address?: Maybe<Scalars["String"]>
  availability?: Maybe<Scalars["String"]>
  business?: Maybe<Business>
  businessId: Scalars["String"]
  calendarLink?: Maybe<Scalars["String"]>
  category?: Maybe<ListingCategory>
  categoryId?: Maybe<Scalars["String"]>
  cost?: Maybe<Scalars["Decimal"]>
  createdAt: Scalars["DateTime"]
  deliveryNotes?: Maybe<Scalars["String"]>
  description?: Maybe<Scalars["String"]>
  id: Scalars["ID"]
  imageUrl?: Maybe<Scalars["String"]>
  isDeliverable?: Maybe<Scalars["Boolean"]>
  isDisabled?: Maybe<Scalars["Boolean"]>
  isFeatured?: Maybe<Scalars["Boolean"]>
  isLocal?: Maybe<Scalars["Boolean"]>
  isVirtual?: Maybe<Scalars["Boolean"]>
  maxPrice?: Maybe<Scalars["Decimal"]>
  minPrice?: Maybe<Scalars["Decimal"]>
  quantity?: Maybe<Scalars["Int"]>
  tags?: Maybe<Array<Maybe<Scalars["String"]>>>
  title?: Maybe<Scalars["String"]>
  type?: Maybe<ListingType>
  updatedAt: Scalars["DateTime"]
  videoUrl?: Maybe<Scalars["String"]>
}

export type ListingCategory = {
  __typename?: "ListingCategory"
  createdAt: Scalars["DateTime"]
  icon?: Maybe<Scalars["String"]>
  id: Scalars["ID"]
  name: Scalars["String"]
  parentId?: Maybe<Scalars["String"]>
}

export enum ListingType {
  Experience = "EXPERIENCE",
  Product = "PRODUCT",
  Service = "SERVICE",
}

export type ListingWhereInput = {
  AND?: Maybe<Array<Maybe<ListingWhereInput>>>
  NOT?: Maybe<Array<Maybe<ListingWhereInput>>>
  OR?: Maybe<Array<Maybe<ListingWhereInput>>>
  address?: Maybe<StringFilter>
  availability?: Maybe<StringFilter>
  businessId?: Maybe<Scalars["String"]>
  calendarLink?: Maybe<StringFilter>
  category?: Maybe<CategoryWhereInput>
  cost?: Maybe<DecimalFilter>
  createdAt?: Maybe<DateTimeFilter>
  deliveryNotes?: Maybe<StringFilter>
  description?: Maybe<StringFilter>
  id?: Maybe<StringFilter>
  isDeliverable?: Maybe<Scalars["Boolean"]>
  isDisabled?: Maybe<Scalars["Boolean"]>
  isFeatured?: Maybe<Scalars["Boolean"]>
  isLocal?: Maybe<Scalars["Boolean"]>
  isVirtual?: Maybe<Scalars["Boolean"]>
  maxPrice?: Maybe<DecimalFilter>
  minPrice?: Maybe<DecimalFilter>
  quantity?: Maybe<IntFilter>
  title?: Maybe<StringFilter>
  type?: Maybe<StringFilter>
  updatedAt?: Maybe<DateTimeFilter>
}

export type ManyAmbassador = {
  __typename?: "ManyAmbassador"
  ambassadors?: Maybe<Array<Maybe<Ambassador>>>
  total?: Maybe<Scalars["Int"]>
}

export type ManyBusiness = {
  __typename?: "ManyBusiness"
  businesses?: Maybe<Array<Maybe<Business>>>
  total?: Maybe<Scalars["Int"]>
}

export type ManyCatalog = {
  __typename?: "ManyCatalog"
  catalogs?: Maybe<Array<Maybe<Catalog>>>
  total?: Maybe<Scalars["Int"]>
}

export type ManyListing = {
  __typename?: "ManyListing"
  listings?: Maybe<Array<Maybe<Listing>>>
  total?: Maybe<Scalars["Int"]>
}

export type ManyListingCategory = {
  __typename?: "ManyListingCategory"
  categories?: Maybe<Array<Maybe<ListingCategory>>>
  total?: Maybe<Scalars["Int"]>
}

export type ManyMarketplaceList = {
  __typename?: "ManyMarketplaceList"
  marketplaceLists?: Maybe<Array<Maybe<MarketplaceList>>>
  total?: Maybe<Scalars["Int"]>
}

export type ManyTransaction = {
  __typename?: "ManyTransaction"
  total: Scalars["Int"]
  transactions: Array<Maybe<Transaction>>
}

export type Market = {
  __typename?: "Market"
  catalogs: Array<Catalog>
  createdAt: Scalars["DateTime"]
  id: Scalars["String"]
  name: Scalars["String"]
  updatedAt: Scalars["DateTime"]
}

export type MarketWhereInput = {
  AND?: Maybe<Array<Maybe<MarketWhereInput>>>
  NOT?: Maybe<Array<Maybe<MarketWhereInput>>>
  OR?: Maybe<Array<Maybe<MarketWhereInput>>>
  id?: Maybe<StringFilter>
  name?: Maybe<StringFilter>
}

export type MarketplaceList = {
  __typename?: "MarketplaceList"
  coverImage: Scalars["String"]
  createdAt: Scalars["DateTime"]
  icon?: Maybe<Scalars["String"]>
  id: Scalars["String"]
  listings: Array<Listing>
  order: Scalars["Int"]
  sublists: Array<MarketplaceList>
  title: Scalars["String"]
  updatedAt: Scalars["DateTime"]
}

export type Mutation = {
  __typename?: "Mutation"
  acceptInvite: AuthPayload
  assignCredit: Wallet
  checkEmailVerification: VerifyPayload
  checkSmsVerification: VerifyPayload
  claimWallet: Wallet
  createAmbassador: Ambassador
  createAmbassadorInvite: Ambassador
  createBusiness: Business
  createBusinessInvite: Business
  createCategory: ListingCategory
  createListing: Listing
  createMarket?: Maybe<Market>
  createOrder: Order
  deleteManyCategory: BatchCount
  deleteOneCategory?: Maybe<ListingCategory>
  disableUser?: Maybe<User>
  doubleEncryptHash: CipherPayload
  findOrCreateChannel: Channel
  loginUser: AuthPayload
  registerUser: AuthPayload
  requestEmailVerification: VerifyPayload
  requestReset?: Maybe<Scalars["Boolean"]>
  requestSmsVerification: VerifyPayload
  resetOTP?: Maybe<Scalars["Boolean"]>
  resetPassword: AuthPayload
  resetPasswordWithGuardian: AuthPayload
  retryTransaction: Transaction
  sendAmbassadorInvite: Ambassador
  sendBugFormToSlack?: Maybe<Scalars["Boolean"]>
  sendBusinessInvite: Business
  sendFeedbackFormToSlack?: Maybe<Scalars["Boolean"]>
  sendRequestFormToSlack?: Maybe<Scalars["Boolean"]>
  sendRusd: Transaction
  sendWelcomeEmail?: Maybe<Scalars["Boolean"]>
  toggleBusiness: Business
  toggleListing?: Maybe<Listing>
  updateAmbassador: Ambassador
  updateBusiness: Business
  updateBusinessSettingsByBusiness: BusinessSettings
  updateCatalog: Catalog
  updateListing?: Maybe<Listing>
  updateManyCategory?: Maybe<BatchCount>
  updateMarketplaceList: MarketplaceList
  updateOrderStatus: Order
  updateUser?: Maybe<User>
  verifyOTP?: Maybe<Scalars["Boolean"]>
}

export type MutationAcceptInviteArgs = {
  data?: Maybe<AcceptInviteInput>
}

export type MutationAssignCreditArgs = {
  id: Scalars["ID"]
  limit: Scalars["String"]
}

export type MutationCheckEmailVerificationArgs = {
  businessId: Scalars["ID"]
  code: Scalars["String"]
}

export type MutationCheckSmsVerificationArgs = {
  businessId: Scalars["ID"]
  code: Scalars["String"]
  to: Scalars["String"]
}

export type MutationClaimWalletArgs = {
  clientAddress: Scalars["String"]
  guardianAddress: Scalars["String"]
  id: Scalars["ID"]
}

export type MutationCreateAmbassadorArgs = {
  data?: Maybe<CreateAmbassadorInput>
}

export type MutationCreateAmbassadorInviteArgs = {
  data?: Maybe<InviteAmbassadorInput>
}

export type MutationCreateBusinessArgs = {
  data?: Maybe<CreateBusinessInput>
}

export type MutationCreateBusinessInviteArgs = {
  data?: Maybe<InviteBusinessInput>
}

export type MutationCreateCategoryArgs = {
  data?: Maybe<CreateCategoryInput>
}

export type MutationCreateListingArgs = {
  data?: Maybe<CreateListingInput>
}

export type MutationCreateMarketArgs = {
  data?: Maybe<CreateMarketInput>
}

export type MutationCreateOrderArgs = {
  data?: Maybe<CreateOrderInput>
}

export type MutationDeleteManyCategoryArgs = {
  where: CategoryWhereInput
}

export type MutationDeleteOneCategoryArgs = {
  id: Scalars["ID"]
}

export type MutationDisableUserArgs = {
  id: Scalars["ID"]
}

export type MutationDoubleEncryptHashArgs = {
  encryptedHash: Scalars["String"]
}

export type MutationFindOrCreateChannelArgs = {
  targetBusinessId: Scalars["ID"]
}

export type MutationLoginUserArgs = {
  email: Scalars["String"]
  password: Scalars["String"]
}

export type MutationRegisterUserArgs = {
  data: UserCreateInput
}

export type MutationRequestEmailVerificationArgs = {
  businessId: Scalars["ID"]
  email: Scalars["String"]
}

export type MutationRequestResetArgs = {
  email: Scalars["String"]
}

export type MutationRequestSmsVerificationArgs = {
  to: Scalars["String"]
}

export type MutationResetOtpArgs = {
  email: Scalars["String"]
}

export type MutationResetPasswordArgs = {
  confirmPassword: Scalars["String"]
  email: Scalars["String"]
  password: Scalars["String"]
  validateEmailToken: Scalars["String"]
}

export type MutationResetPasswordWithGuardianArgs = {
  email: Scalars["String"]
  newClientAddress: Scalars["String"]
  password: Scalars["String"]
  transactionId: Scalars["String"]
}

export type MutationRetryTransactionArgs = {
  transactionId: Scalars["ID"]
  txData: Scalars["String"]
  txSignature: Scalars["String"]
}

export type MutationSendAmbassadorInviteArgs = {
  id: Scalars["ID"]
}

export type MutationSendBugFormToSlackArgs = {
  data: BugFormToSlackInput
}

export type MutationSendBusinessInviteArgs = {
  id: Scalars["ID"]
}

export type MutationSendFeedbackFormToSlackArgs = {
  data: FeedbackFormToSlackInput
}

export type MutationSendRequestFormToSlackArgs = {
  data: CreateRequestFormToSlackInput
}

export type MutationSendRusdArgs = {
  data?: Maybe<SendRusdInput>
}

export type MutationSendWelcomeEmailArgs = {
  email: Scalars["String"]
}

export type MutationToggleBusinessArgs = {
  id: Scalars["ID"]
}

export type MutationToggleListingArgs = {
  id: Scalars["ID"]
}

export type MutationUpdateAmbassadorArgs = {
  data: AmbassadorUpdateInput
  id: Scalars["ID"]
}

export type MutationUpdateBusinessArgs = {
  data: BusinessUpdateInput
  id: Scalars["ID"]
}

export type MutationUpdateBusinessSettingsByBusinessArgs = {
  businessId: Scalars["ID"]
  data?: Maybe<UpdateBusinessSettingsInput>
}

export type MutationUpdateCatalogArgs = {
  data: UpdateCatalogInput
}

export type MutationUpdateListingArgs = {
  data?: Maybe<UpdateListingInput>
  id: Scalars["ID"]
}

export type MutationUpdateManyCategoryArgs = {
  data: CategoryUpdateInput
  where?: Maybe<CategoryWhereInput>
}

export type MutationUpdateMarketplaceListArgs = {
  data?: Maybe<UpdateMarketplaceListInput>
}

export type MutationUpdateOrderStatusArgs = {
  data: UpdateOrderStatusInput
  id: Scalars["ID"]
}

export type MutationUpdateUserArgs = {
  data?: Maybe<UserUpdateInput>
  id: Scalars["ID"]
}

export type MutationVerifyOtpArgs = {
  email: Scalars["String"]
  otp: Scalars["String"]
}

export type NestedBoolFilter = {
  equals?: Maybe<Scalars["Boolean"]>
  not?: Maybe<NestedBoolFilter>
}

export type NestedDateTimeFilter = {
  equals?: Maybe<Scalars["DateTime"]>
  gt?: Maybe<Scalars["DateTime"]>
  gte?: Maybe<Scalars["DateTime"]>
  in?: Maybe<Array<Maybe<Scalars["DateTime"]>>>
  lt?: Maybe<Scalars["DateTime"]>
  lte?: Maybe<Scalars["DateTime"]>
  not?: Maybe<DateTimeFilter>
  notIn?: Maybe<Array<Maybe<Scalars["DateTime"]>>>
}

export type NestedDecimalFilter = {
  equals?: Maybe<Scalars["Decimal"]>
  gt?: Maybe<Scalars["Decimal"]>
  gte?: Maybe<Scalars["Decimal"]>
  in?: Maybe<Array<Maybe<Scalars["Decimal"]>>>
  lt?: Maybe<Scalars["Decimal"]>
  lte?: Maybe<Scalars["Decimal"]>
  not?: Maybe<NestedDecimalFilter>
  notIn?: Maybe<Array<Maybe<Scalars["Decimal"]>>>
}

export type NestedEnumRoleFilter = {
  equals?: Maybe<Role>
  in?: Maybe<Array<Maybe<Role>>>
  notIn?: Maybe<Array<Maybe<Role>>>
}

export type NestedIntFilter = {
  equals?: Maybe<Scalars["Int"]>
  gt?: Maybe<Scalars["Int"]>
  gte?: Maybe<Scalars["Int"]>
  in?: Maybe<Array<Maybe<Scalars["Int"]>>>
  lt?: Maybe<Scalars["Int"]>
  lte?: Maybe<Scalars["Int"]>
  not?: Maybe<NestedIntFilter>
  notIn?: Maybe<Array<Maybe<Scalars["Int"]>>>
}

export type NestedStringFilter = {
  contains?: Maybe<Scalars["String"]>
  endsWith?: Maybe<Scalars["String"]>
  equals?: Maybe<Scalars["String"]>
  gt?: Maybe<Scalars["String"]>
  gte?: Maybe<Scalars["String"]>
  in?: Maybe<Array<Maybe<Scalars["String"]>>>
  lt?: Maybe<Scalars["String"]>
  lte?: Maybe<Scalars["String"]>
  not?: Maybe<NestedStringFilter>
  notIn?: Maybe<Array<Maybe<Scalars["String"]>>>
  startsWith?: Maybe<Scalars["String"]>
}

export type Order = {
  __typename?: "Order"
  amount: Scalars["Decimal"]
  createdAt: Scalars["DateTime"]
  deliveryAddress?: Maybe<Scalars["String"]>
  events?: Maybe<OrderEvent>
  fulfillmentMethod?: Maybe<FulfillmentMethod>
  id: Scalars["String"]
  listing?: Maybe<Listing>
  listingId: Scalars["String"]
  note: Scalars["String"]
  quantity: Scalars["Int"]
  status: OrderStatus
  updatedAt: Scalars["DateTime"]
}

export enum OrderBy {
  Asc = "asc",
  Desc = "desc",
}

export type OrderEvent = {
  __typename?: "OrderEvent"
  createdAt: Scalars["DateTime"]
  fromStatus: OrderStatus
  id: Scalars["String"]
  note?: Maybe<Scalars["String"]>
  order: Order
  orderId: Scalars["String"]
  toStatus: OrderStatus
}

export enum OrderStatus {
  Cancelled = "CANCELLED",
  Closed = "CLOSED",
  Inprogress = "INPROGRESS",
  Open = "OPEN",
}

export type OrderStatusFilter = {
  equals?: Maybe<OrderStatus>
}

export type OrderWhereInput = {
  AND?: Maybe<Array<Maybe<OrderWhereInput>>>
  NOT?: Maybe<Array<Maybe<OrderWhereInput>>>
  OR?: Maybe<Array<Maybe<OrderWhereInput>>>
  amount?: Maybe<DecimalFilter>
  createdAt?: Maybe<DateTimeFilter>
  id?: Maybe<StringFilter>
  listing?: Maybe<ListingWhereInput>
  listingId?: Maybe<StringFilter>
  note?: Maybe<StringFilter>
  quantity?: Maybe<IntFilter>
  status?: Maybe<OrderStatusFilter>
  transaction?: Maybe<TransactionWhereInput>
  transactionId?: Maybe<StringFilter>
}

export type OrderWhereUniqueInput = {
  id?: Maybe<Scalars["String"]>
}

export type Query = {
  __typename?: "Query"
  allBusinessTransactions?: Maybe<Array<Maybe<Transaction>>>
  allOrders?: Maybe<Array<Maybe<Order>>>
  businessTransactionStats?: Maybe<TransactionStatsOutput>
  countAllBusiness?: Maybe<Scalars["Int"]>
  countAllCategory?: Maybe<Scalars["Int"]>
  countAllListing?: Maybe<Scalars["Int"]>
  countAllOrders: Scalars["Int"]
  countAllTransactions?: Maybe<Scalars["Int"]>
  countAllUsers?: Maybe<Scalars["Int"]>
  findAllUsers: Array<AdminUser>
  findByBusiness?: Maybe<ManyListing>
  findCatalogById?: Maybe<Catalog>
  findFirstBusiness?: Maybe<Business>
  findFirstCatalog?: Maybe<Catalog>
  findManyAmbassador?: Maybe<ManyAmbassador>
  findManyBusiness?: Maybe<ManyBusiness>
  findManyCatalog?: Maybe<ManyCatalog>
  findManyCategory?: Maybe<ManyListingCategory>
  findManyListing?: Maybe<ManyListing>
  findManyTransactions?: Maybe<ManyTransaction>
  findMarketplaceListById?: Maybe<MarketplaceList>
  findMarkets: Array<Market>
  findOneAmbassadorByHandle?: Maybe<Ambassador>
  findOneAmbassadorById?: Maybe<Ambassador>
  findOneBusinessByHandle?: Maybe<Business>
  findOneBusinessById?: Maybe<Business>
  findOneCategory?: Maybe<ListingCategory>
  findOneListing?: Maybe<Listing>
  findOneOrder?: Maybe<Order>
  findTransactionById?: Maybe<Transaction>
  findTransactionByTxId?: Maybe<Transaction>
  findUserByEmail?: Maybe<User>
  findUserById?: Maybe<User>
  getBusinessStreamToken?: Maybe<Scalars["String"]>
  getOrderTotal: Scalars["Decimal"]
  isAmbassadorHandleUnique: HandleNode
  isBusinessHandleUnique: HandleNode
  isEmailUnique?: Maybe<Scalars["Boolean"]>
  isInviteCodeValid?: Maybe<Scalars["Boolean"]>
  me?: Maybe<User>
  searchBusinesses: ManyBusiness
  searchListings: ManyListing
  totalTransactionVolume?: Maybe<Scalars["Decimal"]>
  walletBalance: WalletBalance
}

export type QueryAllBusinessTransactionsArgs = {
  id: Scalars["ID"]
  skip?: Maybe<Scalars["Int"]>
  take?: Maybe<Scalars["Int"]>
}

export type QueryAllOrdersArgs = {
  skip?: Maybe<Scalars["Int"]>
  take?: Maybe<Scalars["Int"]>
  where?: Maybe<OrderWhereInput>
}

export type QueryBusinessTransactionStatsArgs = {
  businessId: Scalars["ID"]
}

export type QueryCountAllBusinessArgs = {
  where?: Maybe<BusinessWhereInput>
}

export type QueryCountAllCategoryArgs = {
  where?: Maybe<CategoryWhereInput>
}

export type QueryCountAllListingArgs = {
  where?: Maybe<ListingWhereInput>
}

export type QueryCountAllOrdersArgs = {
  where?: Maybe<OrderWhereInput>
}

export type QueryCountAllTransactionsArgs = {
  where?: Maybe<TransactionWhereInput>
}

export type QueryCountAllUsersArgs = {
  where?: Maybe<UserWhereInput>
}

export type QueryFindAllUsersArgs = {
  skip?: Maybe<Scalars["Int"]>
  take?: Maybe<Scalars["Int"]>
  where?: Maybe<UserWhereInput>
}

export type QueryFindByBusinessArgs = {
  id: Scalars["ID"]
  limit?: Maybe<Scalars["Int"]>
  page?: Maybe<Scalars["Int"]>
}

export type QueryFindCatalogByIdArgs = {
  id: Scalars["ID"]
}

export type QueryFindFirstBusinessArgs = {
  where: BusinessWhereInput
}

export type QueryFindFirstCatalogArgs = {
  where: CatalogWhereInput
}

export type QueryFindManyAmbassadorArgs = {
  limit?: Maybe<Scalars["Int"]>
  page?: Maybe<Scalars["Int"]>
  where?: Maybe<AmbassadorWhereInput>
}

export type QueryFindManyBusinessArgs = {
  limit?: Maybe<Scalars["Int"]>
  page?: Maybe<Scalars["Int"]>
  where?: Maybe<BusinessWhereInput>
}

export type QueryFindManyCategoryArgs = {
  where?: Maybe<CategoryWhereInput>
}

export type QueryFindManyListingArgs = {
  limit?: Maybe<Scalars["Int"]>
  page?: Maybe<Scalars["Int"]>
  where?: Maybe<ListingWhereInput>
}

export type QueryFindManyTransactionsArgs = {
  limit?: Maybe<Scalars["Int"]>
  orderBy?: Maybe<TransactionOrderByInput>
  page?: Maybe<Scalars["Int"]>
  where?: Maybe<TransactionWhereInput>
}

export type QueryFindMarketplaceListByIdArgs = {
  id: Scalars["ID"]
}

export type QueryFindMarketsArgs = {
  where?: Maybe<MarketWhereInput>
}

export type QueryFindOneAmbassadorByHandleArgs = {
  handle: Scalars["String"]
}

export type QueryFindOneAmbassadorByIdArgs = {
  id: Scalars["ID"]
}

export type QueryFindOneBusinessByHandleArgs = {
  handle: Scalars["String"]
}

export type QueryFindOneBusinessByIdArgs = {
  id: Scalars["ID"]
}

export type QueryFindOneCategoryArgs = {
  id: Scalars["ID"]
}

export type QueryFindOneListingArgs = {
  id: Scalars["ID"]
}

export type QueryFindOneOrderArgs = {
  id: Scalars["ID"]
}

export type QueryFindTransactionByIdArgs = {
  id: Scalars["ID"]
}

export type QueryFindTransactionByTxIdArgs = {
  txId: Scalars["String"]
}

export type QueryFindUserByEmailArgs = {
  email: Scalars["String"]
}

export type QueryFindUserByIdArgs = {
  id: Scalars["ID"]
}

export type QueryGetBusinessStreamTokenArgs = {
  businessId: Scalars["ID"]
}

export type QueryGetOrderTotalArgs = {
  listingId: Scalars["ID"]
  quantity: Scalars["Int"]
}

export type QueryIsAmbassadorHandleUniqueArgs = {
  handle: Scalars["String"]
}

export type QueryIsBusinessHandleUniqueArgs = {
  handle: Scalars["String"]
}

export type QueryIsEmailUniqueArgs = {
  email: Scalars["String"]
}

export type QueryIsInviteCodeValidArgs = {
  code: Scalars["String"]
}

export type QuerySearchBusinessesArgs = {
  filters?: Maybe<Scalars["String"]>
  limit?: Maybe<Scalars["Int"]>
  page?: Maybe<Scalars["Int"]>
  query: Scalars["String"]
}

export type QuerySearchListingsArgs = {
  filters?: Maybe<Scalars["String"]>
  limit?: Maybe<Scalars["Int"]>
  page?: Maybe<Scalars["Int"]>
  query: Scalars["String"]
}

export type QueryTotalTransactionVolumeArgs = {
  where?: Maybe<TransactionWhereInput>
}

export type QueryWalletBalanceArgs = {
  id: Scalars["ID"]
}

export type RequestFormToSlack = {
  __typename?: "RequestFormToSlack"
  businessEmail: Scalars["String"]
  businessName: Scalars["String"]
  businessPhone: Scalars["String"]
  companyLead?: Maybe<Scalars["String"]>
  contactMe: Scalars["String"]
  mainRequest: Scalars["String"]
  requestDetails?: Maybe<Scalars["String"]>
  user: Scalars["String"]
}

export enum Role {
  Admin = "ADMIN",
  User = "USER",
}

export type SendRusdInput = {
  amount: Scalars["Decimal"]
  note: Scalars["String"]
  recipientBusinessId: Scalars["String"]
  senderBusinessId: Scalars["String"]
  txData: Scalars["String"]
  txSignature: Scalars["String"]
}

export type StringFilter = {
  contains?: Maybe<Scalars["String"]>
  endsWith?: Maybe<Scalars["String"]>
  equals?: Maybe<Scalars["String"]>
  gt?: Maybe<Scalars["String"]>
  gte?: Maybe<Scalars["String"]>
  in?: Maybe<Array<Maybe<Scalars["String"]>>>
  lt?: Maybe<Scalars["String"]>
  lte?: Maybe<Scalars["String"]>
  not?: Maybe<NestedStringFilter>
  notIn?: Maybe<Array<Maybe<Scalars["String"]>>>
  startsWith?: Maybe<Scalars["String"]>
}

export type Transaction = {
  __typename?: "Transaction"
  amount: Scalars["Decimal"]
  createdAt?: Maybe<Scalars["DateTime"]>
  id: Scalars["ID"]
  note?: Maybe<Scalars["String"]>
  order?: Maybe<Order>
  recipient?: Maybe<Wallet>
  recipientId: Scalars["String"]
  sender?: Maybe<Wallet>
  senderId: Scalars["String"]
  status?: Maybe<TransactionStatus>
  txHash?: Maybe<Scalars["String"]>
  txId?: Maybe<Scalars["String"]>
}

export type TransactionOrderByInput = {
  amount?: Maybe<OrderBy>
  createdAt?: Maybe<OrderBy>
  status?: Maybe<OrderBy>
}

export type TransactionStatsOutput = {
  __typename?: "TransactionStatsOutput"
  count: Scalars["Int"]
  volume: Scalars["Decimal"]
}

export enum TransactionStatus {
  Confirmed = "CONFIRMED",
  Failed = "FAILED",
  Pending = "PENDING",
  Queued = "QUEUED",
}

export type TransactionStatusFilter = {
  equals?: Maybe<TransactionStatus>
}

export type TransactionWhereInput = {
  AND?: Maybe<Array<Maybe<TransactionWhereInput>>>
  NOT?: Maybe<Array<Maybe<TransactionWhereInput>>>
  OR?: Maybe<Array<Maybe<TransactionWhereInput>>>
  amount?: Maybe<DecimalFilter>
  createdAt?: Maybe<DateTimeFilter>
  id?: Maybe<StringFilter>
  listing?: Maybe<ListingWhereInput>
  listingId?: Maybe<StringFilter>
  order?: Maybe<OrderWhereInput>
  orderId?: Maybe<StringFilter>
  recipient?: Maybe<BusinessWhereInput>
  recipientId?: Maybe<StringFilter>
  sender?: Maybe<WalletWhereInput>
  senderId?: Maybe<StringFilter>
  status?: Maybe<TransactionStatusFilter>
}

export type TransactionWhereUniqueInput = {
  id?: Maybe<Scalars["String"]>
}

export type UpdateBusinessSettingsInput = {
  emailChat?: Maybe<Scalars["Boolean"]>
  emailMarketing?: Maybe<Scalars["Boolean"]>
  emailNotifications?: Maybe<Scalars["Boolean"]>
  emailProductUpdates?: Maybe<Scalars["Boolean"]>
  isEmailValidated?: Maybe<Scalars["Boolean"]>
  isSmsValidated?: Maybe<Scalars["Boolean"]>
  languages?: Maybe<Array<Maybe<Languages>>>
  notificationEmail?: Maybe<Scalars["String"]>
  notificationPhone?: Maybe<Scalars["String"]>
  oneTimePassword?: Maybe<Scalars["String"]>
  showSmsOptIn?: Maybe<Scalars["Boolean"]>
  smsChat?: Maybe<Scalars["Boolean"]>
  smsNotifications?: Maybe<Scalars["Boolean"]>
  smsTransaction?: Maybe<Scalars["Boolean"]>
  timezone?: Maybe<Scalars["String"]>
}

export type UpdateCatalogInput = {
  coverImage: Scalars["String"]
  coverTitle: Scalars["String"]
  footerImage: Scalars["String"]
  footerTitle: Scalars["String"]
  id: Scalars["ID"]
  isActive: Scalars["Boolean"]
  lists: Array<UpdateMarketplaceListInput>
}

export type UpdateListingInput = {
  address?: Maybe<Scalars["String"]>
  availability?: Maybe<Scalars["String"]>
  calendarLink?: Maybe<Scalars["String"]>
  categoryId?: Maybe<Scalars["String"]>
  cost?: Maybe<Scalars["Decimal"]>
  deliveryNotes?: Maybe<Scalars["String"]>
  description?: Maybe<Scalars["String"]>
  imageUrl?: Maybe<Scalars["String"]>
  isDeliverable?: Maybe<Scalars["Boolean"]>
  isFeatured?: Maybe<Scalars["Boolean"]>
  isLocal?: Maybe<Scalars["Boolean"]>
  isVirtual?: Maybe<Scalars["Boolean"]>
  maxPrice?: Maybe<Scalars["Decimal"]>
  minPrice?: Maybe<Scalars["Decimal"]>
  quantity?: Maybe<Scalars["Int"]>
  tags?: Maybe<Array<Maybe<Scalars["String"]>>>
  title?: Maybe<Scalars["String"]>
  type?: Maybe<Scalars["String"]>
  videoUrl?: Maybe<Scalars["String"]>
}

export type UpdateListingsOnMarketplaceListInput = {
  business?: Maybe<BusinessUpdateInput>
  id: Scalars["ID"]
  order?: Maybe<Scalars["Int"]>
}

export type UpdateMarketplaceListInput = {
  coverImage: Scalars["String"]
  icon?: Maybe<Scalars["String"]>
  id: Scalars["ID"]
  listings: Array<UpdateListingsOnMarketplaceListInput>
  order?: Maybe<Scalars["Int"]>
  sublists: Array<UpdateMarketplaceListInput>
  title: Scalars["String"]
}

export type UpdateOrderStatusInput = {
  note?: Maybe<Scalars["String"]>
  status: OrderStatus
}

export type User = {
  __typename?: "User"
  ambassador?: Maybe<Ambassador>
  avatarUrl?: Maybe<Scalars["String"]>
  business?: Maybe<Business>
  campaign?: Maybe<Scalars["String"]>
  createdAt: Scalars["DateTime"]
  email: Scalars["String"]
  firstName?: Maybe<Scalars["String"]>
  id: Scalars["String"]
  isAdmin: Scalars["Boolean"]
  isDisabled: Scalars["Boolean"]
  isEmailValidated: Scalars["Boolean"]
  lastLogin?: Maybe<Scalars["DateTime"]>
  lastName?: Maybe<Scalars["String"]>
  password: Scalars["String"]
  requestedCategories?: Maybe<Array<Maybe<Scalars["String"]>>>
  role: Role
  shouldResetPassword: Scalars["Boolean"]
  type?: Maybe<UserType>
  updatedAt: Scalars["DateTime"]
}

export type UserCreateInput = {
  campaign?: Maybe<Scalars["String"]>
  email: Scalars["String"]
  firstName: Scalars["String"]
  lastName: Scalars["String"]
  password: Scalars["String"]
  sendOTPEmail?: Maybe<Scalars["Boolean"]>
  type?: Maybe<Scalars["String"]>
}

export enum UserType {
  Cloudcity = "CLOUDCITY",
  Employee = "EMPLOYEE",
  Local = "LOCAL",
}

export type UserUpdateInput = {
  avatarUrl?: Maybe<Scalars["String"]>
  campaign?: Maybe<Scalars["String"]>
  email?: Maybe<Scalars["String"]>
  firstName?: Maybe<Scalars["String"]>
  handle?: Maybe<Scalars["String"]>
  isEmailValidated?: Maybe<Scalars["Boolean"]>
  lastLogin?: Maybe<Scalars["DateTime"]>
  lastName?: Maybe<Scalars["String"]>
  password?: Maybe<Scalars["String"]>
  role?: Maybe<Role>
  type?: Maybe<UserType>
  updatedAt?: Maybe<Scalars["DateTime"]>
}

export type UserUpdateManyMutationInput = {
  isAdmin?: Maybe<BoolUpdateInput>
  isDisabled?: Maybe<BoolUpdateInput>
  lastLogin?: Maybe<DateTimeUpdateInput>
  role?: Maybe<EnumRoleFieldUpdateOperationsInput>
  shouldResetPassword?: Maybe<BoolUpdateInput>
}

export type UserWhereInput = {
  AND?: Maybe<Array<Maybe<UserWhereInput>>>
  NOT?: Maybe<Array<Maybe<UserWhereInput>>>
  OR?: Maybe<Array<Maybe<UserWhereInput>>>
  business?: Maybe<BusinessListRelationFilter>
  campaign?: Maybe<StringFilter>
  createdAt?: Maybe<DateTimeFilter>
  email?: Maybe<StringFilter>
  firstName?: Maybe<StringFilter>
  id?: Maybe<StringFilter>
  isAdmin?: Maybe<BoolFilter>
  isDisabled?: Maybe<BoolFilter>
  isEmailValidated?: Maybe<BoolFilter>
  lastLogin?: Maybe<DateTimeFilter>
  lastName?: Maybe<StringFilter>
  password?: Maybe<StringFilter>
  role?: Maybe<EnumRoleFilter>
  shouldResetPassword?: Maybe<BoolFilter>
  type?: Maybe<StringFilter>
  updatedAt?: Maybe<DateTimeFilter>
}

export type UserWhereUniqueInput = {
  email?: Maybe<Scalars["String"]>
  id?: Maybe<Scalars["String"]>
}

export type VerifyPayload = {
  __typename?: "VerifyPayload"
  data?: Maybe<Scalars["String"]>
  error?: Maybe<Scalars["Boolean"]>
}

export type Wallet = {
  __typename?: "Wallet"
  business?: Maybe<Business>
  businessId: Scalars["String"]
  clientAddress?: Maybe<Scalars["String"]>
  createdAt?: Maybe<Scalars["DateTime"]>
  creditLimit?: Maybe<Scalars["Decimal"]>
  id: Scalars["String"]
  isActive: Scalars["Boolean"]
  multiSigAddress?: Maybe<Scalars["String"]>
}

export type WalletBalance = {
  __typename?: "WalletBalance"
  availableCredit?: Maybe<Scalars["Decimal"]>
  balance?: Maybe<Scalars["Decimal"]>
  creditLimit?: Maybe<Scalars["Decimal"]>
}

export type WalletWhereInput = {
  AND?: Maybe<Array<Maybe<WalletWhereInput>>>
  NOT?: Maybe<Array<Maybe<WalletWhereInput>>>
  OR?: Maybe<Array<Maybe<WalletWhereInput>>>
  businessId?: Maybe<StringFilter>
  id?: Maybe<StringFilter>
  multiSigAddress?: Maybe<StringFilter>
}

export type AmbassadorFieldsFragment = {
  __typename?: "Ambassador"
  id: string
  handle: string
  creditAllowance?: Maybe<number>
  ownerId: string
  refCode: string
}

export type FindOneAmbassadorByHandleQueryVariables = Exact<{
  handle: Scalars["String"]
}>

export type FindOneAmbassadorByHandleQuery = {
  __typename?: "Query"
  findOneAmbassadorByHandle?: Maybe<
    {
      __typename?: "Ambassador"
      businesses?: Maybe<
        Array<
          Maybe<{
            __typename?: "Business"
            id: string
            name: string
            handle: string
            logoUrl?: Maybe<string>
            createdAt?: Maybe<any>
            owner?: Maybe<{
              __typename?: "User"
              firstName?: Maybe<string>
              lastName?: Maybe<string>
            }>
            transactions?: Maybe<
              Array<
                Maybe<{
                  __typename?: "Transaction"
                  id: string
                  createdAt?: Maybe<any>
                  amount: any
                }>
              >
            >
            listings?: Maybe<Array<Maybe<{ __typename?: "Listing"; id: string }>>>
          }>
        >
      >
    } & AmbassadorFieldsFragment
  >
}

export type RegisterUserMutationVariables = Exact<{
  data: UserCreateInput
}>

export type RegisterUserMutation = {
  __typename?: "Mutation"
  registerUser: {
    __typename?: "AuthPayload"
    authToken: string
    user: {
      __typename?: "User"
      id: string
      role: Role
      firstName?: Maybe<string>
      lastName?: Maybe<string>
      email: string
    }
  }
}

export type LoginUserMutationVariables = Exact<{
  email: Scalars["String"]
  password: Scalars["String"]
}>

export type LoginUserMutation = {
  __typename?: "Mutation"
  loginUser: {
    __typename?: "AuthPayload"
    authToken: string
    user: { __typename?: "User" } & MeFieldsFragment
  }
}

export type RequestResetMutationVariables = Exact<{
  email: Scalars["String"]
}>

export type RequestResetMutation = { __typename?: "Mutation"; requestReset?: Maybe<boolean> }

export type SendWelcomeEmailMutationVariables = Exact<{
  email: Scalars["String"]
}>

export type SendWelcomeEmailMutation = {
  __typename?: "Mutation"
  sendWelcomeEmail?: Maybe<boolean>
}

export type AcceptInviteMutationVariables = Exact<{
  data?: Maybe<AcceptInviteInput>
}>

export type AcceptInviteMutation = {
  __typename?: "Mutation"
  acceptInvite: {
    __typename?: "AuthPayload"
    authToken: string
    user: { __typename?: "User"; id: string }
  }
}

export type ResetPasswordMutationVariables = Exact<{
  email: Scalars["String"]
  password: Scalars["String"]
  confirmPassword: Scalars["String"]
  validateEmailToken: Scalars["String"]
}>

export type ResetPasswordMutation = {
  __typename?: "Mutation"
  resetPassword: {
    __typename?: "AuthPayload"
    authToken: string
    user: { __typename?: "User"; id: string }
  }
}

export type ResetPasswordWithGuardianMutationVariables = Exact<{
  email: Scalars["String"]
  newClientAddress: Scalars["String"]
  password: Scalars["String"]
  transactionId: Scalars["String"]
}>

export type ResetPasswordWithGuardianMutation = {
  __typename?: "Mutation"
  resetPasswordWithGuardian: {
    __typename?: "AuthPayload"
    authToken: string
    user: {
      __typename?: "User"
      id: string
      business?: Maybe<{
        __typename?: "Business"
        wallet?: Maybe<{ __typename?: "Wallet"; id: string }>
      }>
    }
  }
}

export type VerifyOtpMutationVariables = Exact<{
  email: Scalars["String"]
  otp: Scalars["String"]
}>

export type VerifyOtpMutation = { __typename?: "Mutation"; verifyOTP?: Maybe<boolean> }

export type ResetOtpMutationVariables = Exact<{
  email: Scalars["String"]
}>

export type ResetOtpMutation = { __typename?: "Mutation"; resetOTP?: Maybe<boolean> }

export type IsEmailUniqueQueryVariables = Exact<{
  email: Scalars["String"]
}>

export type IsEmailUniqueQuery = { __typename?: "Query"; isEmailUnique?: Maybe<boolean> }

export type BusinessFieldsFragment = {
  __typename?: "Business"
  id: string
  name: string
  tagline?: Maybe<string>
  description?: Maybe<string>
  address?: Maybe<string>
  logoUrl?: Maybe<string>
  coverUrl?: Maybe<string>
  externalLinks?: Maybe<Array<Maybe<string>>>
  isDisabled?: Maybe<boolean>
  phoneNumber?: Maybe<string>
  approved?: Maybe<boolean>
  handle: string
  createdAt?: Maybe<any>
  email?: Maybe<string>
  ownerId: string
  settings?: Maybe<{ __typename?: "BusinessSettings" } & BusinessSettingsFieldsFragment>
  wallet?: Maybe<{ __typename?: "Wallet" } & WalletFieldsFragment>
  markets: Array<{ __typename?: "Market"; name: string; id: string }>
}

export type CreateBusinessMutationVariables = Exact<{
  data?: Maybe<CreateBusinessInput>
}>

export type CreateBusinessMutation = {
  __typename?: "Mutation"
  createBusiness: {
    __typename?: "Business"
    id: string
    handle: string
    logoUrl?: Maybe<string>
    wallet?: Maybe<{ __typename?: "Wallet"; id: string; multiSigAddress?: Maybe<string> }>
  }
}

export type UpdateBusinessMutationVariables = Exact<{
  data: BusinessUpdateInput
  id: Scalars["ID"]
}>

export type UpdateBusinessMutation = {
  __typename?: "Mutation"
  updateBusiness: { __typename?: "Business" } & BusinessFieldsFragment
}

export type ToggleBusinessMutationVariables = Exact<{
  id: Scalars["ID"]
}>

export type ToggleBusinessMutation = {
  __typename?: "Mutation"
  toggleBusiness: { __typename?: "Business"; id: string }
}

export type RequestEmailVerificationMutationVariables = Exact<{
  email: Scalars["String"]
  businessId: Scalars["ID"]
}>

export type RequestEmailVerificationMutation = {
  __typename?: "Mutation"
  requestEmailVerification: {
    __typename?: "VerifyPayload"
    error?: Maybe<boolean>
    data?: Maybe<string>
  }
}

export type CheckEmailVerificationMutationVariables = Exact<{
  code: Scalars["String"]
  businessId: Scalars["ID"]
}>

export type CheckEmailVerificationMutation = {
  __typename?: "Mutation"
  checkEmailVerification: {
    __typename?: "VerifyPayload"
    error?: Maybe<boolean>
    data?: Maybe<string>
  }
}

export type RequestSmsVerificationMutationVariables = Exact<{
  to: Scalars["String"]
}>

export type RequestSmsVerificationMutation = {
  __typename?: "Mutation"
  requestSmsVerification: {
    __typename?: "VerifyPayload"
    error?: Maybe<boolean>
    data?: Maybe<string>
  }
}

export type CheckSmsVerificationMutationVariables = Exact<{
  to: Scalars["String"]
  code: Scalars["String"]
  businessId: Scalars["ID"]
}>

export type CheckSmsVerificationMutation = {
  __typename?: "Mutation"
  checkSmsVerification: {
    __typename?: "VerifyPayload"
    error?: Maybe<boolean>
    data?: Maybe<string>
  }
}

export type FindBusinessByIdQueryVariables = Exact<{
  id: Scalars["ID"]
}>

export type FindBusinessByIdQuery = {
  __typename?: "Query"
  findOneBusinessById?: Maybe<
    {
      __typename?: "Business"
      listings?: Maybe<Array<Maybe<{ __typename?: "Listing" } & ListingFieldsFragment>>>
      settings?: Maybe<{ __typename?: "BusinessSettings" } & BusinessSettingsFieldsFragment>
    } & BusinessFieldsFragment
  >
}

export type FindBusinessByHandleQueryVariables = Exact<{
  handle: Scalars["String"]
}>

export type FindBusinessByHandleQuery = {
  __typename?: "Query"
  findOneBusinessByHandle?: Maybe<
    {
      __typename?: "Business"
      settings?: Maybe<{ __typename?: "BusinessSettings" } & BusinessSettingsFieldsFragment>
      listings?: Maybe<
        Array<
          Maybe<
            {
              __typename?: "Listing"
              business?: Maybe<{ __typename?: "Business"; handle: string; name: string }>
            } & ListingFieldsFragment
          >
        >
      >
    } & BusinessFieldsFragment
  >
}

export type IsBusinessHandleUniqueQueryVariables = Exact<{
  handle: Scalars["String"]
}>

export type IsBusinessHandleUniqueQuery = {
  __typename?: "Query"
  isBusinessHandleUnique: {
    __typename?: "HandleNode"
    handle?: Maybe<string>
    isUnique?: Maybe<boolean>
  }
}

export type SearchBusinessesQueryVariables = Exact<{
  query: Scalars["String"]
  filters?: Maybe<Scalars["String"]>
  page?: Maybe<Scalars["Int"]>
  limit?: Maybe<Scalars["Int"]>
}>

export type SearchBusinessesQuery = {
  __typename?: "Query"
  searchBusinesses: {
    __typename?: "ManyBusiness"
    total?: Maybe<number>
    businesses?: Maybe<
      Array<
        Maybe<{
          __typename?: "Business"
          id: string
          address?: Maybe<string>
          coverUrl?: Maybe<string>
          description?: Maybe<string>
          handle: string
          logoUrl?: Maybe<string>
          name: string
          tagline?: Maybe<string>
        }>
      >
    >
  }
}

export type BusinessSettingsFieldsFragment = {
  __typename?: "BusinessSettings"
  id: string
  businessId?: Maybe<string>
  emailChat?: Maybe<boolean>
  emailMarketing?: Maybe<boolean>
  emailNotifications?: Maybe<boolean>
  emailProductUpdates?: Maybe<boolean>
  isEmailValidated?: Maybe<boolean>
  isSmsValidated?: Maybe<boolean>
  languages?: Maybe<Array<Maybe<Languages>>>
  notificationEmail?: Maybe<string>
  notificationPhone?: Maybe<string>
  smsChat?: Maybe<boolean>
  smsNotifications?: Maybe<boolean>
  smsTransaction?: Maybe<boolean>
  timezone?: Maybe<string>
  showSmsOptIn?: Maybe<boolean>
}

export type UpdateSettingsByBusinessMutationVariables = Exact<{
  businessId: Scalars["ID"]
  data: UpdateBusinessSettingsInput
}>

export type UpdateSettingsByBusinessMutation = {
  __typename?: "Mutation"
  updateBusinessSettingsByBusiness: {
    __typename?: "BusinessSettings"
    id: string
    businessId?: Maybe<string>
    emailChat?: Maybe<boolean>
    emailMarketing?: Maybe<boolean>
    emailNotifications?: Maybe<boolean>
    emailProductUpdates?: Maybe<boolean>
    isEmailValidated?: Maybe<boolean>
    isSmsValidated?: Maybe<boolean>
    languages?: Maybe<Array<Maybe<Languages>>>
    notificationEmail?: Maybe<string>
    notificationPhone?: Maybe<string>
    smsChat?: Maybe<boolean>
    smsNotifications?: Maybe<boolean>
    smsTransaction?: Maybe<boolean>
    timezone?: Maybe<string>
    showSmsOptIn?: Maybe<boolean>
  }
}

export type FindCatalogByIdQueryVariables = Exact<{
  id: CatalogWhereInput
}>

export type FindCatalogByIdQuery = {
  __typename?: "Query"
  findCatalogById?: Maybe<{
    __typename?: "Catalog"
    coverTitle: string
    coverImage: string
    footerTitle: string
    footerImage: string
    lists: Array<{
      __typename?: "MarketplaceList"
      id: string
      title: string
      coverImage: string
      icon?: Maybe<string>
      sublists: Array<{
        __typename?: "MarketplaceList"
        id: string
        title: string
        coverImage: string
        icon?: Maybe<string>
      }>
    }>
  }>
}

export type FindManyCatalogQueryVariables = Exact<{
  where: CatalogWhereInput
}>

export type FindManyCatalogQuery = {
  __typename?: "Query"
  findFirstCatalog?: Maybe<{
    __typename?: "Catalog"
    coverTitle: string
    coverImage: string
    footerTitle: string
    footerImage: string
    lists: Array<{
      __typename?: "MarketplaceList"
      id: string
      title: string
      coverImage: string
      icon?: Maybe<string>
      sublists: Array<{
        __typename?: "MarketplaceList"
        id: string
        title: string
        coverImage: string
        icon?: Maybe<string>
      }>
    }>
  }>
}

export type FindFirstCatalogQueryVariables = Exact<{
  where: CatalogWhereInput
}>

export type FindFirstCatalogQuery = {
  __typename?: "Query"
  findFirstCatalog?: Maybe<{
    __typename?: "Catalog"
    coverTitle: string
    coverImage: string
    footerTitle: string
    footerImage: string
    lists: Array<{
      __typename?: "MarketplaceList"
      id: string
      title: string
      coverImage: string
      icon?: Maybe<string>
      sublists: Array<{
        __typename?: "MarketplaceList"
        id: string
        title: string
        coverImage: string
        icon?: Maybe<string>
      }>
    }>
  }>
}

export type MarketplaceListFragmentFragment = {
  __typename?: "MarketplaceList"
  id: string
  title: string
  coverImage: string
  icon?: Maybe<string>
  listings: Array<{
    __typename?: "Listing"
    id: string
    cost?: Maybe<any>
    title?: Maybe<string>
    imageUrl?: Maybe<string>
    business?: Maybe<{
      __typename?: "Business"
      handle: string
      name: string
      logoUrl?: Maybe<string>
    }>
  }>
}

export type FindMarketplaceListByIdQueryVariables = Exact<{
  id: Scalars["ID"]
}>

export type FindMarketplaceListByIdQuery = {
  __typename?: "Query"
  findMarketplaceListById?: Maybe<
    { __typename?: "MarketplaceList" } & MarketplaceListFragmentFragment
  >
}

export type FindAllCategoriesQueryVariables = Exact<{
  where?: Maybe<CategoryWhereInput>
}>

export type FindAllCategoriesQuery = {
  __typename?: "Query"
  findManyCategory?: Maybe<{
    __typename?: "ManyListingCategory"
    total?: Maybe<number>
    categories?: Maybe<
      Array<
        Maybe<{ __typename?: "ListingCategory"; id: string; name: string; icon?: Maybe<string> }>
      >
    >
  }>
}

export type ListingFieldsFragment = {
  __typename?: "Listing"
  id: string
  createdAt: any
  updatedAt: any
  cost?: Maybe<any>
  address?: Maybe<string>
  imageUrl?: Maybe<string>
  videoUrl?: Maybe<string>
  description?: Maybe<string>
  title?: Maybe<string>
  businessId: string
  isLocal?: Maybe<boolean>
  isVirtual?: Maybe<boolean>
  isDeliverable?: Maybe<boolean>
  deliveryNotes?: Maybe<string>
  availability?: Maybe<string>
  minPrice?: Maybe<any>
  maxPrice?: Maybe<any>
  calendarLink?: Maybe<string>
  type?: Maybe<ListingType>
  categoryId?: Maybe<string>
  tags?: Maybe<Array<Maybe<string>>>
  isDisabled?: Maybe<boolean>
  quantity?: Maybe<number>
  category?: Maybe<{
    __typename?: "ListingCategory"
    id: string
    name: string
    icon?: Maybe<string>
  }>
}

export type CreateListingMutationVariables = Exact<{
  data?: Maybe<CreateListingInput>
}>

export type CreateListingMutation = {
  __typename?: "Mutation"
  createListing: { __typename?: "Listing"; id: string }
}

export type UpdateListingMutationVariables = Exact<{
  data?: Maybe<UpdateListingInput>
  id: Scalars["ID"]
}>

export type UpdateListingMutation = {
  __typename?: "Mutation"
  updateListing?: Maybe<{ __typename?: "Listing"; id: string }>
}

export type ToggleListingMutationVariables = Exact<{
  id: Scalars["ID"]
}>

export type ToggleListingMutation = {
  __typename?: "Mutation"
  toggleListing?: Maybe<{ __typename?: "Listing"; id: string }>
}

export type FindOneListingQueryVariables = Exact<{
  id: Scalars["ID"]
}>

export type FindOneListingQuery = {
  __typename?: "Query"
  findOneListing?: Maybe<{
    __typename?: "Listing"
    address?: Maybe<string>
    businessId: string
    isLocal?: Maybe<boolean>
    isVirtual?: Maybe<boolean>
    isDeliverable?: Maybe<boolean>
    isDisabled?: Maybe<boolean>
    deliveryNotes?: Maybe<string>
    availability?: Maybe<string>
    minPrice?: Maybe<any>
    maxPrice?: Maybe<any>
    calendarLink?: Maybe<string>
    type?: Maybe<ListingType>
    tags?: Maybe<Array<Maybe<string>>>
    categoryId?: Maybe<string>
    cost?: Maybe<any>
    createdAt: any
    description?: Maybe<string>
    id: string
    imageUrl?: Maybe<string>
    title?: Maybe<string>
    updatedAt: any
    videoUrl?: Maybe<string>
    quantity?: Maybe<number>
    business?: Maybe<{
      __typename?: "Business"
      id: string
      handle: string
      name: string
      logoUrl?: Maybe<string>
      tagline?: Maybe<string>
      address?: Maybe<string>
      coverUrl?: Maybe<string>
      externalLinks?: Maybe<Array<Maybe<string>>>
      description?: Maybe<string>
      wallet?: Maybe<{ __typename?: "Wallet"; multiSigAddress?: Maybe<string> }>
    }>
    category?: Maybe<{
      __typename?: "ListingCategory"
      name: string
      id: string
      icon?: Maybe<string>
    }>
  }>
}
export const FindMarkets = gql`
  query findMarkets {
    findMarkets {
      id
      name
    }
  }
`
export type SearchListingsQueryVariables = Exact<{
  query: Scalars["String"]
  filters?: Maybe<Array<Scalars["String"]> | Scalars["String"]>
  page?: Maybe<Scalars["Int"]>
  limit?: Maybe<Scalars["Int"]>
}>

export type SearchListingsQuery = {
  __typename?: "Query"
  searchListings: {
    __typename?: "ManyListing"
    total?: Maybe<number>
    listings?: Maybe<
      Array<
        Maybe<
          {
            __typename?: "Listing"
            business?: Maybe<{
              __typename?: "Business"
              handle: string
              name: string
              logoUrl?: Maybe<string>
              tagline?: Maybe<string>
            }>
          } & ListingFieldsFragment
        >
      >
    >
  }
}

export type FindMarketsQueryVariables = Exact<{
  where?: Maybe<MarketWhereInput>
}>

export type FindMarketsQuery = {
  __typename?: "Query"
  findMarkets: Array<{ __typename?: "Market"; id: string; name: string }>
}

export type CreateOrderMutationVariables = Exact<{
  data?: Maybe<CreateOrderInput>
}>

export type CreateOrderMutation = {
  __typename?: "Mutation"
  createOrder: { __typename?: "Order"; id: string }
}

export type FindOneOrderQueryVariables = Exact<{
  id: Scalars["ID"]
}>

export type FindOneOrderQuery = {
  __typename?: "Query"
  findOneOrder?: Maybe<{ __typename?: "Order"; id: string }>
}

export type CountAllOrdersQueryVariables = Exact<{
  where: OrderWhereInput
}>

export type CountAllOrdersQuery = { __typename?: "Query"; countAllOrders: number }

export type GetOrderTotalQueryVariables = Exact<{
  listingId: Scalars["ID"]
  quantity: Scalars["Int"]
}>

export type GetOrderTotalQuery = { __typename?: "Query"; getOrderTotal: any }

export type UpdateOrderStatusMutationVariables = Exact<{
  data: UpdateOrderStatusInput
  id: Scalars["ID"]
}>

export type UpdateOrderStatusMutation = {
  __typename?: "Mutation"
  updateOrderStatus: { __typename?: "Order"; id: string; status: OrderStatus }
}

export type SendRequestFormToSlackMutationVariables = Exact<{
  data: CreateRequestFormToSlackInput
}>

export type SendRequestFormToSlackMutation = {
  __typename?: "Mutation"
  sendRequestFormToSlack?: Maybe<boolean>
}

export type SendFeedbackFormToSlackMutationVariables = Exact<{
  data: FeedbackFormToSlackInput
}>

export type SendFeedbackFormToSlackMutation = {
  __typename?: "Mutation"
  sendFeedbackFormToSlack?: Maybe<boolean>
}

export type SendBugFormToSlackMutationVariables = Exact<{
  data: BugFormToSlackInput
}>

export type SendBugFormToSlackMutation = {
  __typename?: "Mutation"
  sendBugFormToSlack?: Maybe<boolean>
}

export type GetBusinessStreamTokenQueryVariables = Exact<{
  businessId: Scalars["ID"]
}>

export type GetBusinessStreamTokenQuery = {
  __typename?: "Query"
  getBusinessStreamToken?: Maybe<string>
}

export type FindOrCreateChannelMutationVariables = Exact<{
  targetBusinessId: Scalars["ID"]
}>

export type FindOrCreateChannelMutation = {
  __typename?: "Mutation"
  findOrCreateChannel: { __typename?: "Channel"; id?: Maybe<string> }
}

export type TransactionFieldsFragment = {
  __typename?: "Transaction"
  id: string
  createdAt?: Maybe<any>
  amount: any
  note?: Maybe<string>
  txHash?: Maybe<string>
  status?: Maybe<TransactionStatus>
}

export type SendRusdMutationVariables = Exact<{
  data?: Maybe<SendRusdInput>
}>

export type SendRusdMutation = {
  __typename?: "Mutation"
  sendRusd: { __typename?: "Transaction"; id: string }
}

export type RetryTransactionMutationVariables = Exact<{
  transactionId: Scalars["ID"]
  txData: Scalars["String"]
  txSignature: Scalars["String"]
}>

export type RetryTransactionMutation = {
  __typename?: "Mutation"
  retryTransaction: { __typename?: "Transaction"; id: string }
}

export type FindTransactionByTxIdQueryVariables = Exact<{
  txId: Scalars["String"]
}>

export type FindTransactionByTxIdQuery = {
  __typename?: "Query"
  findTransactionByTxId?: Maybe<{
    __typename?: "Transaction"
    id: string
    txId?: Maybe<string>
    status?: Maybe<TransactionStatus>
  }>
}

export type FindTransactionByIdQueryVariables = Exact<{
  id: Scalars["ID"]
}>

export type FindTransactionByIdQuery = {
  __typename?: "Query"
  findTransactionById?: Maybe<
    {
      __typename?: "Transaction"
      sender?: Maybe<{
        __typename?: "Wallet"
        id: string
        multiSigAddress?: Maybe<string>
        business?: Maybe<{
          __typename?: "Business"
          id: string
          name: string
          handle: string
          logoUrl?: Maybe<string>
        }>
      }>
      recipient?: Maybe<{
        __typename?: "Wallet"
        id: string
        multiSigAddress?: Maybe<string>
        business?: Maybe<{
          __typename?: "Business"
          id: string
          name: string
          handle: string
          logoUrl?: Maybe<string>
        }>
      }>
      order?: Maybe<{
        __typename?: "Order"
        id: string
        quantity: number
        status: OrderStatus
        note: string
        deliveryAddress?: Maybe<string>
        fulfillmentMethod?: Maybe<FulfillmentMethod>
        listing?: Maybe<{ __typename?: "Listing"; title?: Maybe<string>; cost?: Maybe<any> }>
      }>
    } & TransactionFieldsFragment
  >
}

export type FindManyTransactionsQueryVariables = Exact<{
  where?: Maybe<TransactionWhereInput>
  orderBy?: Maybe<TransactionOrderByInput>
  limit?: Maybe<Scalars["Int"]>
  page?: Maybe<Scalars["Int"]>
}>

export type FindManyTransactionsQuery = {
  __typename?: "Query"
  findManyTransactions?: Maybe<{
    __typename?: "ManyTransaction"
    total: number
    transactions: Array<
      Maybe<
        {
          __typename?: "Transaction"
          sender?: Maybe<{
            __typename?: "Wallet"
            id: string
            business?: Maybe<{
              __typename?: "Business"
              id: string
              name: string
              handle: string
              logoUrl?: Maybe<string>
            }>
          }>
          recipient?: Maybe<{
            __typename?: "Wallet"
            id: string
            business?: Maybe<{
              __typename?: "Business"
              id: string
              name: string
              handle: string
              logoUrl?: Maybe<string>
            }>
          }>
          order?: Maybe<{
            __typename?: "Order"
            id: string
            quantity: number
            status: OrderStatus
            listing?: Maybe<{ __typename?: "Listing"; title?: Maybe<string> }>
          }>
        } & TransactionFieldsFragment
      >
    >
  }>
}

export type UserFieldsFragment = {
  __typename?: "User"
  id: string
  role: Role
  firstName?: Maybe<string>
  lastName?: Maybe<string>
  email: string
  isEmailValidated: boolean
  type?: Maybe<UserType>
  business?: Maybe<
    {
      __typename?: "Business"
      listings?: Maybe<Array<Maybe<{ __typename?: "Listing" } & ListingFieldsFragment>>>
    } & BusinessFieldsFragment
  >
  ambassador?: Maybe<{ __typename?: "Ambassador" } & AmbassadorFieldsFragment>
}

export type MeFieldsFragment = {
  __typename?: "User"
  business?: Maybe<
    {
      __typename?: "Business"
      wallet?: Maybe<{ __typename?: "Wallet" } & WalletFieldsFragment>
      settings?: Maybe<{ __typename?: "BusinessSettings" } & BusinessSettingsFieldsFragment>
      listings?: Maybe<Array<Maybe<{ __typename?: "Listing" } & ListingFieldsFragment>>>
    } & BusinessFieldsFragment
  >
} & UserFieldsFragment

export type MeQueryVariables = Exact<{ [key: string]: never }>

export type MeQuery = {
  __typename?: "Query"
  me?: Maybe<{ __typename?: "User" } & MeFieldsFragment>
}

export type DisableUserMutationVariables = Exact<{
  id: Scalars["ID"]
}>

export type DisableUserMutation = {
  __typename?: "Mutation"
  disableUser?: Maybe<{ __typename?: "User"; id: string }>
}

export type FindAllUsersQueryVariables = Exact<{
  where?: Maybe<UserWhereInput>
  skip?: Maybe<Scalars["Int"]>
  take?: Maybe<Scalars["Int"]>
}>

export type FindAllUsersQuery = {
  __typename?: "Query"
  findAllUsers: Array<{ __typename?: "AdminUser"; id: string }>
}

export type FindUserByIdQueryVariables = Exact<{
  id: Scalars["ID"]
}>

export type FindUserByIdQuery = {
  __typename?: "Query"
  findUserById?: Maybe<{ __typename?: "User"; id: string }>
}

export type FindUserByEmailQueryVariables = Exact<{
  email: Scalars["String"]
}>

export type FindUserByEmailQuery = {
  __typename?: "Query"
  findUserByEmail?: Maybe<{ __typename?: "User"; id: string }>
}

export type CountAllUsersQueryVariables = Exact<{
  where?: Maybe<UserWhereInput>
}>

export type CountAllUsersQuery = { __typename?: "Query"; countAllUsers?: Maybe<number> }

export type WalletFieldsFragment = {
  __typename?: "Wallet"
  id: string
  isActive: boolean
  multiSigAddress?: Maybe<string>
  creditLimit?: Maybe<any>
}

export type DoubleEncryptHashMutationVariables = Exact<{
  encryptedHash: Scalars["String"]
}>

export type DoubleEncryptHashMutation = {
  __typename?: "Mutation"
  doubleEncryptHash: { __typename?: "CipherPayload"; data?: Maybe<string> }
}

export type WalletBalanceQueryVariables = Exact<{
  id: Scalars["ID"]
}>

export type WalletBalanceQuery = {
  __typename?: "Query"
  walletBalance: {
    __typename?: "WalletBalance"
    availableCredit?: Maybe<any>
    balance?: Maybe<any>
    creditLimit?: Maybe<any>
  }
}

export type ClaimWalletMutationVariables = Exact<{
  clientAddress: Scalars["String"]
  guardianAddress: Scalars["String"]
  id: Scalars["ID"]
}>

export type ClaimWalletMutation = {
  __typename?: "Mutation"
  claimWallet: {
    __typename?: "Wallet"
    id: string
    isActive: boolean
    multiSigAddress?: Maybe<string>
  }
}

export const MarketplaceListFragmentFragmentDoc = gql`
  fragment MarketplaceListFragment on MarketplaceList {
    id
    title
    coverImage
    icon
    listings {
      id
      cost
      title
      imageUrl
      business {
        handle
        name
        logoUrl
      }
    }
  }
`
export const TransactionFieldsFragmentDoc = gql`
  fragment TransactionFields on Transaction {
    id
    createdAt
    amount
    note
    txHash
    status
  }
`
export const BusinessSettingsFieldsFragmentDoc = gql`
  fragment BusinessSettingsFields on BusinessSettings {
    id
    businessId
    emailChat
    emailMarketing
    emailNotifications
    emailProductUpdates
    isEmailValidated
    isSmsValidated
    languages
    notificationEmail
    notificationPhone
    smsChat
    smsNotifications
    smsTransaction
    timezone
    showSmsOptIn
  }
`
export const WalletFieldsFragmentDoc = gql`
  fragment WalletFields on Wallet {
    id
    isActive
    multiSigAddress
    creditLimit
  }
`
export const BusinessFieldsFragmentDoc = gql`
  fragment BusinessFields on Business {
    id
    name
    tagline
    description
    address
    logoUrl
    coverUrl
    externalLinks
    isDisabled
    phoneNumber
    approved
    handle
    createdAt
    email
    ownerId
    settings {
      ...BusinessSettingsFields
    }
    wallet {
      ...WalletFields
    }
    markets {
      name
      id
    }
  }
  ${BusinessSettingsFieldsFragmentDoc}
  ${WalletFieldsFragmentDoc}
`
export const ListingFieldsFragmentDoc = gql`
  fragment ListingFields on Listing {
    id
    createdAt
    updatedAt
    cost
    address
    imageUrl
    videoUrl
    description
    title
    businessId
    isLocal
    isVirtual
    isDeliverable
    deliveryNotes
    availability
    minPrice
    maxPrice
    address
    calendarLink
    type
    categoryId
    tags
    isDisabled
    category {
      id
      name
      icon
    }
    quantity
  }
`
export const AmbassadorFieldsFragmentDoc = gql`
  fragment AmbassadorFields on Ambassador {
    id
    handle
    creditAllowance
    ownerId
    refCode
  }
`
export const UserFieldsFragmentDoc = gql`
  fragment UserFields on User {
    id
    role
    firstName
    lastName
    email
    isEmailValidated
    type
    business {
      ...BusinessFields
      listings {
        ...ListingFields
      }
    }
    ambassador {
      ...AmbassadorFields
    }
  }
  ${BusinessFieldsFragmentDoc}
  ${ListingFieldsFragmentDoc}
  ${AmbassadorFieldsFragmentDoc}
`
export const MeFieldsFragmentDoc = gql`
  fragment MeFields on User {
    ...UserFields
    business {
      wallet {
        ...WalletFields
      }
      ...BusinessFields
      settings {
        ...BusinessSettingsFields
      }
      listings {
        ...ListingFields
      }
    }
  }
  ${UserFieldsFragmentDoc}
  ${WalletFieldsFragmentDoc}
  ${BusinessFieldsFragmentDoc}
  ${BusinessSettingsFieldsFragmentDoc}
  ${ListingFieldsFragmentDoc}
`
export const FindOneAmbassadorByHandleDocument = gql`
  query findOneAmbassadorByHandle($handle: String!) {
    findOneAmbassadorByHandle(handle: $handle) {
      ...AmbassadorFields
      businesses {
        id
        name
        handle
        logoUrl
        createdAt
        owner {
          firstName
          lastName
        }
        transactions {
          id
          createdAt
          amount
        }
        listings {
          id
        }
      }
    }
  }
  ${AmbassadorFieldsFragmentDoc}
`

/**
 * __useFindOneAmbassadorByHandleQuery__
 *
 * To run a query within a React component, call `useFindOneAmbassadorByHandleQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOneAmbassadorByHandleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOneAmbassadorByHandleQuery({
 *   variables: {
 *      handle: // value for 'handle'
 *   },
 * });
 */
export function useFindOneAmbassadorByHandleQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindOneAmbassadorByHandleQuery,
    FindOneAmbassadorByHandleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FindOneAmbassadorByHandleQuery, FindOneAmbassadorByHandleQueryVariables>(
    FindOneAmbassadorByHandleDocument,
    options,
  )
}
export function useFindOneAmbassadorByHandleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindOneAmbassadorByHandleQuery,
    FindOneAmbassadorByHandleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindOneAmbassadorByHandleQuery,
    FindOneAmbassadorByHandleQueryVariables
  >(FindOneAmbassadorByHandleDocument, options)
}
export type FindOneAmbassadorByHandleQueryHookResult = ReturnType<
  typeof useFindOneAmbassadorByHandleQuery
>
export type FindOneAmbassadorByHandleLazyQueryHookResult = ReturnType<
  typeof useFindOneAmbassadorByHandleLazyQuery
>
export type FindOneAmbassadorByHandleQueryResult = Apollo.QueryResult<
  FindOneAmbassadorByHandleQuery,
  FindOneAmbassadorByHandleQueryVariables
>
export const RegisterUserDocument = gql`
  mutation registerUser($data: UserCreateInput!) {
    registerUser(data: $data) {
      authToken
      user {
        id
        role
        firstName
        lastName
        email
      }
    }
  }
`
export type RegisterUserMutationFn = Apollo.MutationFunction<
  RegisterUserMutation,
  RegisterUserMutationVariables
>

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegisterUserMutation(
  baseOptions?: Apollo.MutationHookOptions<RegisterUserMutation, RegisterUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RegisterUserMutation, RegisterUserMutationVariables>(
    RegisterUserDocument,
    options,
  )
}
export type RegisterUserMutationHookResult = ReturnType<typeof useRegisterUserMutation>
export type RegisterUserMutationResult = Apollo.MutationResult<RegisterUserMutation>
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<
  RegisterUserMutation,
  RegisterUserMutationVariables
>
export const LoginUserDocument = gql`
  mutation loginUser($email: String!, $password: String!) {
    loginUser(email: $email, password: $password) {
      authToken
      user {
        isAdmin
        ...MeFields
      }
    }
  }
  ${MeFieldsFragmentDoc}
`
export type LoginUserMutationFn = Apollo.MutationFunction<
  LoginUserMutation,
  LoginUserMutationVariables
>

/**
 * __useLoginUserMutation__
 *
 * To run a mutation, you first call `useLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutation, { data, loading, error }] = useLoginUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginUserMutation(
  baseOptions?: Apollo.MutationHookOptions<LoginUserMutation, LoginUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<LoginUserMutation, LoginUserMutationVariables>(
    LoginUserDocument,
    options,
  )
}
export type LoginUserMutationHookResult = ReturnType<typeof useLoginUserMutation>
export type LoginUserMutationResult = Apollo.MutationResult<LoginUserMutation>
export type LoginUserMutationOptions = Apollo.BaseMutationOptions<
  LoginUserMutation,
  LoginUserMutationVariables
>
export const RequestResetDocument = gql`
  mutation requestReset($email: String!) {
    requestReset(email: $email)
  }
`
export type RequestResetMutationFn = Apollo.MutationFunction<
  RequestResetMutation,
  RequestResetMutationVariables
>

/**
 * __useRequestResetMutation__
 *
 * To run a mutation, you first call `useRequestResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestResetMutation, { data, loading, error }] = useRequestResetMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestResetMutation(
  baseOptions?: Apollo.MutationHookOptions<RequestResetMutation, RequestResetMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RequestResetMutation, RequestResetMutationVariables>(
    RequestResetDocument,
    options,
  )
}
export type RequestResetMutationHookResult = ReturnType<typeof useRequestResetMutation>
export type RequestResetMutationResult = Apollo.MutationResult<RequestResetMutation>
export type RequestResetMutationOptions = Apollo.BaseMutationOptions<
  RequestResetMutation,
  RequestResetMutationVariables
>
export const SendWelcomeEmailDocument = gql`
  mutation sendWelcomeEmail($email: String!) {
    sendWelcomeEmail(email: $email)
  }
`
export type SendWelcomeEmailMutationFn = Apollo.MutationFunction<
  SendWelcomeEmailMutation,
  SendWelcomeEmailMutationVariables
>

/**
 * __useSendWelcomeEmailMutation__
 *
 * To run a mutation, you first call `useSendWelcomeEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendWelcomeEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendWelcomeEmailMutation, { data, loading, error }] = useSendWelcomeEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendWelcomeEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendWelcomeEmailMutation,
    SendWelcomeEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SendWelcomeEmailMutation, SendWelcomeEmailMutationVariables>(
    SendWelcomeEmailDocument,
    options,
  )
}
export type SendWelcomeEmailMutationHookResult = ReturnType<typeof useSendWelcomeEmailMutation>
export type SendWelcomeEmailMutationResult = Apollo.MutationResult<SendWelcomeEmailMutation>
export type SendWelcomeEmailMutationOptions = Apollo.BaseMutationOptions<
  SendWelcomeEmailMutation,
  SendWelcomeEmailMutationVariables
>
export const AcceptInviteDocument = gql`
  mutation acceptInvite($data: AcceptInviteInput) {
    acceptInvite(data: $data) {
      authToken
      user {
        id
      }
    }
  }
`
export type AcceptInviteMutationFn = Apollo.MutationFunction<
  AcceptInviteMutation,
  AcceptInviteMutationVariables
>

/**
 * __useAcceptInviteMutation__
 *
 * To run a mutation, you first call `useAcceptInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInviteMutation, { data, loading, error }] = useAcceptInviteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAcceptInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<AcceptInviteMutation, AcceptInviteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AcceptInviteMutation, AcceptInviteMutationVariables>(
    AcceptInviteDocument,
    options,
  )
}
export type AcceptInviteMutationHookResult = ReturnType<typeof useAcceptInviteMutation>
export type AcceptInviteMutationResult = Apollo.MutationResult<AcceptInviteMutation>
export type AcceptInviteMutationOptions = Apollo.BaseMutationOptions<
  AcceptInviteMutation,
  AcceptInviteMutationVariables
>
export const ResetPasswordDocument = gql`
  mutation resetPassword(
    $email: String!
    $password: String!
    $confirmPassword: String!
    $validateEmailToken: String!
  ) {
    resetPassword(
      email: $email
      password: $password
      confirmPassword: $confirmPassword
      validateEmailToken: $validateEmailToken
    ) {
      authToken
      user {
        id
      }
    }
  }
`
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      confirmPassword: // value for 'confirmPassword'
 *      validateEmailToken: // value for 'validateEmailToken'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(
    ResetPasswordDocument,
    options,
  )
}

export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>
export const ResetPasswordWithGuardianDocument = gql`
  mutation resetPasswordWithGuardian(
    $email: String!
    $newClientAddress: String!
    $password: String!
    $transactionId: String!
  ) {
    resetPasswordWithGuardian(
      email: $email
      newClientAddress: $newClientAddress
      password: $password
      transactionId: $transactionId
    ) {
      authToken
      user {
        id
        business {
          wallet {
            id
          }
        }
      }
    }
  }
`
export type ResetPasswordWithGuardianMutationFn = Apollo.MutationFunction<
  ResetPasswordWithGuardianMutation,
  ResetPasswordWithGuardianMutationVariables
>

/**
 * __useResetPasswordWithGuardianMutation__
 *
 * To run a mutation, you first call `useResetPasswordWithGuardianMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordWithGuardianMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordWithGuardianMutation, { data, loading, error }] = useResetPasswordWithGuardianMutation({
 *   variables: {
 *      email: // value for 'email'
 *      newClientAddress: // value for 'newClientAddress'
 *      password: // value for 'password'
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useResetPasswordWithGuardianMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordWithGuardianMutation,
    ResetPasswordWithGuardianMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ResetPasswordWithGuardianMutation,
    ResetPasswordWithGuardianMutationVariables
  >(ResetPasswordWithGuardianDocument, options)
}
export type ResetPasswordWithGuardianMutationHookResult = ReturnType<
  typeof useResetPasswordWithGuardianMutation
>
export type ResetPasswordWithGuardianMutationResult =
  Apollo.MutationResult<ResetPasswordWithGuardianMutation>
export type ResetPasswordWithGuardianMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordWithGuardianMutation,
  ResetPasswordWithGuardianMutationVariables
>
export const VerifyOtpDocument = gql`
  mutation verifyOTP($email: String!, $otp: String!) {
    verifyOTP(email: $email, otp: $otp)
  }
`
export type VerifyOtpMutationFn = Apollo.MutationFunction<
  VerifyOtpMutation,
  VerifyOtpMutationVariables
>

/**
 * __useVerifyOtpMutation__
 *
 * To run a mutation, you first call `useVerifyOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyOtpMutation, { data, loading, error }] = useVerifyOtpMutation({
 *   variables: {
 *      email: // value for 'email'
 *      otp: // value for 'otp'
 *   },
 * });
 */
export function useVerifyOtpMutation(
  baseOptions?: Apollo.MutationHookOptions<VerifyOtpMutation, VerifyOtpMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<VerifyOtpMutation, VerifyOtpMutationVariables>(
    VerifyOtpDocument,
    options,
  )
}
export type VerifyOtpMutationHookResult = ReturnType<typeof useVerifyOtpMutation>
export type VerifyOtpMutationResult = Apollo.MutationResult<VerifyOtpMutation>
export type VerifyOtpMutationOptions = Apollo.BaseMutationOptions<
  VerifyOtpMutation,
  VerifyOtpMutationVariables
>
export const ResetOtpDocument = gql`
  mutation resetOTP($email: String!) {
    resetOTP(email: $email)
  }
`
export type ResetOtpMutationFn = Apollo.MutationFunction<
  ResetOtpMutation,
  ResetOtpMutationVariables
>

/**
 * __useResetOtpMutation__
 *
 * To run a mutation, you first call `useResetOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetOtpMutation, { data, loading, error }] = useResetOtpMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResetOtpMutation(
  baseOptions?: Apollo.MutationHookOptions<ResetOtpMutation, ResetOtpMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ResetOtpMutation, ResetOtpMutationVariables>(ResetOtpDocument, options)
}
export type ResetOtpMutationHookResult = ReturnType<typeof useResetOtpMutation>
export type ResetOtpMutationResult = Apollo.MutationResult<ResetOtpMutation>
export type ResetOtpMutationOptions = Apollo.BaseMutationOptions<
  ResetOtpMutation,
  ResetOtpMutationVariables
>
export const IsEmailUniqueDocument = gql`
  query isEmailUnique($email: String!) {
    isEmailUnique(email: $email)
  }
`

/**
 * __useIsEmailUniqueQuery__
 *
 * To run a query within a React component, call `useIsEmailUniqueQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsEmailUniqueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsEmailUniqueQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useIsEmailUniqueQuery(
  baseOptions: Apollo.QueryHookOptions<IsEmailUniqueQuery, IsEmailUniqueQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IsEmailUniqueQuery, IsEmailUniqueQueryVariables>(
    IsEmailUniqueDocument,
    options,
  )
}
export function useIsEmailUniqueLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IsEmailUniqueQuery, IsEmailUniqueQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IsEmailUniqueQuery, IsEmailUniqueQueryVariables>(
    IsEmailUniqueDocument,
    options,
  )
}
export type IsEmailUniqueQueryHookResult = ReturnType<typeof useIsEmailUniqueQuery>
export type IsEmailUniqueLazyQueryHookResult = ReturnType<typeof useIsEmailUniqueLazyQuery>
export type IsEmailUniqueQueryResult = Apollo.QueryResult<
  IsEmailUniqueQuery,
  IsEmailUniqueQueryVariables
>
export const CreateBusinessDocument = gql`
  mutation createBusiness($data: CreateBusinessInput) {
    createBusiness(data: $data) {
      id
      handle
      logoUrl
      wallet {
        id
        multiSigAddress
      }
    }
  }
`
export type CreateBusinessMutationFn = Apollo.MutationFunction<
  CreateBusinessMutation,
  CreateBusinessMutationVariables
>

/**
 * __useCreateBusinessMutation__
 *
 * To run a mutation, you first call `useCreateBusinessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBusinessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBusinessMutation, { data, loading, error }] = useCreateBusinessMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateBusinessMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateBusinessMutation, CreateBusinessMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateBusinessMutation, CreateBusinessMutationVariables>(
    CreateBusinessDocument,
    options,
  )
}
export type CreateBusinessMutationHookResult = ReturnType<typeof useCreateBusinessMutation>
export type CreateBusinessMutationResult = Apollo.MutationResult<CreateBusinessMutation>
export type CreateBusinessMutationOptions = Apollo.BaseMutationOptions<
  CreateBusinessMutation,
  CreateBusinessMutationVariables
>
export const UpdateBusinessDocument = gql`
  mutation updateBusiness($data: BusinessUpdateInput!, $id: ID!) {
    updateBusiness(data: $data, id: $id) {
      ...BusinessFields
    }
  }
  ${BusinessFieldsFragmentDoc}
`
export type UpdateBusinessMutationFn = Apollo.MutationFunction<
  UpdateBusinessMutation,
  UpdateBusinessMutationVariables
>

/**
 * __useUpdateBusinessMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessMutation, { data, loading, error }] = useUpdateBusinessMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateBusinessMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateBusinessMutation, UpdateBusinessMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateBusinessMutation, UpdateBusinessMutationVariables>(
    UpdateBusinessDocument,
    options,
  )
}
export type UpdateBusinessMutationHookResult = ReturnType<typeof useUpdateBusinessMutation>
export type UpdateBusinessMutationResult = Apollo.MutationResult<UpdateBusinessMutation>
export type UpdateBusinessMutationOptions = Apollo.BaseMutationOptions<
  UpdateBusinessMutation,
  UpdateBusinessMutationVariables
>
export const ToggleBusinessDocument = gql`
  mutation toggleBusiness($id: ID!) {
    toggleBusiness(id: $id) {
      id
    }
  }
`
export const useCreateMaraket = gql`
  mutation createMarket($data: CreateMarketInput!) {
    createMarket(data: $data) {
      name
    }
  }
`
export type ToggleBusinessMutationFn = Apollo.MutationFunction<
  ToggleBusinessMutation,
  ToggleBusinessMutationVariables
>

/**
 * __useToggleBusinessMutation__
 *
 * To run a mutation, you first call `useToggleBusinessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleBusinessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleBusinessMutation, { data, loading, error }] = useToggleBusinessMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useToggleBusinessMutation(
  baseOptions?: Apollo.MutationHookOptions<ToggleBusinessMutation, ToggleBusinessMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ToggleBusinessMutation, ToggleBusinessMutationVariables>(
    ToggleBusinessDocument,
    options,
  )
}
export type ToggleBusinessMutationHookResult = ReturnType<typeof useToggleBusinessMutation>
export type ToggleBusinessMutationResult = Apollo.MutationResult<ToggleBusinessMutation>
export type ToggleBusinessMutationOptions = Apollo.BaseMutationOptions<
  ToggleBusinessMutation,
  ToggleBusinessMutationVariables
>
export const RequestEmailVerificationDocument = gql`
  mutation requestEmailVerification($email: String!, $businessId: ID!) {
    requestEmailVerification(email: $email, businessId: $businessId) {
      error
      data
    }
  }
`
export type RequestEmailVerificationMutationFn = Apollo.MutationFunction<
  RequestEmailVerificationMutation,
  RequestEmailVerificationMutationVariables
>

/**
 * __useRequestEmailVerificationMutation__
 *
 * To run a mutation, you first call `useRequestEmailVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestEmailVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestEmailVerificationMutation, { data, loading, error }] = useRequestEmailVerificationMutation({
 *   variables: {
 *      email: // value for 'email'
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useRequestEmailVerificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestEmailVerificationMutation,
    RequestEmailVerificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RequestEmailVerificationMutation,
    RequestEmailVerificationMutationVariables
  >(RequestEmailVerificationDocument, options)
}
export type RequestEmailVerificationMutationHookResult = ReturnType<
  typeof useRequestEmailVerificationMutation
>
export type RequestEmailVerificationMutationResult =
  Apollo.MutationResult<RequestEmailVerificationMutation>
export type RequestEmailVerificationMutationOptions = Apollo.BaseMutationOptions<
  RequestEmailVerificationMutation,
  RequestEmailVerificationMutationVariables
>
export const CheckEmailVerificationDocument = gql`
  mutation checkEmailVerification($code: String!, $businessId: ID!) {
    checkEmailVerification(code: $code, businessId: $businessId) {
      error
      data
    }
  }
`
export type CheckEmailVerificationMutationFn = Apollo.MutationFunction<
  CheckEmailVerificationMutation,
  CheckEmailVerificationMutationVariables
>

/**
 * __useCheckEmailVerificationMutation__
 *
 * To run a mutation, you first call `useCheckEmailVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckEmailVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkEmailVerificationMutation, { data, loading, error }] = useCheckEmailVerificationMutation({
 *   variables: {
 *      code: // value for 'code'
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useCheckEmailVerificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CheckEmailVerificationMutation,
    CheckEmailVerificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CheckEmailVerificationMutation,
    CheckEmailVerificationMutationVariables
  >(CheckEmailVerificationDocument, options)
}
export type CheckEmailVerificationMutationHookResult = ReturnType<
  typeof useCheckEmailVerificationMutation
>
export type CheckEmailVerificationMutationResult =
  Apollo.MutationResult<CheckEmailVerificationMutation>
export type CheckEmailVerificationMutationOptions = Apollo.BaseMutationOptions<
  CheckEmailVerificationMutation,
  CheckEmailVerificationMutationVariables
>
export const RequestSmsVerificationDocument = gql`
  mutation requestSmsVerification($to: String!) {
    requestSmsVerification(to: $to) {
      error
      data
    }
  }
`
export type RequestSmsVerificationMutationFn = Apollo.MutationFunction<
  RequestSmsVerificationMutation,
  RequestSmsVerificationMutationVariables
>

/**
 * __useRequestSmsVerificationMutation__
 *
 * To run a mutation, you first call `useRequestSmsVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestSmsVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestSmsVerificationMutation, { data, loading, error }] = useRequestSmsVerificationMutation({
 *   variables: {
 *      to: // value for 'to'
 *   },
 * });
 */
export function useRequestSmsVerificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestSmsVerificationMutation,
    RequestSmsVerificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RequestSmsVerificationMutation,
    RequestSmsVerificationMutationVariables
  >(RequestSmsVerificationDocument, options)
}
export type RequestSmsVerificationMutationHookResult = ReturnType<
  typeof useRequestSmsVerificationMutation
>
export type RequestSmsVerificationMutationResult =
  Apollo.MutationResult<RequestSmsVerificationMutation>
export type RequestSmsVerificationMutationOptions = Apollo.BaseMutationOptions<
  RequestSmsVerificationMutation,
  RequestSmsVerificationMutationVariables
>
export const CheckSmsVerificationDocument = gql`
  mutation checkSmsVerification($to: String!, $code: String!, $businessId: ID!) {
    checkSmsVerification(to: $to, code: $code, businessId: $businessId) {
      error
      data
    }
  }
`
export type CheckSmsVerificationMutationFn = Apollo.MutationFunction<
  CheckSmsVerificationMutation,
  CheckSmsVerificationMutationVariables
>

/**
 * __useCheckSmsVerificationMutation__
 *
 * To run a mutation, you first call `useCheckSmsVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckSmsVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkSmsVerificationMutation, { data, loading, error }] = useCheckSmsVerificationMutation({
 *   variables: {
 *      to: // value for 'to'
 *      code: // value for 'code'
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useCheckSmsVerificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CheckSmsVerificationMutation,
    CheckSmsVerificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CheckSmsVerificationMutation, CheckSmsVerificationMutationVariables>(
    CheckSmsVerificationDocument,
    options,
  )
}
export type CheckSmsVerificationMutationHookResult = ReturnType<
  typeof useCheckSmsVerificationMutation
>
export type CheckSmsVerificationMutationResult = Apollo.MutationResult<CheckSmsVerificationMutation>
export type CheckSmsVerificationMutationOptions = Apollo.BaseMutationOptions<
  CheckSmsVerificationMutation,
  CheckSmsVerificationMutationVariables
>
export const FindBusinessByIdDocument = gql`
  query findBusinessById($id: ID!) {
    findOneBusinessById(id: $id) {
      ...BusinessFields
      listings {
        ...ListingFields
      }
      settings {
        ...BusinessSettingsFields
      }
    }
  }
  ${BusinessFieldsFragmentDoc}
  ${ListingFieldsFragmentDoc}
  ${BusinessSettingsFieldsFragmentDoc}
`

/**
 * __useFindBusinessByIdQuery__
 *
 * To run a query within a React component, call `useFindBusinessByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindBusinessByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindBusinessByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindBusinessByIdQuery(
  baseOptions: Apollo.QueryHookOptions<FindBusinessByIdQuery, FindBusinessByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FindBusinessByIdQuery, FindBusinessByIdQueryVariables>(
    FindBusinessByIdDocument,
    options,
  )
}
export function useFindBusinessByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindBusinessByIdQuery, FindBusinessByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FindBusinessByIdQuery, FindBusinessByIdQueryVariables>(
    FindBusinessByIdDocument,
    options,
  )
}
export type FindBusinessByIdQueryHookResult = ReturnType<typeof useFindBusinessByIdQuery>
export type FindBusinessByIdLazyQueryHookResult = ReturnType<typeof useFindBusinessByIdLazyQuery>
export type FindBusinessByIdQueryResult = Apollo.QueryResult<
  FindBusinessByIdQuery,
  FindBusinessByIdQueryVariables
>
export const FindBusinessByHandleDocument = gql`
  query findBusinessByHandle($handle: String!) {
    findOneBusinessByHandle(handle: $handle) {
      ...BusinessFields
      settings {
        ...BusinessSettingsFields
      }
      listings {
        ...ListingFields
        business {
          handle
          name
        }
      }
    }
  }
  ${BusinessFieldsFragmentDoc}
  ${BusinessSettingsFieldsFragmentDoc}
  ${ListingFieldsFragmentDoc}
`

/**
 * __useFindBusinessByHandleQuery__
 *
 * To run a query within a React component, call `useFindBusinessByHandleQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindBusinessByHandleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindBusinessByHandleQuery({
 *   variables: {
 *      handle: // value for 'handle'
 *   },
 * });
 */
export function useFindBusinessByHandleQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindBusinessByHandleQuery,
    FindBusinessByHandleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FindBusinessByHandleQuery, FindBusinessByHandleQueryVariables>(
    FindBusinessByHandleDocument,
    options,
  )
}
export function useFindBusinessByHandleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindBusinessByHandleQuery,
    FindBusinessByHandleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FindBusinessByHandleQuery, FindBusinessByHandleQueryVariables>(
    FindBusinessByHandleDocument,
    options,
  )
}
export type FindBusinessByHandleQueryHookResult = ReturnType<typeof useFindBusinessByHandleQuery>
export type FindBusinessByHandleLazyQueryHookResult = ReturnType<
  typeof useFindBusinessByHandleLazyQuery
>
export type FindBusinessByHandleQueryResult = Apollo.QueryResult<
  FindBusinessByHandleQuery,
  FindBusinessByHandleQueryVariables
>
export const IsBusinessHandleUniqueDocument = gql`
  query isBusinessHandleUnique($handle: String!) {
    isBusinessHandleUnique(handle: $handle) {
      handle
      isUnique
    }
  }
`

/**
 * __useIsBusinessHandleUniqueQuery__
 *
 * To run a query within a React component, call `useIsBusinessHandleUniqueQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsBusinessHandleUniqueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsBusinessHandleUniqueQuery({
 *   variables: {
 *      handle: // value for 'handle'
 *   },
 * });
 */
export function useIsBusinessHandleUniqueQuery(
  baseOptions: Apollo.QueryHookOptions<
    IsBusinessHandleUniqueQuery,
    IsBusinessHandleUniqueQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IsBusinessHandleUniqueQuery, IsBusinessHandleUniqueQueryVariables>(
    IsBusinessHandleUniqueDocument,
    options,
  )
}
export function useIsBusinessHandleUniqueLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IsBusinessHandleUniqueQuery,
    IsBusinessHandleUniqueQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IsBusinessHandleUniqueQuery, IsBusinessHandleUniqueQueryVariables>(
    IsBusinessHandleUniqueDocument,
    options,
  )
}
export type IsBusinessHandleUniqueQueryHookResult = ReturnType<
  typeof useIsBusinessHandleUniqueQuery
>
export type IsBusinessHandleUniqueLazyQueryHookResult = ReturnType<
  typeof useIsBusinessHandleUniqueLazyQuery
>
export type IsBusinessHandleUniqueQueryResult = Apollo.QueryResult<
  IsBusinessHandleUniqueQuery,
  IsBusinessHandleUniqueQueryVariables
>
export const SearchBusinessesDocument = gql`
  query searchBusinesses($query: String!, $filters: [String!], $page: Int, $limit: Int) {
    searchBusinesses(query: $query, filters: $filters, page: $page, limit: $limit) {
      total
      businesses {
        id
        address
        coverUrl
        description
        handle
        logoUrl
        name
        tagline
      }
    }
  }
`

/**
 * __useSearchBusinessesQuery__
 *
 * To run a query within a React component, call `useSearchBusinessesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchBusinessesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchBusinessesQuery({
 *   variables: {
 *      query: // value for 'query'
 *      filters: // value for 'filters'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchBusinessesQuery(
  baseOptions: Apollo.QueryHookOptions<SearchBusinessesQuery, SearchBusinessesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SearchBusinessesQuery, SearchBusinessesQueryVariables>(
    SearchBusinessesDocument,
    options,
  )
}
export function useSearchBusinessesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchBusinessesQuery, SearchBusinessesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SearchBusinessesQuery, SearchBusinessesQueryVariables>(
    SearchBusinessesDocument,
    options,
  )
}
export type SearchBusinessesQueryHookResult = ReturnType<typeof useSearchBusinessesQuery>
export type SearchBusinessesLazyQueryHookResult = ReturnType<typeof useSearchBusinessesLazyQuery>
export type SearchBusinessesQueryResult = Apollo.QueryResult<
  SearchBusinessesQuery,
  SearchBusinessesQueryVariables
>
export const UpdateSettingsByBusinessDocument = gql`
  mutation updateSettingsByBusiness($businessId: ID!, $data: UpdateBusinessSettingsInput!) {
    updateBusinessSettingsByBusiness(businessId: $businessId, data: $data) {
      id
      businessId
      emailChat
      emailMarketing
      emailNotifications
      emailProductUpdates
      isEmailValidated
      isSmsValidated
      languages
      notificationEmail
      notificationPhone
      smsChat
      smsNotifications
      smsTransaction
      timezone
      showSmsOptIn
    }
  }
`
export type UpdateSettingsByBusinessMutationFn = Apollo.MutationFunction<
  UpdateSettingsByBusinessMutation,
  UpdateSettingsByBusinessMutationVariables
>

/**
 * __useUpdateSettingsByBusinessMutation__
 *
 * To run a mutation, you first call `useUpdateSettingsByBusinessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSettingsByBusinessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSettingsByBusinessMutation, { data, loading, error }] = useUpdateSettingsByBusinessMutation({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSettingsByBusinessMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSettingsByBusinessMutation,
    UpdateSettingsByBusinessMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateSettingsByBusinessMutation,
    UpdateSettingsByBusinessMutationVariables
  >(UpdateSettingsByBusinessDocument, options)
}
export type UpdateSettingsByBusinessMutationHookResult = ReturnType<
  typeof useUpdateSettingsByBusinessMutation
>
export type UpdateSettingsByBusinessMutationResult =
  Apollo.MutationResult<UpdateSettingsByBusinessMutation>
export type UpdateSettingsByBusinessMutationOptions = Apollo.BaseMutationOptions<
  UpdateSettingsByBusinessMutation,
  UpdateSettingsByBusinessMutationVariables
>

export const FindCatalogById = gql`
  query findCatalogById($id: ID!) {
    findCatalogById(id: $id) {
      coverImage
      coverTitle
      footerImage
      footerTitle
      id
      isActive
      market {
        name
      }
      lists {
        id
        coverImage
        icon
        order
        listings {
          id
        }
        sublists {
          coverImage
          icon
          id
          order
          title
          sublists {
            id
          }
          listings {
            id
            business {
              handle
            }
          }
        }
        title
      }
    }
  }
`

/**
 * __useFindCatalogByIdQuery__
 *
 * To run a query within a React component, call `useFindCatalogByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCatalogByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCatalogByIdQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindCatalogByIdQuery(
  baseOptions: Apollo.QueryHookOptions<FindCatalogByIdQuery, FindCatalogByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FindCatalogByIdQuery, FindCatalogByIdQueryVariables>(
    FindCatalogById,
    options,
  )
}
export function useFindCatalogByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindCatalogByIdQuery, FindCatalogByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FindCatalogByIdQuery, FindCatalogByIdQueryVariables>(
    FindCatalogById,
    options,
  )
}
export type FindCatalogByIdQueryHookResult = ReturnType<typeof useFindCatalogByIdQuery>
export type FindCatalogByIdLazyQueryHookResult = ReturnType<typeof useFindCatalogByIdLazyQuery>
export type FindCatalogByIdQueryResult = Apollo.QueryResult<
  FindCatalogByIdQuery,
  FindCatalogByIdQueryVariables
>

export const FindManyCatalog = gql`
  query findManyCatalog {
    findManyCatalog {
      catalogs {
        coverImage
        coverTitle
        footerImage
        footerTitle
        id
        isActive
        market {
          name
        }
        lists {
          id
          coverImage
          icon
          order
          listings {
            id
          }
          sublists {
            coverImage
            icon
            id
            order
            title
            sublists {
              id
            }
            listings {
              id
              title
              imageUrl
              cost
              business {
                handle
                logoUrl
                name
              }
            }
          }
          title
        }
      }
      total
    }
  }
`

/**
 * __useFindManyCatalogQuery__
 *
 * To run a query within a React component, call `useFindManyCatalogQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManyCatalogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManyCatalogQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindManyCatalogQuery(
  baseOptions: Apollo.QueryHookOptions<FindManyCatalogQuery, FindManyCatalogQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FindManyCatalogQuery, FindManyCatalogQueryVariables>(
    FindManyCatalog,
    options,
  )
}
export function useFindManyCatalogLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindManyCatalogQuery, FindManyCatalogQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FindManyCatalogQuery, FindManyCatalogQueryVariables>(
    FindManyCatalog,
    options,
  )
}
export type FindManyCatalogQueryHookResult = ReturnType<typeof useFindManyCatalogQuery>
export type FindManyCatalogLazyQueryHookResult = ReturnType<typeof useFindManyCatalogLazyQuery>
export type FindManyCatalogQueryResult = Apollo.QueryResult<
  FindManyCatalogQuery,
  FindManyCatalogQueryVariables
>

export const FindFirstCatalogDocument = gql`
  query findManyCatalog {
    findManyCatalog {
      catalogs {
        coverImage
        coverTitle
        footerImage
        footerTitle
        isActive
        id
        market {
          name
        }
        lists {
          id
          coverImage
          icon
          order
          listings {
            id
          }
          sublists {
            coverImage
            icon
            id
            order
            title
            sublists {
              id
            }
            listings {
              id
              cost
              title
              imageUrl
              business {
                handle
                name
                logoUrl
              }
            }
          }
          title
        }
      }
      total
    }
  }
`

/**
 * __useFindFirstCatalogQuery__
 *
 * To run a query within a React component, call `useFindFirstCatalogQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindFirstCatalogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindFirstCatalogQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindFirstCatalogQuery(
  baseOptions: Apollo.QueryHookOptions<FindFirstCatalogQuery, FindFirstCatalogQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FindFirstCatalogQuery, FindFirstCatalogQueryVariables>(
    FindFirstCatalogDocument,
    options,
  )
}
export function useFindFirstCatalogLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindFirstCatalogQuery, FindFirstCatalogQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FindFirstCatalogQuery, FindFirstCatalogQueryVariables>(
    FindFirstCatalogDocument,
    options,
  )
}
export type FindFirstCatalogQueryHookResult = ReturnType<typeof useFindFirstCatalogQuery>
export type FindFirstCatalogLazyQueryHookResult = ReturnType<typeof useFindFirstCatalogLazyQuery>
export type FindFirstCatalogQueryResult = Apollo.QueryResult<
  FindFirstCatalogQuery,
  FindFirstCatalogQueryVariables
>

export const FindMarketplaceListByIdDocument = gql`
  query FindMarketplaceListById($id: ID!) {
    findMarketplaceListById(id: $id) {
      id
      icon
      title
      coverImage
      listings {
        id
        cost
        title
        imageUrl
        business {
          handle
          name
          logoUrl
        }
      }

      sublists {
        listings {
          business {
            handle
          }
        }
      }
      sublists {
        id
      }
    }
  }
`

/**
 * __useFindMarketplaceListByIdQuery__
 *
 * To run a query within a React component, call `useFindMarketplaceListByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindMarketplaceListByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindMarketplaceListByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindMarketplaceListByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindMarketplaceListByIdQuery,
    FindMarketplaceListByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FindMarketplaceListByIdQuery, FindMarketplaceListByIdQueryVariables>(
    FindMarketplaceListByIdDocument,
    options,
  )
}
export function useFindMarketplaceListByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindMarketplaceListByIdQuery,
    FindMarketplaceListByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FindMarketplaceListByIdQuery, FindMarketplaceListByIdQueryVariables>(
    FindMarketplaceListByIdDocument,
    options,
  )
}
export type FindMarketplaceListByIdQueryHookResult = ReturnType<
  typeof useFindMarketplaceListByIdQuery
>
export type FindMarketplaceListByIdLazyQueryHookResult = ReturnType<
  typeof useFindMarketplaceListByIdLazyQuery
>
export type FindMarketplaceListByIdQueryResult = Apollo.QueryResult<
  FindMarketplaceListByIdQuery,
  FindMarketplaceListByIdQueryVariables
>
export const FindAllCategoriesDocument = gql`
  query findAllCategories($where: CategoryWhereInput) {
    findManyCategory(where: $where) {
      total
      categories {
        id
        name
        icon
      }
    }
  }
`

/**
 * __useFindAllCategoriesQuery__
 *
 * To run a query within a React component, call `useFindAllCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllCategoriesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindAllCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<FindAllCategoriesQuery, FindAllCategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FindAllCategoriesQuery, FindAllCategoriesQueryVariables>(
    FindAllCategoriesDocument,
    options,
  )
}
export function useFindAllCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindAllCategoriesQuery,
    FindAllCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FindAllCategoriesQuery, FindAllCategoriesQueryVariables>(
    FindAllCategoriesDocument,
    options,
  )
}
export type FindAllCategoriesQueryHookResult = ReturnType<typeof useFindAllCategoriesQuery>
export type FindAllCategoriesLazyQueryHookResult = ReturnType<typeof useFindAllCategoriesLazyQuery>
export type FindAllCategoriesQueryResult = Apollo.QueryResult<
  FindAllCategoriesQuery,
  FindAllCategoriesQueryVariables
>
export const CreateListingDocument = gql`
  mutation createListing($data: CreateListingInput) {
    createListing(data: $data) {
      id
    }
  }
`
export type CreateListingMutationFn = Apollo.MutationFunction<
  CreateListingMutation,
  CreateListingMutationVariables
>

/**
 * __useCreateListingMutation__
 *
 * To run a mutation, you first call `useCreateListingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateListingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createListingMutation, { data, loading, error }] = useCreateListingMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateListingMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateListingMutation, CreateListingMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateListingMutation, CreateListingMutationVariables>(
    CreateListingDocument,
    options,
  )
}
export type CreateListingMutationHookResult = ReturnType<typeof useCreateListingMutation>
export type CreateListingMutationResult = Apollo.MutationResult<CreateListingMutation>
export type CreateListingMutationOptions = Apollo.BaseMutationOptions<
  CreateListingMutation,
  CreateListingMutationVariables
>
export const UpdateListingDocument = gql`
  mutation updateListing($data: UpdateListingInput, $id: ID!) {
    updateListing(data: $data, id: $id) {
      id
    }
  }
`
export type UpdateListingMutationFn = Apollo.MutationFunction<
  UpdateListingMutation,
  UpdateListingMutationVariables
>

/**
 * __useUpdateListingMutation__
 *
 * To run a mutation, you first call `useUpdateListingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateListingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateListingMutation, { data, loading, error }] = useUpdateListingMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateListingMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateListingMutation, UpdateListingMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateListingMutation, UpdateListingMutationVariables>(
    UpdateListingDocument,
    options,
  )
}
export type UpdateListingMutationHookResult = ReturnType<typeof useUpdateListingMutation>
export type UpdateListingMutationResult = Apollo.MutationResult<UpdateListingMutation>
export type UpdateListingMutationOptions = Apollo.BaseMutationOptions<
  UpdateListingMutation,
  UpdateListingMutationVariables
>
export const ToggleListingDocument = gql`
  mutation toggleListing($id: ID!) {
    toggleListing(id: $id) {
      id
    }
  }
`
export type ToggleListingMutationFn = Apollo.MutationFunction<
  ToggleListingMutation,
  ToggleListingMutationVariables
>

/**
 * __useToggleListingMutation__
 *
 * To run a mutation, you first call `useToggleListingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleListingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleListingMutation, { data, loading, error }] = useToggleListingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useToggleListingMutation(
  baseOptions?: Apollo.MutationHookOptions<ToggleListingMutation, ToggleListingMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ToggleListingMutation, ToggleListingMutationVariables>(
    ToggleListingDocument,
    options,
  )
}
export type ToggleListingMutationHookResult = ReturnType<typeof useToggleListingMutation>
export type ToggleListingMutationResult = Apollo.MutationResult<ToggleListingMutation>
export type ToggleListingMutationOptions = Apollo.BaseMutationOptions<
  ToggleListingMutation,
  ToggleListingMutationVariables
>
export const FindOneListingDocument = gql`
  query findOneListing($id: ID!) {
    findOneListing(id: $id) {
      address
      businessId
      isLocal
      isVirtual
      isDeliverable
      isDisabled
      deliveryNotes
      availability
      minPrice
      maxPrice
      calendarLink
      type
      tags
      business {
        id
        handle
        name
        logoUrl
        tagline
        address
        coverUrl
        externalLinks
        tagline
        description
        wallet {
          multiSigAddress
        }
      }
      category {
        name
        id
        icon
      }
      categoryId
      cost
      createdAt
      description
      id
      imageUrl
      title
      updatedAt
      videoUrl
      quantity
    }
  }
`

/**
 * __useFindOneListingQuery__
 *
 * To run a query within a React component, call `useFindOneListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOneListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOneListingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindOneListingQuery(
  baseOptions: Apollo.QueryHookOptions<FindOneListingQuery, FindOneListingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FindOneListingQuery, FindOneListingQueryVariables>(
    FindOneListingDocument,
    options,
  )
}
export function useFindOneListingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindOneListingQuery, FindOneListingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FindOneListingQuery, FindOneListingQueryVariables>(
    FindOneListingDocument,
    options,
  )
}
export type FindOneListingQueryHookResult = ReturnType<typeof useFindOneListingQuery>
export type FindOneListingLazyQueryHookResult = ReturnType<typeof useFindOneListingLazyQuery>
export type FindOneListingQueryResult = Apollo.QueryResult<
  FindOneListingQuery,
  FindOneListingQueryVariables
>
export const SearchListingsDocument = gql`
  query searchListings($query: String!, $filters: [String!], $page: Int, $limit: Int) {
    searchListings(query: $query, filters: $filters, page: $page, limit: $limit) {
      total
      listings {
        imageUrl
        title
        cost
        quantity
        id
        ...ListingFields
        business {
          handle
          name
          logoUrl
          tagline
        }
      }
    }
  }
  ${ListingFieldsFragmentDoc}
`

/**
 * __useSearchListingsQuery__
 *
 * To run a query within a React component, call `useSearchListingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchListingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchListingsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      filters: // value for 'filters'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });0
 */
export function useSearchListingsQuery(
  baseOptions: Apollo.QueryHookOptions<SearchListingsQuery, SearchListingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SearchListingsQuery, SearchListingsQueryVariables>(
    SearchListingsDocument,
    options,
  )
}
export function useSearchListingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchListingsQuery, SearchListingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SearchListingsQuery, SearchListingsQueryVariables>(
    SearchListingsDocument,
    options,
  )
}
export type SearchListingsQueryHookResult = ReturnType<typeof useSearchListingsQuery>
export type SearchListingsLazyQueryHookResult = ReturnType<typeof useSearchListingsLazyQuery>
export type SearchListingsQueryResult = Apollo.QueryResult<
  SearchListingsQuery,
  SearchListingsQueryVariables
>
export const FindMarketsDocument = gql`
  query findMarkets($where: MarketWhereInput) {
    findMarkets(where: $where) {
      id
      name
    }
  }
`

/**
 * __useFindMarketsQuery__
 *
 * To run a query within a React component, call `useFindMarketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindMarketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindMarketsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindMarketsQuery(
  baseOptions?: Apollo.QueryHookOptions<FindMarketsQuery, FindMarketsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FindMarketsQuery, FindMarketsQueryVariables>(FindMarketsDocument, options)
}
export function useFindMarketsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindMarketsQuery, FindMarketsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FindMarketsQuery, FindMarketsQueryVariables>(
    FindMarketsDocument,
    options,
  )
}
export type FindMarketsQueryHookResult = ReturnType<typeof useFindMarketsQuery>
export type FindMarketsLazyQueryHookResult = ReturnType<typeof useFindMarketsLazyQuery>
export type FindMarketsQueryResult = Apollo.QueryResult<FindMarketsQuery, FindMarketsQueryVariables>
export const CreateOrderDocument = gql`
  mutation createOrder($data: CreateOrderInput) {
    createOrder(data: $data) {
      id
    }
  }
`
export type CreateOrderMutationFn = Apollo.MutationFunction<
  CreateOrderMutation,
  CreateOrderMutationVariables
>

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(
    CreateOrderDocument,
    options,
  )
}
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>
export type CreateOrderMutationResult = Apollo.MutationResult<CreateOrderMutation>
export type CreateOrderMutationOptions = Apollo.BaseMutationOptions<
  CreateOrderMutation,
  CreateOrderMutationVariables
>
export const FindOneOrderDocument = gql`
  query findOneOrder($id: ID!) {
    findOneOrder(id: $id) {
      id
    }
  }
`

/**
 * __useFindOneOrderQuery__
 *
 * To run a query within a React component, call `useFindOneOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOneOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOneOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindOneOrderQuery(
  baseOptions: Apollo.QueryHookOptions<FindOneOrderQuery, FindOneOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FindOneOrderQuery, FindOneOrderQueryVariables>(
    FindOneOrderDocument,
    options,
  )
}
export function useFindOneOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindOneOrderQuery, FindOneOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FindOneOrderQuery, FindOneOrderQueryVariables>(
    FindOneOrderDocument,
    options,
  )
}
export type FindOneOrderQueryHookResult = ReturnType<typeof useFindOneOrderQuery>
export type FindOneOrderLazyQueryHookResult = ReturnType<typeof useFindOneOrderLazyQuery>
export type FindOneOrderQueryResult = Apollo.QueryResult<
  FindOneOrderQuery,
  FindOneOrderQueryVariables
>
export const CountAllOrdersDocument = gql`
  query countAllOrders($where: OrderWhereInput!) {
    countAllOrders(where: $where)
  }
`

/**
 * __useCountAllOrdersQuery__
 *
 * To run a query within a React component, call `useCountAllOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountAllOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountAllOrdersQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCountAllOrdersQuery(
  baseOptions: Apollo.QueryHookOptions<CountAllOrdersQuery, CountAllOrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CountAllOrdersQuery, CountAllOrdersQueryVariables>(
    CountAllOrdersDocument,
    options,
  )
}
export function useCountAllOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CountAllOrdersQuery, CountAllOrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CountAllOrdersQuery, CountAllOrdersQueryVariables>(
    CountAllOrdersDocument,
    options,
  )
}
export type CountAllOrdersQueryHookResult = ReturnType<typeof useCountAllOrdersQuery>
export type CountAllOrdersLazyQueryHookResult = ReturnType<typeof useCountAllOrdersLazyQuery>
export type CountAllOrdersQueryResult = Apollo.QueryResult<
  CountAllOrdersQuery,
  CountAllOrdersQueryVariables
>
export const GetOrderTotalDocument = gql`
  query getOrderTotal($listingId: ID!, $quantity: Int!) {
    getOrderTotal(listingId: $listingId, quantity: $quantity)
  }
`

/**
 * __useGetOrderTotalQuery__
 *
 * To run a query within a React component, call `useGetOrderTotalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderTotalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderTotalQuery({
 *   variables: {
 *      listingId: // value for 'listingId'
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useGetOrderTotalQuery(
  baseOptions: Apollo.QueryHookOptions<GetOrderTotalQuery, GetOrderTotalQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetOrderTotalQuery, GetOrderTotalQueryVariables>(
    GetOrderTotalDocument,
    options,
  )
}
export function useGetOrderTotalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOrderTotalQuery, GetOrderTotalQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetOrderTotalQuery, GetOrderTotalQueryVariables>(
    GetOrderTotalDocument,
    options,
  )
}
// export const UpdateCatalogDocument = gql`
//   mutation (
//     $id: ID!
//     $coverTitle: String!
//     $coverImage: String!
//     $footerTitle: String!
//     $footerImage: String!
//     $isActive: Boolean!
//     $lists: [UpdateMarketplaceListInput!]!
//   ) {
//     updateCatalog(
//       data: {
//         coverTitle: $coverTitle
//         coverImage: $coverImage
//         id: $id
//         footerTitle: $footerTitle
//         footerImage: $footerImage
//         isActive: $isActive
//         lists: $lists
//       }
//     ) {
//       coverTitle
//       coverImage
//       footerTitle
//       footerImage
//       id
//       isActive
//       lists {
//         id
//         title
//         coverImage
//         icon
//         sublists {
//           id
//           title
//           coverImage
//           icon
//         }
//       }
//     }
//   }
// `

export const UpdateCatalogDocument = gql`
  mutation UpdateCatalogInput($data: UpdateCatalogInput!) {
    updateCatalog(data: $data) {
      id
      coverTitle
      footerTitle

      lists {
        id
        coverImage
        icon
        title
        order
        sublists {
          coverImage
          icon
          id
          order
          title
          listings {
            id
            business {
              handle
            }
          }
        }
      }
    }
  }
`

// export const UpdateCatalogDocument = gql`
//   mutation UpdateCatalogInput($data: UpdateCatalogInput!) {
//     updateCatalog(data: $data) {
//       id
//     }
//   }
// `
export type GetOrderTotalQueryHookResult = ReturnType<typeof useGetOrderTotalQuery>
export type GetOrderTotalLazyQueryHookResult = ReturnType<typeof useGetOrderTotalLazyQuery>
export type GetOrderTotalQueryResult = Apollo.QueryResult<
  GetOrderTotalQuery,
  GetOrderTotalQueryVariables
>
export const UpdateOrderStatusDocument = gql`
  mutation updateOrderStatus($data: UpdateOrderStatusInput!, $id: ID!) {
    updateOrderStatus(data: $data, id: $id) {
      id
      status
    }
  }
`
export type UpdateOrderStatusMutationFn = Apollo.MutationFunction<
  UpdateOrderStatusMutation,
  UpdateOrderStatusMutationVariables
>

/**
 * __useUpdateOrderStatusMutation__
 *
 * To run a mutation, you first call `useUpdateOrderStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderStatusMutation, { data, loading, error }] = useUpdateOrderStatusMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */

export function useUpdateCatalogStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCatalogInput, MutationUpdateCatalogArgs>,
) {
  const options: any = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateCatalogInput, UpdateOrderStatusMutationVariables>(
    UpdateCatalogDocument,
    options,
  )
}
export function useUpdateOrderStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrderStatusMutation,
    UpdateOrderStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateOrderStatusMutation, UpdateOrderStatusMutationVariables>(
    UpdateOrderStatusDocument,
    options,
  )
}
export type UpdateOrderStatusMutationHookResult = ReturnType<typeof useUpdateOrderStatusMutation>
export type UpdateOrderStatusMutationResult = Apollo.MutationResult<UpdateOrderStatusMutation>
export type UpdateOrderStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrderStatusMutation,
  UpdateOrderStatusMutationVariables
>
export const SendRequestFormToSlackDocument = gql`
  mutation sendRequestFormToSlack($data: CreateRequestFormToSlackInput!) {
    sendRequestFormToSlack(data: $data)
  }
`
export type SendRequestFormToSlackMutationFn = Apollo.MutationFunction<
  SendRequestFormToSlackMutation,
  SendRequestFormToSlackMutationVariables
>

/**
 * __useSendRequestFormToSlackMutation__
 *
 * To run a mutation, you first call `useSendRequestFormToSlackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendRequestFormToSlackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendRequestFormToSlackMutation, { data, loading, error }] = useSendRequestFormToSlackMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendRequestFormToSlackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendRequestFormToSlackMutation,
    SendRequestFormToSlackMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SendRequestFormToSlackMutation,
    SendRequestFormToSlackMutationVariables
  >(SendRequestFormToSlackDocument, options)
}
export type SendRequestFormToSlackMutationHookResult = ReturnType<
  typeof useSendRequestFormToSlackMutation
>
export type SendRequestFormToSlackMutationResult =
  Apollo.MutationResult<SendRequestFormToSlackMutation>
export type SendRequestFormToSlackMutationOptions = Apollo.BaseMutationOptions<
  SendRequestFormToSlackMutation,
  SendRequestFormToSlackMutationVariables
>
export const SendFeedbackFormToSlackDocument = gql`
  mutation sendFeedbackFormToSlack($data: FeedbackFormToSlackInput!) {
    sendFeedbackFormToSlack(data: $data)
  }
`
export type SendFeedbackFormToSlackMutationFn = Apollo.MutationFunction<
  SendFeedbackFormToSlackMutation,
  SendFeedbackFormToSlackMutationVariables
>

/**
 * __useSendFeedbackFormToSlackMutation__
 *
 * To run a mutation, you first call `useSendFeedbackFormToSlackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendFeedbackFormToSlackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendFeedbackFormToSlackMutation, { data, loading, error }] = useSendFeedbackFormToSlackMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendFeedbackFormToSlackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendFeedbackFormToSlackMutation,
    SendFeedbackFormToSlackMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SendFeedbackFormToSlackMutation,
    SendFeedbackFormToSlackMutationVariables
  >(SendFeedbackFormToSlackDocument, options)
}
export type SendFeedbackFormToSlackMutationHookResult = ReturnType<
  typeof useSendFeedbackFormToSlackMutation
>
export type SendFeedbackFormToSlackMutationResult =
  Apollo.MutationResult<SendFeedbackFormToSlackMutation>
export type SendFeedbackFormToSlackMutationOptions = Apollo.BaseMutationOptions<
  SendFeedbackFormToSlackMutation,
  SendFeedbackFormToSlackMutationVariables
>
export const SendBugFormToSlackDocument = gql`
  mutation sendBugFormToSlack($data: BugFormToSlackInput!) {
    sendBugFormToSlack(data: $data)
  }
`
export type SendBugFormToSlackMutationFn = Apollo.MutationFunction<
  SendBugFormToSlackMutation,
  SendBugFormToSlackMutationVariables
>

/**
 * __useSendBugFormToSlackMutation__
 *
 * To run a mutation, you first call `useSendBugFormToSlackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendBugFormToSlackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendBugFormToSlackMutation, { data, loading, error }] = useSendBugFormToSlackMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendBugFormToSlackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendBugFormToSlackMutation,
    SendBugFormToSlackMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SendBugFormToSlackMutation, SendBugFormToSlackMutationVariables>(
    SendBugFormToSlackDocument,
    options,
  )
}
export type SendBugFormToSlackMutationHookResult = ReturnType<typeof useSendBugFormToSlackMutation>
export type SendBugFormToSlackMutationResult = Apollo.MutationResult<SendBugFormToSlackMutation>
export type SendBugFormToSlackMutationOptions = Apollo.BaseMutationOptions<
  SendBugFormToSlackMutation,
  SendBugFormToSlackMutationVariables
>
export const GetBusinessStreamTokenDocument = gql`
  query getBusinessStreamToken($businessId: ID!) {
    getBusinessStreamToken(businessId: $businessId)
  }
`

/**
 * __useGetBusinessStreamTokenQuery__
 *
 * To run a query within a React component, call `useGetBusinessStreamTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessStreamTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessStreamTokenQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useGetBusinessStreamTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBusinessStreamTokenQuery,
    GetBusinessStreamTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetBusinessStreamTokenQuery, GetBusinessStreamTokenQueryVariables>(
    GetBusinessStreamTokenDocument,
    options,
  )
}
export function useGetBusinessStreamTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBusinessStreamTokenQuery,
    GetBusinessStreamTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetBusinessStreamTokenQuery, GetBusinessStreamTokenQueryVariables>(
    GetBusinessStreamTokenDocument,
    options,
  )
}
export type GetBusinessStreamTokenQueryHookResult = ReturnType<
  typeof useGetBusinessStreamTokenQuery
>
export type GetBusinessStreamTokenLazyQueryHookResult = ReturnType<
  typeof useGetBusinessStreamTokenLazyQuery
>
export type GetBusinessStreamTokenQueryResult = Apollo.QueryResult<
  GetBusinessStreamTokenQuery,
  GetBusinessStreamTokenQueryVariables
>
export const FindOrCreateChannelDocument = gql`
  mutation findOrCreateChannel($targetBusinessId: ID!) {
    findOrCreateChannel(targetBusinessId: $targetBusinessId) {
      id
    }
  }
`
export type FindOrCreateChannelMutationFn = Apollo.MutationFunction<
  FindOrCreateChannelMutation,
  FindOrCreateChannelMutationVariables
>

/**
 * __useFindOrCreateChannelMutation__
 *
 * To run a mutation, you first call `useFindOrCreateChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFindOrCreateChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [findOrCreateChannelMutation, { data, loading, error }] = useFindOrCreateChannelMutation({
 *   variables: {
 *      targetBusinessId: // value for 'targetBusinessId'
 *   },
 * });
 */
export function useFindOrCreateChannelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FindOrCreateChannelMutation,
    FindOrCreateChannelMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<FindOrCreateChannelMutation, FindOrCreateChannelMutationVariables>(
    FindOrCreateChannelDocument,
    options,
  )
}
export type FindOrCreateChannelMutationHookResult = ReturnType<
  typeof useFindOrCreateChannelMutation
>
export type FindOrCreateChannelMutationResult = Apollo.MutationResult<FindOrCreateChannelMutation>
export type FindOrCreateChannelMutationOptions = Apollo.BaseMutationOptions<
  FindOrCreateChannelMutation,
  FindOrCreateChannelMutationVariables
>
export const SendRusdDocument = gql`
  mutation sendRusd($data: SendRusdInput) {
    sendRusd(data: $data) {
      id
    }
  }
`
export type SendRusdMutationFn = Apollo.MutationFunction<
  SendRusdMutation,
  SendRusdMutationVariables
>

/**
 * __useSendRusdMutation__
 *
 * To run a mutation, you first call `useSendRusdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendRusdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendRusdMutation, { data, loading, error }] = useSendRusdMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendRusdMutation(
  baseOptions?: Apollo.MutationHookOptions<SendRusdMutation, SendRusdMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SendRusdMutation, SendRusdMutationVariables>(SendRusdDocument, options)
}
export type SendRusdMutationHookResult = ReturnType<typeof useSendRusdMutation>
export type SendRusdMutationResult = Apollo.MutationResult<SendRusdMutation>
export type SendRusdMutationOptions = Apollo.BaseMutationOptions<
  SendRusdMutation,
  SendRusdMutationVariables
>
export const RetryTransactionDocument = gql`
  mutation retryTransaction($transactionId: ID!, $txData: String!, $txSignature: String!) {
    retryTransaction(transactionId: $transactionId, txData: $txData, txSignature: $txSignature) {
      id
    }
  }
`
export type RetryTransactionMutationFn = Apollo.MutationFunction<
  RetryTransactionMutation,
  RetryTransactionMutationVariables
>

/**
 * __useRetryTransactionMutation__
 *
 * To run a mutation, you first call `useRetryTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetryTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retryTransactionMutation, { data, loading, error }] = useRetryTransactionMutation({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *      txData: // value for 'txData'
 *      txSignature: // value for 'txSignature'
 *   },
 * });
 */
export function useRetryTransactionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RetryTransactionMutation,
    RetryTransactionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RetryTransactionMutation, RetryTransactionMutationVariables>(
    RetryTransactionDocument,
    options,
  )
}
export type RetryTransactionMutationHookResult = ReturnType<typeof useRetryTransactionMutation>
export type RetryTransactionMutationResult = Apollo.MutationResult<RetryTransactionMutation>
export type RetryTransactionMutationOptions = Apollo.BaseMutationOptions<
  RetryTransactionMutation,
  RetryTransactionMutationVariables
>
export const FindTransactionByTxIdDocument = gql`
  query findTransactionByTxId($txId: String!) {
    findTransactionByTxId(txId: $txId) {
      id
      txId
      status
    }
  }
`

/**
 * __useFindTransactionByTxIdQuery__
 *
 * To run a query within a React component, call `useFindTransactionByTxIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindTransactionByTxIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindTransactionByTxIdQuery({
 *   variables: {
 *      txId: // value for 'txId'
 *   },
 * });
 */
export function useFindTransactionByTxIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindTransactionByTxIdQuery,
    FindTransactionByTxIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FindTransactionByTxIdQuery, FindTransactionByTxIdQueryVariables>(
    FindTransactionByTxIdDocument,
    options,
  )
}
export function useFindTransactionByTxIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindTransactionByTxIdQuery,
    FindTransactionByTxIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FindTransactionByTxIdQuery, FindTransactionByTxIdQueryVariables>(
    FindTransactionByTxIdDocument,
    options,
  )
}
export type FindTransactionByTxIdQueryHookResult = ReturnType<typeof useFindTransactionByTxIdQuery>
export type FindTransactionByTxIdLazyQueryHookResult = ReturnType<
  typeof useFindTransactionByTxIdLazyQuery
>
export type FindTransactionByTxIdQueryResult = Apollo.QueryResult<
  FindTransactionByTxIdQuery,
  FindTransactionByTxIdQueryVariables
>
export const FindTransactionByIdDocument = gql`
  query findTransactionById($id: ID!) {
    findTransactionById(id: $id) {
      ...TransactionFields
      sender {
        id
        multiSigAddress
        business {
          id
          name
          handle
          logoUrl
        }
      }
      recipient {
        id
        multiSigAddress
        business {
          id
          name
          handle
          logoUrl
        }
      }
      order {
        id
        quantity
        status
        note
        deliveryAddress
        fulfillmentMethod
        listing {
          title
          cost
        }
      }
    }
  }
  ${TransactionFieldsFragmentDoc}
`

/**
 * __useFindTransactionByIdQuery__
 *
 * To run a query within a React component, call `useFindTransactionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindTransactionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindTransactionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindTransactionByIdQuery(
  baseOptions: Apollo.QueryHookOptions<FindTransactionByIdQuery, FindTransactionByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FindTransactionByIdQuery, FindTransactionByIdQueryVariables>(
    FindTransactionByIdDocument,
    options,
  )
}
export function useFindTransactionByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindTransactionByIdQuery,
    FindTransactionByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FindTransactionByIdQuery, FindTransactionByIdQueryVariables>(
    FindTransactionByIdDocument,
    options,
  )
}
export type FindTransactionByIdQueryHookResult = ReturnType<typeof useFindTransactionByIdQuery>
export type FindTransactionByIdLazyQueryHookResult = ReturnType<
  typeof useFindTransactionByIdLazyQuery
>
export type FindTransactionByIdQueryResult = Apollo.QueryResult<
  FindTransactionByIdQuery,
  FindTransactionByIdQueryVariables
>
export const FindManyTransactionsDocument = gql`
  query findManyTransactions(
    $where: TransactionWhereInput
    $orderBy: TransactionOrderByInput
    $limit: Int
    $page: Int
  ) {
    findManyTransactions(where: $where, orderBy: $orderBy, limit: $limit, page: $page) {
      transactions {
        ...TransactionFields
        sender {
          id
          business {
            id
            name
            handle
            logoUrl
          }
        }
        recipient {
          id
          business {
            id
            name
            handle
            logoUrl
          }
        }
        order {
          id
          quantity
          status
          listing {
            title
          }
        }
      }
      total
    }
  }
  ${TransactionFieldsFragmentDoc}
`

/**
 * __useFindManyTransactionsQuery__
 *
 * To run a query within a React component, call `useFindManyTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManyTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManyTransactionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useFindManyTransactionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindManyTransactionsQuery,
    FindManyTransactionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FindManyTransactionsQuery, FindManyTransactionsQueryVariables>(
    FindManyTransactionsDocument,
    options,
  )
}
export function useFindManyTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindManyTransactionsQuery,
    FindManyTransactionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FindManyTransactionsQuery, FindManyTransactionsQueryVariables>(
    FindManyTransactionsDocument,
    options,
  )
}
export type FindManyTransactionsQueryHookResult = ReturnType<typeof useFindManyTransactionsQuery>
export type FindManyTransactionsLazyQueryHookResult = ReturnType<
  typeof useFindManyTransactionsLazyQuery
>
export type FindManyTransactionsQueryResult = Apollo.QueryResult<
  FindManyTransactionsQuery,
  FindManyTransactionsQueryVariables
>
export const MeDocument = gql`
  query me {
    me {
      ...MeFields
    }
  }
  ${MeFieldsFragmentDoc}
`

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>
export const DisableUserDocument = gql`
  mutation disableUser($id: ID!) {
    disableUser(id: $id) {
      id
    }
  }
`
export type DisableUserMutationFn = Apollo.MutationFunction<
  DisableUserMutation,
  DisableUserMutationVariables
>

/**
 * __useDisableUserMutation__
 *
 * To run a mutation, you first call `useDisableUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableUserMutation, { data, loading, error }] = useDisableUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDisableUserMutation(
  baseOptions?: Apollo.MutationHookOptions<DisableUserMutation, DisableUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DisableUserMutation, DisableUserMutationVariables>(
    DisableUserDocument,
    options,
  )
}
export type DisableUserMutationHookResult = ReturnType<typeof useDisableUserMutation>
export type DisableUserMutationResult = Apollo.MutationResult<DisableUserMutation>
export type DisableUserMutationOptions = Apollo.BaseMutationOptions<
  DisableUserMutation,
  DisableUserMutationVariables
>
export const FindAllUsersDocument = gql`
  query findAllUsers($where: UserWhereInput, $skip: Int, $take: Int) {
    findAllUsers(where: $where, skip: $skip, take: $take) {
      id
    }
  }
`

/**
 * __useFindAllUsersQuery__
 *
 * To run a query within a React component, call `useFindAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllUsersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useFindAllUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<FindAllUsersQuery, FindAllUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FindAllUsersQuery, FindAllUsersQueryVariables>(
    FindAllUsersDocument,
    options,
  )
}
export function useFindAllUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindAllUsersQuery, FindAllUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FindAllUsersQuery, FindAllUsersQueryVariables>(
    FindAllUsersDocument,
    options,
  )
}
export type FindAllUsersQueryHookResult = ReturnType<typeof useFindAllUsersQuery>
export type FindAllUsersLazyQueryHookResult = ReturnType<typeof useFindAllUsersLazyQuery>
export type FindAllUsersQueryResult = Apollo.QueryResult<
  FindAllUsersQuery,
  FindAllUsersQueryVariables
>
export const FindUserByIdDocument = gql`
  query findUserById($id: ID!) {
    findUserById(id: $id) {
      id
    }
  }
`

/**
 * __useFindUserByIdQuery__
 *
 * To run a query within a React component, call `useFindUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindUserByIdQuery(
  baseOptions: Apollo.QueryHookOptions<FindUserByIdQuery, FindUserByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FindUserByIdQuery, FindUserByIdQueryVariables>(
    FindUserByIdDocument,
    options,
  )
}
export function useFindUserByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindUserByIdQuery, FindUserByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FindUserByIdQuery, FindUserByIdQueryVariables>(
    FindUserByIdDocument,
    options,
  )
}
export type FindUserByIdQueryHookResult = ReturnType<typeof useFindUserByIdQuery>
export type FindUserByIdLazyQueryHookResult = ReturnType<typeof useFindUserByIdLazyQuery>
export type FindUserByIdQueryResult = Apollo.QueryResult<
  FindUserByIdQuery,
  FindUserByIdQueryVariables
>
export const FindUserByEmailDocument = gql`
  query findUserByEmail($email: String!) {
    findUserByEmail(email: $email) {
      id
    }
  }
`

/**
 * __useFindUserByEmailQuery__
 *
 * To run a query within a React component, call `useFindUserByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUserByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUserByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useFindUserByEmailQuery(
  baseOptions: Apollo.QueryHookOptions<FindUserByEmailQuery, FindUserByEmailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FindUserByEmailQuery, FindUserByEmailQueryVariables>(
    FindUserByEmailDocument,
    options,
  )
}
export function useFindUserByEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindUserByEmailQuery, FindUserByEmailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FindUserByEmailQuery, FindUserByEmailQueryVariables>(
    FindUserByEmailDocument,
    options,
  )
}
export type FindUserByEmailQueryHookResult = ReturnType<typeof useFindUserByEmailQuery>
export type FindUserByEmailLazyQueryHookResult = ReturnType<typeof useFindUserByEmailLazyQuery>
export type FindUserByEmailQueryResult = Apollo.QueryResult<
  FindUserByEmailQuery,
  FindUserByEmailQueryVariables
>
export const CountAllUsersDocument = gql`
  query countAllUsers($where: UserWhereInput) {
    countAllUsers(where: $where)
  }
`

/**
 * __useCountAllUsersQuery__
 *
 * To run a query within a React component, call `useCountAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountAllUsersQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCountAllUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<CountAllUsersQuery, CountAllUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CountAllUsersQuery, CountAllUsersQueryVariables>(
    CountAllUsersDocument,
    options,
  )
}
export function useCountAllUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CountAllUsersQuery, CountAllUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CountAllUsersQuery, CountAllUsersQueryVariables>(
    CountAllUsersDocument,
    options,
  )
}
export type CountAllUsersQueryHookResult = ReturnType<typeof useCountAllUsersQuery>
export type CountAllUsersLazyQueryHookResult = ReturnType<typeof useCountAllUsersLazyQuery>
export type CountAllUsersQueryResult = Apollo.QueryResult<
  CountAllUsersQuery,
  CountAllUsersQueryVariables
>
export const DoubleEncryptHashDocument = gql`
  mutation doubleEncryptHash($encryptedHash: String!) {
    doubleEncryptHash(encryptedHash: $encryptedHash) {
      data
    }
  }
`
export type DoubleEncryptHashMutationFn = Apollo.MutationFunction<
  DoubleEncryptHashMutation,
  DoubleEncryptHashMutationVariables
>

/**
 * __useDoubleEncryptHashMutation__
 *
 * To run a mutation, you first call `useDoubleEncryptHashMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDoubleEncryptHashMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [doubleEncryptHashMutation, { data, loading, error }] = useDoubleEncryptHashMutation({
 *   variables: {
 *      encryptedHash: // value for 'encryptedHash'
 *   },
 * });
 */
export function useDoubleEncryptHashMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DoubleEncryptHashMutation,
    DoubleEncryptHashMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DoubleEncryptHashMutation, DoubleEncryptHashMutationVariables>(
    DoubleEncryptHashDocument,
    options,
  )
}
export type DoubleEncryptHashMutationHookResult = ReturnType<typeof useDoubleEncryptHashMutation>
export type DoubleEncryptHashMutationResult = Apollo.MutationResult<DoubleEncryptHashMutation>
export type DoubleEncryptHashMutationOptions = Apollo.BaseMutationOptions<
  DoubleEncryptHashMutation,
  DoubleEncryptHashMutationVariables
>
export const WalletBalanceDocument = gql`
  query walletBalance($id: ID!) {
    walletBalance(id: $id) {
      availableCredit
      balance
      creditLimit
    }
  }
`

/**
 * __useWalletBalanceQuery__
 *
 * To run a query within a React component, call `useWalletBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useWalletBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWalletBalanceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWalletBalanceQuery(
  baseOptions: Apollo.QueryHookOptions<WalletBalanceQuery, WalletBalanceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<WalletBalanceQuery, WalletBalanceQueryVariables>(
    WalletBalanceDocument,
    options,
  )
}
export function useWalletBalanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WalletBalanceQuery, WalletBalanceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<WalletBalanceQuery, WalletBalanceQueryVariables>(
    WalletBalanceDocument,
    options,
  )
}
export type WalletBalanceQueryHookResult = ReturnType<typeof useWalletBalanceQuery>
export type WalletBalanceLazyQueryHookResult = ReturnType<typeof useWalletBalanceLazyQuery>
export type WalletBalanceQueryResult = Apollo.QueryResult<
  WalletBalanceQuery,
  WalletBalanceQueryVariables
>
export const ClaimWalletDocument = gql`
  mutation claimWallet($clientAddress: String!, $guardianAddress: String!, $id: ID!) {
    claimWallet(clientAddress: $clientAddress, guardianAddress: $guardianAddress, id: $id) {
      id
      isActive
      multiSigAddress
    }
  }
`
export type ClaimWalletMutationFn = Apollo.MutationFunction<
  ClaimWalletMutation,
  ClaimWalletMutationVariables
>

/**
 * __useClaimWalletMutation__
 *
 * To run a mutation, you first call `useClaimWalletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimWalletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimWalletMutation, { data, loading, error }] = useClaimWalletMutation({
 *   variables: {
 *      clientAddress: // value for 'clientAddress'
 *      guardianAddress: // value for 'guardianAddress'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClaimWalletMutation(
  baseOptions?: Apollo.MutationHookOptions<ClaimWalletMutation, ClaimWalletMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ClaimWalletMutation, ClaimWalletMutationVariables>(
    ClaimWalletDocument,
    options,
  )
}
export type ClaimWalletMutationHookResult = ReturnType<typeof useClaimWalletMutation>
export type ClaimWalletMutationResult = Apollo.MutationResult<ClaimWalletMutation>
export type ClaimWalletMutationOptions = Apollo.BaseMutationOptions<
  ClaimWalletMutation,
  ClaimWalletMutationVariables
>
