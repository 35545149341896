import {
  Box,
  BoxProps,
  Heading,
  HStack,
  VStack,
  Stack,
  Text,
  StackProps,
  HeadingProps,
} from "@chakra-ui/layout"
import React, { useEffect, useState } from "react"
import CloudinaryImage from "../../../../components/CloudinaryImage"
import { MarketplaceList, UpdateCatalogDocument } from "../../../../generated/graphql"
import { baseThumbnailStyles } from "./SharedStyles"
import EditTitle from "./EditTitle"
import { useMutation } from "@apollo/client"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { SelectedCatalogAtom, SelectedListAtom } from "../../../../store/listing"
import { CatalogMutation } from "../CatalogMutation"
import { useToast } from "@chakra-ui/react"

interface Props extends BoxProps {
  list: MarketplaceList
}
const ThreeWideList = ({ list, ...rest }: Props) => {
  const { title, sublists } = list
  const [isTitleEditAble, setTitleEditable] = useState(false)
  const [isTitle, setIsTitle] = useState(true)
  const [titleValue, setTitleValue] = useState(title)
  const [inputValue, setInputValue] = useState("")
  const [catalogInput] = useMutation(UpdateCatalogDocument)
  const selectedCatalog: any = useRecoilValue(SelectedCatalogAtom)
  const [id, setId] = useState("")
  const toast = useToast()

  useEffect(() => {
    setTitleValue(title)
  }, [title])
  const handleSubmit = () => {
    setIsTitle(true)
    setTitleEditable(false)
    setInputValue(inputValue)
    const updatedCatalog = JSON.parse(JSON.stringify(selectedCatalog))
    updatedCatalog?.lists.map((obj) => {
      if (obj !== undefined) {
        if (obj.id === id) {
          obj.title = titleValue
          return obj
        }
      }
    })

    CatalogMutation(catalogInput, updatedCatalog, toast)
  }
  return (
    <VStack align="flex-start" h="500px" {...rest} px="20px">
      <Box pos="relative" h="70px">
        <Heading
          {...headingStyles}
          border={isTitle ? "6px solid transparent" : "none"}
          _hover={{
            border: isTitle ? "6px solid #699DFF" : "none",
            borderRadius: "8px",
            bg: "#F2F2F2",
          }}
          onClick={() => {
            setTitleEditable(true)
            setIsTitle(false)
            setId(list.id)
          }}
        >
          {titleValue || "Title"}
        </Heading>

        {isTitleEditAble && (
          <Box pos="absolute" top={2} width="100%" minW="350px" zIndex="1">
            <EditTitle
              setIsEdit={setTitleEditable}
              setIsTitle={setIsTitle}
              setValue={setTitleValue}
              coverTitle={title}
              value={titleValue}
              catalogInput={catalogInput}
              handleSubmit={handleSubmit}
            />
          </Box>
        )}
      </Box>

      <Box
        overflowX="auto"
        pb={3}
        filter={isTitleEditAble ? "blur(2px)" : "none"}
        opacity={isTitleEditAble ? "0.50" : "1"}
        maxW="full"
        className="webkitbox"
      >
        <HStack spacing={4} w="fit-content">
          <Thumbnail list={sublists[0]} />
          <Thumbnail list={sublists[1]} />
          <Thumbnail list={sublists[2]} />
        </HStack>
      </Box>
    </VStack>
  )
}

const Thumbnail = ({ list }: Props) => {
  const [hover, setHover] = useState(false)
  const setSelectedList = useSetRecoilState(SelectedListAtom)

  const handleClick = () => {
    setSelectedList(list)
  }
  return (
    <VStack
      to={`/marketplace/edit/list/${list.id}`}
      w={{ base: "260px", md: "400px" }}
      onClick={handleClick}
      align="flex-start"
      {...baseThumbnailStyles}
    >
      <CloudinaryImage
        fit="cover"
        borderRadius="25px"
        quality="low"
        w={{ base: "260px", md: "410px" }}
        h={{ base: "200px", md: "260px" }}
        _hover={{ shadow: hover ? "lg" : "" }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        border={hover ? "12px solid #699dff" : "12px solid transparent"}
        src={list.coverImage}
      />
      <Box>
        <Stack
          mt="-5.5rem"
          className="editthree"
          {...StackStyles}
          color={hover ? "white" : "transparent"}
          bg={hover ? "rgba(255, 255, 255, 0.15)" : "transparent"}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <Text>EDIT LIST</Text>
        </Stack>
      </Box>
      <Heading {...subHeadingStyles}>{list.title}</Heading>
    </VStack>
  )
}
const StackStyles: StackProps = {
  minW: "376px",
  px: "5",
  ml: "3",
  h: "53px",
  justifyContent: "center",
  align: "flex-end",
  fontWeight: "400",
  fontSize: "14px",
  fontFamily: "Poppins",
}
const headingStyles: HeadingProps = {
  fontSize: "36px",
  color: "#595959",
  fontWeight: "600",
  py: 2,
  px: 1,
}
const subHeadingStyles: HeadingProps = {
  fontSize: "28px",
  color: "#333333",
  fontWeight: "600",
  pl: "3",
}
export default React.memo(ThreeWideList)
