import FontIconPicker from "@fonticonpicker/react-fonticonpicker"
import "@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.base-theme.react.css"
import "@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.material-theme.react.css"
import * as iconDefs from "../IconDefs"
import "./styles.scss"
import { CatalogMutation } from "../CatalogMutation"
import { useMutation } from "@apollo/client"
import { UpdateCatalogDocument } from "../../../../generated/graphql"
import { useToast } from "@chakra-ui/react"
const IconPicker = (props) => {
  const { iconClass, setIconClass, currentCatalog, sublistId } = props
  const [catalogInput] = useMutation(UpdateCatalogDocument)
  const toast = useToast()

  const handleChange = (value) => {
    setIconClass(value)

    if (value) {
      if (currentCatalog) {
        const updatedCatalog = JSON.parse(JSON.stringify(currentCatalog))
        updatedCatalog?.lists?.map((obj) => {
          if (obj !== undefined) {
            obj.sublists.map((childObj) => {
              if (childObj.id === sublistId) {
                childObj.icon = value
              }
              return childObj
            })
            return obj
          }
        })
        CatalogMutation(catalogInput, updatedCatalog, toast)
      }
    }
  }

  return (
    <FontIconPicker
      icons={iconDefs.icons}
      value={iconClass}
      theme="bluegrey"
      isMulti={false}
      closeOnSelect={true}
      onChange={handleChange}
      allCatPlaceholder="Show from all"
    />
  )
}

export default IconPicker
