import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Text,
  Button,
  useDisclosure,
  Heading,
  Input,
  ButtonProps,
} from "@chakra-ui/react"
import { FaPlus } from "react-icons/fa"

export default function AddListingModal(props) {
  const { setNewMarket, handleSubmit } = props
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleRemove = () => {
    onClose()
    setNewMarket("")
  }

  return (
    <>
      <Button
        onClick={onOpen}
        leftIcon={<FaPlus style={{ fontWeight: 300 }} />}
        {...buttonStyles}
        _hover={{ shadow: "none", bg: "#FBFAFE" }}
      >
        New market
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Heading fontSize="28px" fontWeight="600" mt="4" h="60px">
              Add new market
            </Heading>
            <>
              <Text fontSize="14px" fontWeight="400" color="#8C8C8C" h="30px" pl="2">
                Market name
              </Text>
              <Input
                mb="3"
                placeholder="Write name"
                fontSize="18px"
                color="#BDBDBD"
                h="47px"
                _focus={{ bg: "white" }}
                onChange={(e) => {
                  setNewMarket(e.target.value)
                }}
              ></Input>
            </>
          </ModalBody>
          <ModalFooter>
            <Button
              w="114px"
              h="47px"
              mr={5}
              onClick={handleRemove}
              border="2px solid #EAE5FC"
              bg="white"
              color="#957FEF"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleSubmit()
                onClose()
              }}
              bg="#957FEF"
              w="86px"
              h="47px"
            >
              Add
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

const buttonStyles: ButtonProps = {
  h: "37px",
  borderRadius: "0",
  fontSize: "14px",
  w: "full",
  justifyContent: "flex-start",
  px: "2",
  fontWeight: "400",
  color: "black",
  bg: "none",
}
