import { HStack, Spinner, Text } from "@chakra-ui/react"
import {
  FindMarketplaceListByIdDocument,
  FindCatalogById,
  FindManyCatalog,
} from "../../../generated/graphql"
export const CatalogMutation = (catalogInput, selectedCatalog, toast) => {
  if (selectedCatalog.market) {
    delete selectedCatalog.market
  }
  const updatedCatalog = JSON.parse(JSON.stringify(selectedCatalog))
  updatedCatalog?.lists.map((obj) => {
    if (obj !== undefined) {
      obj.sublists.map((childObj) => {
        childObj = childObj?.listings.map((listingObj) => {
          delete listingObj?.title
          delete listingObj?.imageUrl
          delete listingObj?.cost

          if (listingObj?.business) {
            delete listingObj?.business.logoUrl
            delete listingObj?.business.name
          }
        })
        return childObj
      })
      return obj
    }
  })

  toast({
    description: (
      <HStack>
        <Text color={"white"}>Saving changes</Text>
        <Spinner />
      </HStack>
    ),
    duration: 2000,
    isClosable: true,
  })

  catalogInput({
    refetchQueries: [FindMarketplaceListByIdDocument, FindCatalogById, FindManyCatalog],
    variables: {
      data: {
        ...updatedCatalog,
      },
    },
  }).then(() => {
    toast({
      description: "Changes saved",
      status: "success",
      duration: 3000,
      isClosable: true,
    })
  })
  return
}
