import { CeloProvider } from "@celo-tools/celo-ethers-wrapper"
import { ethers } from "ethers"
import { config } from "../../../../config"
import { MultiSigWallet, MultiSigWallet__factory } from "../../types"
import CryptoJS, { AES, enc } from "crypto-js/core"
import SHA3_512 from "crypto-js/sha3"
import { retrieveEncryptedPrivateKey } from "../../../../pages/wallet/utils/fetchFromKeystore"
import * as Sentry from "@sentry/react"

const GLOBAL_SALT = "F*im3Cwc16X:"

export const getProvider = async () => {
  const provider = new CeloProvider(config.BLOCKCHAIN.NETWORK)
  await provider.ready
  return provider
}

export const getWalletFromEmailPassword = async (params: {
  walletId: string
  email: string
  password: string
}): Promise<ethers.Wallet> => {
  const { email, password, walletId } = params
  const piiHash = getPiiHash({ email, password })
  return await getWalletFromEncryptedPk(false, walletId, piiHash)
}

export const isClientWalletValid = async (clientWalletAddress: string, multiSigAddress) => {
  const provider = await getProvider()
  const multiSigContract = getMultiSigContract(multiSigAddress, provider)
  const owners = await multiSigContract.getOwners()
  return owners.includes(clientWalletAddress)
}

export const getMultiSigContract = (multiSigAddress: string, provider: CeloProvider) => {
  return new ethers.Contract(
    multiSigAddress,
    MultiSigWallet__factory.createInterface(),
    provider,
  ) as MultiSigWallet
}

export interface CreateWalletProps {
  email: string
  password: string
}

export const getPiiHash = ({ email, password }: CreateWalletProps): string => {
  const piiRawValues = [email, password, GLOBAL_SALT].join()
  const piiHash = SHA3_512(piiRawValues, { outputLength: 256 }).toString()
  return piiHash
}

export const formatToBytesLike = (key) => {
  return Buffer.from(key.toString(CryptoJS.enc.Hex), "hex")
}

const getWalletFromEncryptedPk = async (
  fetchedLocally: boolean,
  walletId: string,
  piiHash: string,
): Promise<ethers.Wallet> => {
  let encryptedKey
  let wallet
  if (fetchedLocally) {
    encryptedKey = (await retrieveEncryptedPrivateKey(walletId)).data
    window.localStorage.setItem("encryptedPrivateKey", encryptedKey)
  } else {
    encryptedKey = window.localStorage.getItem("encryptedPrivateKey")
  }
  if (!encryptedKey || encryptedKey === `""`)
    encryptedKey = (await retrieveEncryptedPrivateKey(walletId)).data
  if (!encryptedKey) throw new Error("There was a problem fetching your wallet information")
  const pkey = AES.decrypt(encryptedKey, piiHash)

  try {
    wallet = new ethers.Wallet(pkey.toString(enc.Utf8))
  } catch (e) {
    // if password is valid but localstorage is out of date, fetch from keystore
    if (e.message.includes("invalid hexlify value") && !fetchedLocally) {
      return await getWalletFromEncryptedPk(true, walletId, piiHash)
    }
    window.localStorage.setItem("encryptedPrivateKey", "")
    Sentry.captureException(e)
    throw new Error("Your wallet needs to be recovered. Please try resetting your password.")
  }
  return wallet
}
