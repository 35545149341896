import { Box, BoxProps, Heading, HeadingProps, StackProps } from "@chakra-ui/layout"
import { HStack, useToast, VStack } from "@chakra-ui/react"
import { useEffect, useRef, useState } from "react"
import {
  MarketplaceList,
  UpdateCatalogDocument,
  useFindMarketplaceListByIdQuery,
} from "../../../../generated/graphql"
import { ListingCard } from "../ListingCard"
import { AddListing } from "./AddListing"
import { useMutation } from "@apollo/client"
import EditTitle from "./EditTitle"
import CustomDraggable from "../../../../components/Draggable/Draggable"
import bgImage from "../../assets/listingbg.jpg"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { listingDataAtom, removeListingAtom, SelectedCatalogAtom } from "../../../../store/listing"
import { CatalogMutation } from "../CatalogMutation"

interface Props extends BoxProps {
  list: MarketplaceList
}

const ListingList = ({ list, ...rest }: Props) => {
  const toast = useToast()

  const [bgHeight, setBgHeight] = useState(420)
  const query = useFindMarketplaceListByIdQuery({
    variables: { id: list.id },
    fetchPolicy: "network-only",
    skip: list.listings?.length > 0,
  })
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const refHeight = ref.current?.clientHeight ?? 0
    const bgHeight = refHeight > 400 ? refHeight : 0
    setBgHeight(bgHeight)
  }, [ref.current?.clientHeight])

  if (list) {
    localStorage.setItem("sublistId", list.id)
  }
  const title = list.title ?? query.data?.findMarketplaceListById?.title
  const [isTitleEditAble, setTitleEditable] = useState(false)
  const [isTitle, setIsTitle] = useState(true)
  const [inputValue, setInputValue] = useState("")
  const [titleValue, setTitleValue] = useState(title || "Title")
  const [catalogInput] = useMutation(UpdateCatalogDocument)
  const [listingData, setListingsData]: any = useState([])
  const newCardData = useRecoilValue(listingDataAtom)
  const setNewCardData: any = useSetRecoilState(listingDataAtom)
  const removeListing = useRecoilValue(removeListingAtom)
  const SetRemoveListing = useSetRecoilState(removeListingAtom)
  const selectedCatalog: any = useRecoilValue(SelectedCatalogAtom)
  const [id, setId] = useState("")
  const [componentPosition, setComponentPosition]: any = useState()

  useEffect(() => {
    if (list.listings.length >= 0) {
      setListingsData(list.listings)
    }
  }, [list])
  useEffect(() => {
    if (Object.keys(newCardData).length > 0) {
      setListingsData([newCardData, ...listingData])
      setNewCardData({})
    }
  }, [newCardData])

  useEffect(() => {
    if (removeListing.state) {
      const filteredList = listingData.filter((list) => list.id !== removeListing.id)
      setListingsData(filteredList)
      SetRemoveListing({ id: "", state: false })
    }
  }, [removeListing])

  useEffect(() => {
    if (listingData) {
      const component = listingData?.map((listing) => (
        <ListingCard
          key={listing.id}
          layout="desktop"
          w="242px"
          listing={listing}
          isRemove={true}
          isListing={true}
        />
      ))
      setComponentPosition(component)
    }
  }, [listingData])

  const handleSubmit = () => {
    setIsTitle(true)
    setTitleEditable(false)
    setInputValue(inputValue)
    const updatedCatalog = JSON.parse(JSON.stringify(selectedCatalog))
    updatedCatalog?.lists.map((obj) => {
      if (obj !== undefined) {
        obj.sublists.map((childObj) => {
          if (childObj.id === id) {
            childObj.title = titleValue
          }
          return childObj
        })
        return obj
      }
    })

    CatalogMutation(catalogInput, updatedCatalog, toast)
  }

  const handleOnDragEnd = (result) => {
    if (!result.destination) return
    const items: any = Array.from(componentPosition)
    const [reorderedItem] = items?.splice(result.source.index, 1)
    items?.splice(result.destination.index, 0, reorderedItem)
    setComponentPosition(items)

    const listings = items.map((item) => {
      return item.props.listing
    })
    const updatedCatalog = JSON.parse(JSON.stringify(selectedCatalog))
    updatedCatalog?.lists.map((obj) => {
      if (obj !== undefined) {
        obj.sublists.map((childObj) => {
          if (childObj.id === list.id) {
            childObj.listings = listings
          }
          return childObj
        })
        return obj
      }
    })

    CatalogMutation(catalogInput, updatedCatalog, toast)
  }

  return (
    <>
      <VStack ref={ref} {...mainStackStyles} {...rest} bgImage={bgImage}>
        <Box pos="relative" h="40px" zIndex="1">
          <Heading
            {...headingStyles}
            border={isTitle ? "6px solid transparent" : "none"}
            _hover={{
              border: isTitle ? "6px solid #699DFF" : "none",
              borderRadius: "8px",
              bg: "#F2F2F2",
            }}
            onClick={() => {
              setTitleEditable(true)
              setIsTitle(false)
              setId(list.id)
            }}
          >
            {titleValue || "Title"}
          </Heading>

          {isTitleEditAble && (
            <Box pos="absolute" top={2} width="100%" minW="470px">
              <EditTitle
                setIsEdit={setTitleEditable}
                setIsTitle={setIsTitle}
                setValue={setTitleValue}
                coverTitle={title}
                value={titleValue}
                catalogInput={catalogInput}
                handleSubmit={handleSubmit}
              />
            </Box>
          )}
        </Box>

        <HStack
          {...cardStckStyles}
          opacity={isTitleEditAble ? "0.50" : "1"}
          filter={isTitleEditAble ? "blur(2px)" : "none"}
        >
          <Box mr="2">
            <AddListing id={list?.id} />
          </Box>
          <Box overflowX={"auto"}>
            <CustomDraggable
              componentPosition={componentPosition}
              direction="horizontal"
              listClass="listing"
              handleOnDragEnd={handleOnDragEnd}
            />
          </Box>
        </HStack>
      </VStack>
    </>
  )
}
const headingStyles: HeadingProps = {
  fontSize: "28px",
  color: "#595959",
  fontWeight: "600",
  py: 2,
  px: 1,
}
const mainStackStyles: StackProps = {
  align: "flex-start",
  px: "30px",
  py: 4,
}
const cardStckStyles: StackProps = {
  overflowX: "auto",
  px: "3",
  pb: 3,
  spacing: 2,
  width: "var(--chakra-sizes-full)",
}
export default ListingList
