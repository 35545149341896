import { MarketplaceList } from "../../../../generated/graphql"
import FeaturedList from "./FeaturedList"
import FourWideList from "./FourWideList"
import ThreeWideList from "./ThreeWideList"
interface Props {
  list: MarketplaceList
  catalog: Record<string, number>
}

const MarketplaceListFactory = ({ list, catalog }: Props) => {
  switch (list.sublists.length) {
    case 4:
      return <FourWideList list={list} />
    case 3:
      return <ThreeWideList list={list} />
    case 1:
      return <FeaturedList list={list} />
    default:
      return null
  }
}

export default MarketplaceListFactory
