import { Heading } from "@chakra-ui/react"

function CatalogHeader() {
  return (
    <Heading fontSize="28px" color="#333333" fontWeight="600" pt="3" px="3">
      Catalog Editor
    </Heading>
  )
}

export default CatalogHeader
