import { HStack } from "@chakra-ui/react"
import IsActive from "./IsActive"
import MarketMenu from "./MarketMenu"
import PreviewButton from "./PreviewButton"
const TopMenu = (props) => {
  const { allMarkets, handleSelectMarket, setCurrentMarket, currentMarket, isMarketPlace } = props
  return (
    <HStack h="80px" justifyContent="space-between">
      <HStack zIndex={1}>
        <MarketMenu
          allMarkets={allMarkets}
          handleSelectMarket={handleSelectMarket}
          setCurrentMarket={setCurrentMarket}
          currentMarket={currentMarket}
          isMarketPlace={isMarketPlace}
        />
      </HStack>
      <HStack minW="315px" pr="3">
        <IsActive catalog={currentMarket} />
        <PreviewButton currentMarket={currentMarket} />
      </HStack>
    </HStack>
  )
}

export default TopMenu
