// import {
//   Avatar,
//   Button,
//   Menu,
//   MenuButton,
//   MenuButtonProps,
//   MenuItem,
//   MenuList,
//   useBreakpointValue,
// } from "@chakra-ui/react"
// import {
//   faBug,
//   faCog,
//   faSignOutAlt,
//   faStickyNote,
//   faStoreAlt,
// } from "@fortawesome/free-solid-svg-icons"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { useCallback } from "react"
// import { useHistory } from "react-router-dom"
// import defaultImage from "../assets/images/defaultImage.svg"
// import { useAuth } from "../services/auth/AuthProvider"
// import { ZendeskMenuItem } from "../services/zendesk/ZendeskIcon"
// import { useGetMe } from "../store/useGetMe"

// export const MenuDropdown = (props: MenuButtonProps) => {
//   const { me, myBusiness } = useGetMe()
//   const ambassador = me?.ambassador
//   const history = useHistory()
//   const auth = useAuth()
//   const device = useBreakpointValue({ base: "mobile", md: "desktop" })

//   const navigateTo = useCallback((path: string) => () => history.push(path), [])

//   return (
//     <>
//       <Menu {...props}>
//         <MenuButton
//           data-testid="user_menu_btn"
//           as={Button}
//           style={{ margin: 0 }}
//           variant="ghost"
//           _hover={{ base: {}, md: { bg: "gray.300" } }}
//           px={0}
//           {...props}
//         >
//           <Avatar
//             src={myBusiness?.logoUrl || (myBusiness ? (defaultImage as string) : "")}
//             alt={"Author"}
//             size="xs"
//           />
//         </MenuButton>
//         <MenuList zIndex="100">
//           {myBusiness && (
//             <>
//               <MenuItem
//                 textStyle="body"
//                 onClick={() => window.open(`https://staging.resourcenetwork.co/settings`)}
//                 icon={<FontAwesomeIcon icon={faCog} />}
//               >
//                 Settings
//               </MenuItem>
//             </>
//           )}
//           {ambassador && (
//             <>
//               <MenuItem
//                 textStyle="body"
//                 onClick={() => window.open(`https://staging.resourcenetwork.co/settings`)}
//                 icon={<FontAwesomeIcon icon={faStoreAlt} />}
//               >
//                 Ambassador Profile
//               </MenuItem>
//             </>
//           )}
//           <MenuItem
//             textStyle="body"
//             onClick={() => window.open(`https://staging.resourcenetwork.co/feedback`)}
//             icon={<FontAwesomeIcon icon={faStickyNote} />}
//           >
//             Feedback
//           </MenuItem>
//           <MenuItem
//             textStyle="body"
//             onClick={() => window.open(`https://staging.resourcenetwork.co/report`)}
//             icon={<FontAwesomeIcon icon={faBug} />}
//           >
//             Bug report
//           </MenuItem>
//           {auth.isSignedIn && device === "mobile" && <ZendeskMenuItem />}
//           <MenuItem
//             data-testid="logout_btn"
//             textStyle="body"
//             icon={<FontAwesomeIcon icon={faSignOutAlt} />}
//             // onClick={navigateTo("/login")}
//             onClick={() => history.push("/")}
//           >
//             Logout
//           </MenuItem>
//         </MenuList>
//       </Menu>
//     </>
//   )
// }

import {
  Avatar,
  Button,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuItem,
  MenuList,
  useBreakpointValue,
} from "@chakra-ui/react"
import {
  faBug,
  faCog,
  faSignOutAlt,
  faStickyNote,
  faStoreAlt,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useCallback } from "react"
import { useHistory } from "react-router-dom"
import defaultImage from "../assets/images/defaultImage.svg"
import { useAuth } from "../services/auth/AuthProvider"
import { ZendeskMenuItem } from "../services/zendesk/ZendeskIcon"
import { useGetMe } from "../store/useGetMe"
export const MenuDropdown = (props: MenuButtonProps) => {
  const { me, myBusiness } = useGetMe()
  const ambassador = me?.ambassador
  const history = useHistory()
  const auth = useAuth()
  const device = useBreakpointValue({ base: "mobile", md: "desktop" })

  const navigateTo = useCallback((path: string) => () => history.push(path), [])

  return (
    <>
      <Menu {...props}>
        <MenuButton
          data-testid="user_menu_btn"
          as={Button}
          style={{ margin: 0 }}
          variant="ghost"
          _hover={{ base: {}, md: { bg: "gray.300" } }}
          px={0}
          {...props}
        >
          <Avatar
            src={myBusiness?.logoUrl || (myBusiness ? (defaultImage as string) : "")}
            alt={"Author"}
            size="xs"
          />
        </MenuButton>
        <MenuList zIndex="100">
          {myBusiness && (
            <>
              <MenuItem
                textStyle="body"
                onClick={navigateTo("/settings")}
                icon={<FontAwesomeIcon icon={faCog} />}
              >
                Settings
              </MenuItem>
            </>
          )}
          {ambassador && (
            <>
              <MenuItem
                textStyle="body"
                onClick={navigateTo(`/ambassador/${ambassador.handle}`)}
                icon={<FontAwesomeIcon icon={faStoreAlt} />}
              >
                Ambassador Profile
              </MenuItem>
            </>
          )}
          <MenuItem
            textStyle="body"
            onClick={navigateTo("/feedback")}
            icon={<FontAwesomeIcon icon={faStickyNote} />}
          >
            Feedback
          </MenuItem>
          <MenuItem
            textStyle="body"
            onClick={navigateTo("/report")}
            icon={<FontAwesomeIcon icon={faBug} />}
          >
            Bug report
          </MenuItem>
          {auth.isSignedIn && device === "mobile" && <ZendeskMenuItem />}
          <MenuItem
            data-testid="logout_btn"
            textStyle="body"
            icon={<FontAwesomeIcon icon={faSignOutAlt} />}
            onClick={auth.signout}
          >
            Logout
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  )
}
