import { IconButton, MenuItem } from "@chakra-ui/react"
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Zendesk from "react-zendesk"
import { useState } from "react"
import colors from "../../theme/foundations/colors"

const ZENDESK_KEY = "e48eb63f-e356-416c-88fa-ab20dc11b596"

const setting = {
  color: {
    theme: colors.purple.main,
    button: colors.purple.main,
    header: colors.purple.main,
  },
  launcher: {
    mobile: {
      labelVisible: true,
    },
  },
  position: { horizontal: "right", vertical: "top" },
  offset: { horizontal: "108px", vertical: "40px" },
  contactForm: {
    fields: [{ id: "description" }],
  },
}

// eslint-disable-next-line
declare let window: any

export const ZendeskIcon = () => {
  const [isOpen, setIsOpen] = useState(false)

  const handleLoadEvent = () => {
    window.zE?.("webWidget", "hide")
  }

  const toggleZendeskChat = () => {
    if (isOpen) {
      window.zE?.("webWidget", "hide")
      setIsOpen(false)
    } else {
      console.log("window", window)
      window.zE?.activate({ hideOnClose: true })
      setIsOpen(true)
    }
  }
  return (
    <>
      <IconButton
        pr="0px"
        data-testid="btn_zendesk"
        variant="ghost"
        borderRadius="2xl"
        aria-label="show zendesk widget"
        onClick={toggleZendeskChat}
      >
        <FontAwesomeIcon color={colors.gray[900]} icon={faQuestionCircle} />
      </IconButton>
      <Zendesk defer zendeskKey={ZENDESK_KEY} {...setting} onLoaded={handleLoadEvent} />
    </>
  )
}

export const ZendeskMenuItem = () => {
  const [isOpen, setIsOpen] = useState(false)

  const handleLoadEvent = () => {
    window.zE?.("webWidget", "hide")
  }

  const toggleZendeskChat = () => {
    if (isOpen) {
      window.zE?.("webWidget", "hide")
      setIsOpen(false)
    } else {
      window.zE?.activate({ hideOnClose: true })
      setIsOpen(true)
    }
  }
  return (
    <>
      <MenuItem
        textStyle="body"
        onClick={toggleZendeskChat}
        icon={<FontAwesomeIcon icon={faQuestionCircle} />}
      >
        Help
      </MenuItem>
      <Zendesk defer zendeskKey={ZENDESK_KEY} {...setting} onLoaded={handleLoadEvent} />
    </>
  )
}
