import { Alert, AlertProps, CloseButton, Text } from "@chakra-ui/react"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
interface Props extends AlertProps {
  onClose: any
}

const SubmittingTransactionAlert = ({ onClose }: Props) => {
  return (
    <Alert data-testid="notification_toast_submitting" variant="ledger">
      <FontAwesomeIcon icon={faSpinner} color="white" spin />
      <Text ml={3}>Submitting transaction</Text>
      <CloseButton ml="auto" justifySelf="flex-end" onClick={onClose} />
    </Alert>
  )
}

export default SubmittingTransactionAlert
