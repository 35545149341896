import { Box, GridItem, SimpleGrid, useToast } from "@chakra-ui/react"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { EndOfResults } from "../../../components/EndOfResults"
import { NoSearchResults } from "../../../components/NoSearchResults"
import { UpdateCatalogDocument, Business, Listing } from "../../../generated/graphql"
import {
  SelectedCatalogAtom,
  listingDataAtom,
  closeModalAtom,
  replaceCardAtom,
} from "../../../store/listing"
import { BusinessCard } from "./BusinessCard"
import { ListingCard } from "./ListingCard"
import { CatalogMutation } from "./CatalogMutation"
import { useMutation } from "@apollo/client"

export const DirectoryGrid = ({
  items,
  type,
  total,
  current,
  pageSize,
  called,
  loading,
  isCategory,
}) => {
  const isLastPage = Math.ceil(total / pageSize) === current
  const setCloseModal = useSetRecoilState(closeModalAtom)
  const selectedCatalog: any = useRecoilValue(SelectedCatalogAtom)
  const [catalogInput] = useMutation(UpdateCatalogDocument)
  const coverPageListingId = localStorage.getItem("selectedCatalogID")
  const sublistId = localStorage.getItem("sublistId")
  const replaced = useRecoilValue(replaceCardAtom)
  const setListingData = useSetRecoilState(listingDataAtom)
  const toast = useToast()

  const handleClickCard = (title, type, id, cost, imageUrl, business) => {
    setCloseModal(true)
    const data: any = {
      title: title,
      type: type,
      id: id,
      cost: cost,
      imageUrl: imageUrl,
      business: business,
    }
    const updatedCatalog = JSON.parse(JSON.stringify(selectedCatalog))

    if (replaced) {
      updatedCatalog?.lists.map((obj) => {
        if (obj !== undefined) {
          obj.sublists?.map((childObj) => {
            if (childObj.id === sublistId) {
              const indexToReplace = childObj.listings.findIndex((target) => target.id === replaced)
              const cloneListing: any = [...childObj.listings]
              cloneListing[indexToReplace] = {
                id: data.id,
                business: { handle: data.business.handle },
              }
              childObj.listings = cloneListing
            }
            return childObj
          })
          return obj
        }
      })
      CatalogMutation(catalogInput, updatedCatalog, toast)
    } else {
      updatedCatalog?.lists.map((obj) => {
        if (obj !== undefined) {
          obj.sublists?.map((childObj) => {
            if (childObj.id === sublistId || childObj.id === coverPageListingId) {
              childObj.listings.unshift({ id: data?.id })
            }
            return childObj
          })
          return obj
        }
      })
      CatalogMutation(catalogInput, updatedCatalog, toast)
      setListingData(data)
    }
  }

  const clickBusinessCard = ({
    address,
    coverUrl,
    description,
    handle,
    logoUrl,
    name,
    tagline,
    id,
    type,
  }) => {
    setCloseModal(true)
    const businessData: any = {
      address: address,
      coverUrl: coverUrl,
      description: description,
      handle: handle,
      logoUrl: logoUrl,
      name: name,
      tagline: tagline,
      id: id,
      type: type,
    }
    const updatedCatalog = JSON.parse(JSON.stringify(selectedCatalog))
    updatedCatalog?.lists.map((obj) => {
      if (obj !== undefined) {
        obj.sublists?.map((childObj) => {
          if (childObj.id === sublistId || childObj.id === coverPageListingId) {
            childObj.listings.unshift({
              id: id,
            })
          }
          return childObj
        })
        return obj
      }
    })
    CatalogMutation(catalogInput, updatedCatalog, toast)
    setListingData(businessData)
  }

  return (
    <>
      {called && !loading && items.length === 0 && <NoSearchResults />}
      <SimpleGrid columns={{ base: 1, sm: 2, md: 3, xl: 4 }} gap={{ base: 0, sm: 6 }}>
        {type === "listing" &&
          items?.map((listing, index) => (
            <Box
              onClick={() =>
                handleClickCard(
                  listing.title,
                  listing.type,
                  listing.id,
                  listing.cost,
                  listing.imageUrl,
                  listing.business,
                )
              }
              key={index}
            >
              <ListingCard key={index} listing={listing as Listing} isCategory={isCategory} />
            </Box>
          ))}
        {type === "business" &&
          items.map((business, index) => {
            return (
              <Box key={index} p={{ base: 4, sm: 0 }} onClick={() => clickBusinessCard(business)}>
                <BusinessCard key={index} business={business as Business} />
              </Box>
            )
          })}
      </SimpleGrid>
      {called && isLastPage && items.length > 0 && (
        <SimpleGrid mt="60px" columns={[1]} spacing="40px">
          <GridItem mx={{ base: "auto", md: "inherit" }} />
          <EndOfResults />
        </SimpleGrid>
      )}
    </>
  )
}
