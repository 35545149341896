import { InputProps } from "@chakra-ui/react"
import { HStack, VStack, IconButton, Input } from "@chakra-ui/react"
import { useCallback, useEffect } from "react"
import { FaCheck, FaTimes } from "react-icons/fa"

const EditTitle = ({ handleSubmit, setIsTitle, setIsEdit, setValue, coverTitle, value }: any) => {
  const handleCancel = () => {
    setIsTitle(true)
    setIsEdit(false)
    setValue(coverTitle)
  }

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      handleSubmit(value)
    }
  }

  const handleEsc = useCallback((e) => {
    if (e.key === "Escape") {
      handleCancel()
    }
  }, [])

  useEffect(() => {
    document.addEventListener("keydown", handleEsc)
    return () => {
      document.removeEventListener("keydown", handleEsc)
    }
  }, [handleEsc])

  const handleInput = (e) => {
    setValue(e.target.value)
  }

  return (
    <VStack w="full" className="heading">
      <Input {...inputStyles} value={value} onChange={handleInput} onKeyDown={handleEnter} />
      <HStack alignSelf="flex-end">
        <IconButton aria-label="Checked" bg="#FF3C38" icon={<FaTimes />} onClick={handleCancel} />
        <IconButton aria-label="Checked" bg="#3772FF" icon={<FaCheck />} onClick={handleSubmit} />
      </HStack>
    </VStack>
  )
}

const inputStyles: InputProps = {
  borderRadius: "8px",
  w: "full",
  background: "#ffffff",
  h: "62px",
  fontSize: "28px",
  fontWeight: "600",
  border: " 6px solid transparent",
  _focus: { border: " 6px solid #699DFF" },
}
export default EditTitle
