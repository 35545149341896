import {
  Box,
  BoxProps,
  Menu,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react"
import { useRecoilState, useRecoilValue } from "recoil"
import { FulfillmentTypeIcon, ListingTypeIcon } from "../../../components/ListingIcons"
import { MenuButton } from "../../../components/Menu"
import { ListingType, useFindMarketsQuery } from "../../../generated/graphql"
import { fulfillmentTypesSelector, listingQuerySelector } from "../../../store/listing"
import { FulfillmentType, getCorrespondingFulfillmentType } from "../utils/types"
export type ListingTypeWithAny = ListingType | "Any"
const types = ["Any", ...Object.keys(ListingType)]

export const ListingTypeFilter = (props: BoxProps) => {
  const [{ types: selectedTypes, fulfillmentType }, setListingQuery] =
    useRecoilState(listingQuerySelector)
  const selectedType = selectedTypes[0]

  const handleSelect = (type: ListingTypeWithAny) => {
    if (type === selectedType) return
    const newFulfillmentType = getCorrespondingFulfillmentType(type, fulfillmentType)
    setListingQuery((prev) => ({
      ...prev,
      page: 1,
      types: type === "Any" ? [] : [type],
      fulfillmentType: newFulfillmentType ?? "Any",
    }))
  }

  return (
    <Box {...props}>
      <Text mx={3} mb={2}>
        Type
      </Text>
      <Menu data-testid="listing_type_filter_menu" autoSelect={false} {...props}>
        {({ isOpen }) => (
          <>
            <MenuButton
              minW="170px"
              isOpen={isOpen}
              leftIcon={<ListingTypeIcon type={selectedType ?? "Any"} />}
              data-testid="listing_type_filter_menu_btn"
            >
              {selectedType ?? "Any"}
            </MenuButton>
            <MenuList>
              <MenuGroup title="Listing type">
                <MenuDivider />
                {types.map((type: any) => (
                  <MenuItem key={type} onClick={() => handleSelect(type)}>
                    {type}
                    <ListingTypeIcon type={type} />
                  </MenuItem>
                ))}
              </MenuGroup>
            </MenuList>
          </>
        )}
      </Menu>
    </Box>
  )
}

export const ListingFulfillmentFilter = ({ ...rest }: BoxProps) => {
  const [{ fulfillmentType }, setListingQuery] = useRecoilState(listingQuerySelector)
  const availableFulfillmentTypes = useRecoilValue(fulfillmentTypesSelector)
  const handleSelect = (type: FulfillmentType) => {
    if (type === fulfillmentType) return
    setListingQuery((prev) => ({ ...prev, page: 1, fulfillmentType: type }))
  }

  return (
    <Box {...rest}>
      <Text mx={3} mb={2}>
        Fulfillment
      </Text>
      <Menu data-testid="fulfillment_filter_menu" autoSelect={false} {...rest}>
        {({ isOpen }) => (
          <>
            <MenuButton
              minW="150px"
              isOpen={isOpen}
              leftIcon={<FulfillmentTypeIcon type={fulfillmentType} />}
              data-testid="fulfillment_filter_menu_btn"
            >
              {fulfillmentType}
            </MenuButton>
            <MenuList>
              <MenuGroup title="Fulfillment">
                <MenuDivider />
                {availableFulfillmentTypes.map((type) => {
                  return (
                    <MenuItem key={type} onClick={() => handleSelect(type)}>
                      {type}
                      <FulfillmentTypeIcon type={type} />
                    </MenuItem>
                  )
                })}
              </MenuGroup>
            </MenuList>
          </>
        )}
      </Menu>
    </Box>
  )
}

interface MarketFilterProps extends BoxProps {
  market: string
  setMarket: (name: string) => void
}
export const MarketFilter = ({ market, setMarket, ...rest }: MarketFilterProps) => {
  const { data } = useFindMarketsQuery()

  return (
    <Box {...rest}>
      <Text mx={3} mb={2}>
        Market
      </Text>
      <Menu data-testid="market_filter_menu" autoSelect={false} {...rest}>
        {({ isOpen }) => (
          <>
            <MenuButton
              w="150px"
              isOpen={isOpen}
              overflow={"hidden"}
              data-testid="market_filter_menu_btn"
            >
              {market}
            </MenuButton>
            <MenuList maxH="200px" overflowY="auto">
              <MenuGroup title="Market">
                <MenuDivider />
                {data?.findMarkets.map(({ id, name }) => {
                  const marketName = name !== "Public" ? name : "Any"
                  return (
                    <MenuItem key={id} onClick={() => setMarket(marketName)}>
                      {marketName}
                    </MenuItem>
                  )
                })}
              </MenuGroup>
            </MenuList>
          </>
        )}
      </Menu>
    </Box>
  )
}
