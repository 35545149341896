import { Center, Flex, HStack, StackProps } from "@chakra-ui/layout"
import { Image, ImageProps, useBreakpointValue } from "@chakra-ui/react"
import { faSearch, faStore } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useHistory } from "react-router-dom"
import { atom, useRecoilValue, useSetRecoilState } from "recoil"
import logo from "../assets/logotype.svg"
import ChatNavIcon from "./ChatNavIcon"
import { MenuDropdown } from "./Dropdowns"
import { useIsOnboarding } from "./foundations"
import ActivateWallet from "../pages/wallet/components/ActivateWallet"
import { useAuth } from "../services/auth/AuthProvider"
import { useGetMe } from "../store/useGetMe"
import { onlyDesktop, onlyMobile } from "../theme/utils/display"
import { RusdGlyphGradient } from "../components/glyph/Glyph"
import { ZendeskIcon } from "../services/zendesk/ZendeskIcon"
import Button from "../components/Button"
import AccountBalanceInfo from "../services/ledger/components/WalletInfo"

export const Header = () => {
  const { isSignedIn, hasFetchedToken } = useAuth()
  const headerState = useRecoilValue(headerAtom)
  const isOnboarding = useIsOnboarding()
  const containerStyles: StackProps = {
    px: { base: 4, md: 6 },
    py: { base: 1, md: 2 },
    justify: "space-between",
    borderBottom: "solid 1px",
    borderColor: "gray.300",
    position: "sticky",
    bgColor: "white",
    zIndex: 100,
    top: "0px",
  }

  if (headerState === "hide") return null
  return (
    <Flex {...containerStyles}>
      {!hasFetchedToken || isOnboarding ? (
        <FullLogo />
      ) : (
        <>
          {isSignedIn && <MemberToolbar />}
          {!isSignedIn && <VisitorToolbar />}
        </>
      )}
    </Flex>
  )
}

const MemberToolbar = () => {
  const history = useHistory()
  const { myBusiness, myWallet } = useGetMe()
  const myHandle = myBusiness.handle
  const device = useBreakpointValue({ base: "mobile", md: "desktop" })

  if (device === "mobile") {
    return (
      <HStack align="center" justifyContent="space-between" w="full">
        <RusdGlyphGradient boxSize="40px" onClick={() => history.push("/marketplace")} />
        {myWallet && !myWallet.isActive ? <ActivateWallet /> : <AccountBalanceInfo />}
      </HStack>
    )
  }

  return (
    <>
      <Image
        src={logo}
        width="150px"
        _hover={{ cursor: "pointer" }}
        onClick={() => history.push("/marketplace")}
      />

      <Flex justify="flex-end" w="full">
        <HStack align="center" spacing={{ lg: 2, xl: 6 }}>
          <Center w="120px">
            <Button
              data-testid="nav_marketplace"
              variant="link"
              colorScheme="primary"
              isActive={window.location.href.includes("marketplace")}
              display={onlyDesktop}
              onClick={() => history.push(`/marketplace`)}
              leftIcon={<FontAwesomeIcon icon={faSearch} />}
            >
              Marketplace
            </Button>
          </Center>
          {myHandle && (
            <Center w="130px" pr={2}>
              <Button
                data-testid="nav_my_storefront"
                variant="link"
                isActive={window.location.href.includes(myHandle)}
                colorScheme="primary"
                display={onlyDesktop}
                onClick={() => history.push(`/${myHandle}`)}
                leftIcon={<FontAwesomeIcon icon={faStore} />}
              >
                My Storefront
              </Button>
            </Center>
          )}

          {myWallet.id && !myWallet.isActive ? <ActivateWallet /> : <AccountBalanceInfo />}
          <Flex flexWrap={"nowrap"}>
            <ZendeskIcon />
            <ChatNavIcon aria-label="chat" />
            <MenuDropdown pr={0} mr={0} />
          </Flex>
        </HStack>
      </Flex>
    </>
  )
}

const VisitorToolbar = () => {
  const history = useHistory()

  return (
    <>
      <FullLogo display={onlyDesktop} />
      <RusdGlyphGradient
        boxSize="40px"
        display={onlyMobile}
        onClick={() => history.push(`/marketplace`)}
      />
      <HStack spacing={4}>
        <Button
          data-testid="nav_marketplace"
          variant="link"
          colorScheme="primary"
          isActive={window.location.href.includes("marketplace")}
          display={onlyDesktop}
          onClick={() => history.push(`/marketplace`)}
          leftIcon={<FontAwesomeIcon icon={faSearch} />}
        >
          Marketplace
        </Button>
        <ZendeskIcon />
        <Button
          data-testid="nav_login"
          variant="outline"
          colorScheme="primary"
          onClick={() => history.push("/login")}
        >
          Login
        </Button>
        <Button
          data-testid="nav_join"
          colorScheme="primary"
          variant="secondary"
          onClick={() => history.push("/register")}
        >
          Join
        </Button>
      </HStack>
    </>
  )
}

const FullLogo = (props: ImageProps) => {
  const history = useHistory()
  return (
    <Image
      src={logo}
      width="150px"
      _hover={{ cursor: "pointer" }}
      onClick={() => history.push("/marketplace")}
      {...props}
    />
  )
}

const headerAtom = atom<"show" | "hide">({ key: "headerAtom", default: "show" })

export const useToggleHeader = () => {
  const setHeader = useSetRecoilState(headerAtom)
  return (val?: "show" | "hide") =>
    setHeader((prev) => {
      if (val) return val
      return prev === "show" ? "hide" : "show"
    })
}

export default Header
