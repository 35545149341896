import { Box, Container } from "@chakra-ui/layout"
import { useLocation } from "react-router-dom"
import SplashPage from "../../../components/SplashPage"
import CatalogCover from "./catalog/CatalogCover"
import CatalogFooter from "./catalog/CatalogFooter"
import CategoryList from "./catalog/CategoryList"
import ListingList from "./catalog/ListingList"
import MarketplaceListFactory from "./catalog/MarketplaceListFactory"
import CatalogHeader from "./CatalogHeader"
import DirectoriesPage from "./DirectoriesPage"
import { useEffect } from "react"
import "./catalog/styles.scss"
import TopMenu from "./catalog/TopMenu"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { SelectedCatalogAtom } from "../../../store/listing"
import { useFindManyCatalogQuery } from "../../../generated/graphql"
const CatalogPage = () => {
  const { data: Data, loading }: any = useFindManyCatalogQuery({})
  const currentCatalogId = localStorage.getItem("selectedCatalogID")
  const location = useLocation()
  const selectedCatalog: any = useRecoilValue(SelectedCatalogAtom)
  const setSelectedCatalog = useSetRecoilState(SelectedCatalogAtom)
  useEffect(() => {
    if (!loading && Data) {
      if (currentCatalogId) {
        const [initialCatalog] = Data?.findManyCatalog?.catalogs.filter(
          (catalog) => catalog?.id === currentCatalogId,
        )
        localStorage.setItem("selectedCatalogID", initialCatalog?.id)
        localStorage.setItem("sublistId", initialCatalog?.lists[3].sublists[0].id)
        setSelectedCatalog(initialCatalog)
      } else {
        const [initialCatalog] = Data?.findManyCatalog?.catalogs.filter(
          (catalog) => catalog.market?.name === "Public",
        )
        localStorage.setItem("selectedCatalogID", initialCatalog?.id)
        localStorage.setItem("sublistId", initialCatalog?.lists[3].sublists[0].id)

        setSelectedCatalog(initialCatalog)
      }
    }
  }, [loading, Data])

  const handleSelectMarket = (id) => {
    const [selectCatalog] = Data?.findManyCatalog?.catalogs.filter((data) => data.id === id)
    setSelectedCatalog(selectCatalog)
    localStorage.setItem("selectedCatalogID", selectCatalog?.id)
  }

  if (location.pathname === "/marketplace/listings") {
    return (
      <Box w="full">
        <CatalogHeader />
        <DirectoriesPage />
      </Box>
    )
  }
  if (loading) return <SplashPage />
  return (
    <Box className="handPointer">
      {selectedCatalog?.lists && (
        <>
          <CatalogHeader />
          <TopMenu
            allMarkets={Data?.findManyCatalog?.catalogs}
            handleSelectMarket={handleSelectMarket}
            setSelectedCatalog={setSelectedCatalog}
            currentMarket={selectedCatalog}
          />
          <CatalogCover catalog={selectedCatalog} />
          <Container maxW="container.xl" p={0}>
            <MarketplaceListFactory list={selectedCatalog?.lists[0]} catalog={selectedCatalog} />
            <MarketplaceListFactory list={selectedCatalog?.lists[1]} catalog={selectedCatalog} />
            <MarketplaceListFactory list={selectedCatalog?.lists[2]} catalog={selectedCatalog} />
            <CategoryList />
            <ListingList list={selectedCatalog?.lists[3].sublists[0]} />
          </Container>
          <CatalogFooter catalog={selectedCatalog} />,
        </>
      )}
    </Box>
  )
}

export default CatalogPage
