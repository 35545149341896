import { useMutation } from "@apollo/client"
import { Box, Grid, GridItem } from "@chakra-ui/layout"
import { Container, useBreakpointValue, useDisclosure, useToast } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { useParams } from "react-router"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { AnyObject } from "yup/lib/object"
import CustomDraggable from "../../components/Draggable/Draggable"
import { PurpleBackdrop } from "../../components/PurpleBackdrop"
import SplashPage from "../../components/SplashPage"
import {
  MarketplaceList,
  UpdateCatalogDocument,
  useFindCatalogByIdQuery,
  useFindManyCatalogQuery,
  useFindMarketplaceListByIdQuery,
} from "../../generated/graphql"
import {
  listingDataAtom,
  removeListingAtom,
  replaceCardAtom,
  SelectedCatalogAtom,
  SublistIdAtom,
} from "../../store/listing"
import { AddListing } from "./components/catalog/AddListing"
import EditlistModal from "./components/catalog/EditlistModal"
import MarketplaceListCover from "./components/catalog/MarketplaceListCover"
import TopMenu from "./components/catalog/TopMenu"
import { CatalogMutation } from "./components/CatalogMutation"
import { ListingCard } from "./components/ListingCard"

const MarketplaceListPage = () => {
  const { id } = useParams<{ id: string }>()
  const { data, loading: listLoading } = useFindMarketplaceListByIdQuery({
    fetchPolicy: "network-only",
    variables: { id },
  })
  const list = data?.findMarketplaceListById as MarketplaceList
  const listings = list?.listings ?? []
  const setSublistId = useSetRecoilState(SublistIdAtom)
  const shouldShowBackdrop = useBreakpointValue({ base: false, sm: true })
  const { isOpen, onOpen, onClose } = useDisclosure()
  const removeList = useRecoilValue(removeListingAtom)
  const SetRemoveListing = useSetRecoilState(removeListingAtom)
  const newCardData = useRecoilValue(listingDataAtom)
  const setNewCardData = useSetRecoilState(listingDataAtom)
  const setSelectedCatalog: any = useSetRecoilState(SelectedCatalogAtom)
  const { data: allCatalogs }: any = useFindManyCatalogQuery({})
  const replace = useRecoilValue(replaceCardAtom)
  const setReplace = useSetRecoilState(replaceCardAtom)
  const currentCatalogId: any = localStorage.getItem("selectedCatalogID")
  localStorage.setItem("sublistId", id)
  const [currentCatalog, setCurrentCatalog]: any = useState({})
  const [listingsData, setListingsData]: any = useState([])
  const [componentPosition, setComponentPosition]: any = useState()
  const [catalogInput] = useMutation(UpdateCatalogDocument)
  const toast = useToast()
  const { data: Data, loading: Loading } = useFindCatalogByIdQuery({
    variables: {
      id: currentCatalogId,
    },
  })

  useEffect(() => {
    if (!Loading && Data) {
      setSublistId(id)
      const { findCatalogById } = Data
      setCurrentCatalog(findCatalogById)
      setSelectedCatalog(findCatalogById)
    }
  }, [Data, Loading])

  useEffect(() => {
    if (!listLoading && listings) {
      setListingsData(listings)
    }
  }, [listings, listLoading])

  useEffect(() => {
    if (Object.keys(newCardData).length > 0) {
      if (replace) {
        const indexToReplace = listingsData.findIndex((target) => target.id === replace)
        const cloneListing = [...listingsData]
        cloneListing[indexToReplace] = newCardData
        setListingsData(cloneListing)
        setReplace("")
      } else {
        setListingsData([newCardData, ...listingsData])
        setNewCardData({})
      }
    }
  }, [newCardData])

  useEffect(() => {
    if (removeList.state) {
      const cloneListing = listingsData?.filter((lists) => lists.id !== removeList.id)
      setListingsData(cloneListing)
      SetRemoveListing({
        id: "",
        state: false,
      })
    }
  }, [removeList])

  useEffect(() => {
    if (listingsData) {
      const component = listingsData?.map((listing) => {
        return (
          <Box onClick={(e) => handleClickCard(listing?.id, e)} key={listing?.id}>
            <ListingCard key={listing?.id} listing={listing} isRemove={true} />
          </Box>
        )
      })
      setComponentPosition(component)
    }
  }, [listingsData])

  const handleOnDragEnd = (result) => {
    if (!result.destination) return
    const items: Record<string, AnyObject>[] = Array.from(componentPosition)
    const [reorderedItem] = items?.splice(result.source.index, 1)
    items?.splice(result.destination.index, 0, reorderedItem)
    setComponentPosition(items)
    const listings = items.map((item) => {
      return item?.props.children.props.listing
    })
    const updatedCatalog = JSON.parse(JSON.stringify(currentCatalog))
    updatedCatalog?.lists.map((obj) => {
      if (obj !== undefined) {
        obj.sublists.map((childObj) => {
          if (childObj.id === id) {
            childObj.listings = listings
          }
          return childObj
        })
        return obj
      }
    })

    CatalogMutation(catalogInput, updatedCatalog, toast)
  }

  const handleClickCard = (id, e) => {
    setReplace(id)
    onOpen()
    e.stopPropagation()
  }
  if (Loading) return <SplashPage />
  return (
    <Box>
      <Grid templateColumns="repeat(8, 1fr)" gap={0} w="full">
        <GridItem colSpan={8} w="full" h="full">
          <TopMenu
            allMarkets={allCatalogs?.findManyCatalog?.catalogs}
            currentMarket={Data && currentCatalog}
            isMarketPlace={true}
          />

          <MarketplaceListCover currentCatalog={currentCatalog} list={list} id={id} />
          <PurpleBackdrop showBackdrop={shouldShowBackdrop}>
            <Container maxW="container.xl" pt={5}>
              <Box pos="absolute" mt="30px">
                <AddListing />
              </Box>
              <CustomDraggable
                componentPosition={componentPosition}
                handleOnDragEnd={handleOnDragEnd}
                direction="horizontal"
                listClass="marketplace"
              />
            </Container>
          </PurpleBackdrop>
          {isOpen && <EditlistModal isOpen={isOpen} onClose={onClose} />}
        </GridItem>
      </Grid>
    </Box>
  )
}
export default MarketplaceListPage
