import { useDisclosure } from "@chakra-ui/react"
import { useState } from "react"
import Button from "../../../components/Button"
import ActivateWalletModal from "./ActivateWalletModal"

const ActivateWallet = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isActivating, setIsActivating] = useState(false)

  return (
    <>
      <Button
        isDisabled={isActivating}
        variant="primary"
        colorScheme="primary"
        onClick={() => onOpen()}
      >
        Activate wallet
      </Button>
      <ActivateWalletModal setIsActivating={setIsActivating} isOpen={isOpen} onClose={onClose} />
    </>
  )
}

export default ActivateWallet
