import { Route, Switch, useRouteMatch } from "react-router-dom"
import { featureFlags } from "../../utils/featureFlags"
import CatalogPage from "./components/CatalogPage"
import DirectoriesPage from "./components/DirectoriesPage"
import MarketplaceListPage from "./MarketplaceListPage"

const MarketplaceRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      {featureFlags.Catalog && <Route exact path={`${path}`} component={CatalogPage} />}
      {featureFlags.Catalog && (
        <Route exact path={`${path}/edit/list/:id`} component={MarketplaceListPage} />
      )}
      <Route path={`${path}`} component={DirectoriesPage} />
    </Switch>
  )
}

export default MarketplaceRoutes
