import { SystemStyleObjectRecord } from "@chakra-ui/react"

export const layerStyles: SystemStyleObjectRecord = {
  base: {
    // bg: "gray.50",
    // border: "2px solid",
    // borderColor: "gray.500",
  },
  selected: {
    // bg: "teal.500",
    // color: "teal.700",
    // borderColor: "orange.500",
  },
}
