import { atom, selector } from "recoil"
import { ListingType } from "../generated/graphql"
import { FulfillmentType, type2FulfillmentMap } from "../pages/marketplace/utils/types"
interface ListingQueryOptions {
  page: number
  pageSize: number
  searchText: string
  fulfillmentType: FulfillmentType
  marketName: string
  types: ListingType[]
}

const defaultQueryOptions = {
  page: 1,
  pageSize: 24,
  searchText: "",
  fulfillmentType: "Any",
  marketName: "Any",
  types: [],
}

export const listingAtom = atom({
  key: "listingAtom",
  default: {
    queryOptions: defaultQueryOptions as ListingQueryOptions,
  },
})

export const listingQuerySelector = selector<ListingQueryOptions>({
  key: "listingQuerySelector",
  get: ({ get }) => get(listingAtom).queryOptions,
  set: ({ set }, newValue) => {
    set(listingAtom, (prevState) => ({ ...prevState, queryOptions: newValue } as any))
  },
})
export const fulfillmentTypesSelector = selector<FulfillmentType[]>({
  key: "fulfillmentTypesSelector",
  get: ({ get }) => {
    const { types } = get(listingAtom).queryOptions
    const selectedType = types[0] ?? "Any"
    return type2FulfillmentMap[selectedType] ?? type2FulfillmentMap["Any"]
  },
})

export const listingDataAtom = atom({
  key: "listingDataAtom",
  default: {},
})

export const replaceCardAtom = atom({
  key: "replaceCardAtom",
  default: "",
})
export const closeModalAtom = atom({
  key: "closeModalAtom",
  default: false,
})
export const removeListingAtom = atom({
  key: "removeListingAtom",
  default: {
    id: "",
    state: false,
  },
})

export const SublistIdAtom = atom({
  key: "SublistIdAtom",
  default: "",
})

export const SelectedCatalogAtom = atom({
  key: "SelectedCatalogAtom",
  default: {},
})
export const SelectedListAtom = atom({
  key: "SelectedListAtom",
  default: {},
})
