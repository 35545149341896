/* eslint-disable react/display-name */
import { useToast } from "@chakra-ui/react"
import * as Sentry from "@sentry/react"
import AES from "crypto-js/aes"
import React from "react"
import { useClaimWalletMutation } from "../../../generated/graphql"
import { fetchFromKeyStore } from "../../../pages/wallet/utils/fetchFromKeystore"
import { useGetMe } from "../../../store/useGetMe"
import { useAuth } from "../../auth/AuthProvider"
import ActivatingWalletAlert from "../components/alerts/ActivatingWalletAlert"
import { useLedgerToast } from "../components/hooks/useLedgerToast"
import {
  CreateWalletProps,
  getMultiSigContract,
  getPiiHash,
  getProvider,
} from "../components/utils"
import { registerUserWithGuardian } from "./registerUserWithGuardian"
import { useGenerateClientWallet } from "./useGenerateClientWallet"

export const useProvisionClientWallet = () => {
  const [claimWallet] = useClaimWalletMutation()
  const { authToken } = useAuth()
  const getMe = useGetMe()
  const wallet = getMe.myBusiness.wallet
  const me = getMe.me
  const generateClientWallet = useGenerateClientWallet()

  const toastIdRef = React.useRef<string | number>()
  const toast = useToast()
  const ledgerToast = useLedgerToast()

  const closeCurrentToast = () => {
    if (toastIdRef.current) toast.close(toastIdRef.current)
  }

  return async ({ email, password }: CreateWalletProps) => {
    try {
      if (!wallet?.id || !wallet.multiSigAddress) throw new Error("No wallet found on business")
      if (!me) throw new Error("You're not logged in...")

      toastIdRef.current = ledgerToast({
        duration: null,
        render: ({ onClose }) => <ActivatingWalletAlert activated={false} onClose={onClose} />,
      })
      const clientWallet = await generateClientWallet({ email, password, authenticate: true })

      if (!clientWallet) throw new Error("Unable to generate wallet")
      const piiHash = getPiiHash({ email, password })
      const encryptedPK = AES.encrypt(clientWallet.privateKey, piiHash).toString()

      const provider = await getProvider()
      const multiSigContract = getMultiSigContract(wallet.multiSigAddress, provider)
      const owners = await multiSigContract.getOwners()

      if (owners.length > 2) throw new Error("Wallet already claimed.")

      const guardianAddress = await registerUserWithGuardian({
        authToken,
        userId: me.id,
        email: me.email,
        multiSigAddress: wallet.multiSigAddress,
        clientAddress: clientWallet.address,
      })

      if (!guardianAddress) throw new Error()

      const [claimWalletResponse, storePrivateKeyResponse] = await Promise.all([
        claimWallet({
          variables: { clientAddress: clientWallet.address, guardianAddress, id: wallet?.id },
        }),
        fetchFromKeyStore({ path: "store", payload: { data: encryptedPK, walletId: wallet.id } }),
      ])

      await getMe.refetch()

      if (toastIdRef.current) {
        ledgerToast.update(toastIdRef.current, {
          duration: 5000,
          render: ({ onClose }) => <ActivatingWalletAlert activated={true} onClose={onClose} />,
        })
      }
    } catch (e) {
      Sentry.captureException(e)
      closeCurrentToast()
      console.log(e)
      if (e.message === "Invalid username or password") {
        toast({ description: "Invalid password", status: "error" })
      } else {
        toast({ description: "Error activating wallet, try again...", status: "error" })
      }
    }
  }
}
