import { useCallback } from "react"
import { atom, useSetRecoilState } from "recoil"
import { useWalletBalanceLazyQuery } from "../../../generated/graphql"

type WalletBalance = { balance: number; availableCredit: number; creditLimit: number }
export const walletBalanceAtom = atom<WalletBalance>({
  key: "walletBalanceAtom",
  default: { balance: 0, availableCredit: 0, creditLimit: 0 },
})

export const useFetchWalletBalance = () => {
  const setWalletInfoState = useSetRecoilState(walletBalanceAtom)
  const [query] = useWalletBalanceLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (!data?.walletBalance) return
      setWalletInfoState(data.walletBalance as WalletBalance)
    },
  })

  return useCallback((walletId: string) => {
    if (!walletId) return
    query({ variables: { id: walletId } })
  }, [])
}
