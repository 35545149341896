import { Box, HStack } from "@chakra-ui/react"
import { ImageSearch } from "../../../../components/ImageSearch"
import { ImageUpload } from "./ImageUploader"
export default function HeaderButtons({ ImageHandler }) {
  const handleImage = (image: Record<string, number>[]) => {
    ImageHandler(image[image.length - 1].file)
  }

  const onImageSelected = (image: string) => {
    ImageHandler("", image)
  }
  return (
    <HStack alignSelf="flex-end">
      <Box marginRight="9px">
        <ImageUpload handleImage={handleImage} />
      </Box>
      <Box>
        <ImageSearch onImageSelected={onImageSelected} />
      </Box>
    </HStack>
  )
}
