import { SearchIcon } from "@chakra-ui/icons"
import { Box, BoxProps, Input, InputGroup, InputRightElement, Text } from "@chakra-ui/react"
import { debounce } from "lodash"
import { useCallback, useEffect, useState } from "react"
import { useRecoilState, useSetRecoilState } from "recoil"
import { businessQuerySelector } from "../../../store/business"
import { listingQuerySelector } from "../../../store/listing"

const defaultStyles: BoxProps = {
  w: { base: "256px", md: "400px" },
  mx: { base: "auto", md: "initial" },
}

const BusinessSearchBar = (boxProps: BoxProps) => {
  const setBusinessQuery = useSetRecoilState(businessQuerySelector)
  const [localText, setLocalText] = useState("")
  const [{ searchText }, setListingQuery] = useRecoilState(listingQuerySelector)
  const searchBusiness = useCallback(
    (text: string) => {
      const options = { page: 1, searchText: text }
      setBusinessQuery((prevState) => ({ ...prevState, ...options }))
    },
    [setBusinessQuery],
  )
  const search = useCallback(
    (text: string) => {
      const options = { page: 1, searchText: text }
      setListingQuery((prevState) => ({ ...prevState, ...options }))
    },
    [setListingQuery],
  )

  useEffect(() => {
    if (localText) {
      search(localText)
      searchBusiness(localText)
    }
  }, [localText])
  useEffect(() => {
    setLocalText(searchText)
  }, [searchText])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(debounce(search, 750), [search])

  const handleKeyPressed = (event) => {
    if (event.key === "Enter") {
      searchBusiness(localText)
      search(localText)
    }
  }

  return (
    <Box {...defaultStyles} {...boxProps}>
      <Text px={4} mb={3} fontWeight="500">
        I am looking for...
      </Text>
      <InputGroup>
        <Input
          onChange={(e) => {
            setLocalText(e.target.value)
            debouncedSearch(e.target.value)
          }}
          onKeyPress={handleKeyPressed}
          placeholder={`Search businesses`}
          value={localText}
          bg="white"
          border={"1px solid #dddddd"}
          fontSize="18px"
          fontWeight="500"
        />
        <InputRightElement zIndex={1} pointerEvents="none">
          <SearchIcon />
        </InputRightElement>
      </InputGroup>
    </Box>
  )
}

export default BusinessSearchBar
