import * as Sentry from "@sentry/react"
import axios, { AxiosRequestConfig } from "axios"
import { config } from "../../../config"

interface requestResetPasswordWithGuardianProps {
  email: string
}

export const requestResetPasswordWithGuardian = async ({
  email,
}: requestResetPasswordWithGuardianProps) => {
  try {
    if (!email) throw new Error("Recovery email not provided")
    const axiosConfig: AxiosRequestConfig = {
      method: "POST",
      url: config.GUARDIAN_ENDPOINTS.RESET,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        email,
        redirectUrl: window.location.origin,
      },
    }
    const res = await axios(axiosConfig)
    const data = res.data && res.data.sent
    return data ? true : null
  } catch (e) {
    Sentry.captureException(e)
  }
}
