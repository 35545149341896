import { Box, CloseButton, HStack, Text } from "@chakra-ui/react"
import { faCheckCircle, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const ActivatingWalletAlert = ({ onClose, activated }) => {
  return (
    <Box borderRadius={"xl"} color="white" p={3} bg={activated ? "alert.success" : "primary.main"}>
      <HStack justifyContent={"space-between"}>
        <HStack>
          {activated ? (
            <>
              <FontAwesomeIcon icon={faCheckCircle} />
              <Text ml={"1em"}>Wallet activated</Text>
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faSpinner} color="white" spin />
              <Text ml={"1em"}>Activating your wallet</Text>
            </>
          )}
        </HStack>
        <CloseButton onClick={onClose} />
      </HStack>
      <HStack justifyContent={"space-between"}>
        <Text ml={"1.5em"} mt={"1em"}>
          {activated
            ? "You're good to go!"
            : "Deploying your wallet to the blockchain, sit tight..."}
        </Text>
      </HStack>
    </Box>
  )
}

export default ActivatingWalletAlert
