/* eslint-disable react/display-name */
import { ethers } from "ethers"
import { useEffect, useState } from "react"
import { useRecoilState } from "recoil"
import { useFindTransactionByTxIdLazyQuery } from "../../../../generated/graphql"
import { useGetMe } from "../../../../store/useGetMe"
import { transactionAlertAtom } from "../../store/transactionAlert"
import { walletListenSelector } from "../../store/wallet"
import { useFetchWalletBalance } from "../../store/walletBalance"
import { MultiSigWallet } from "../../types"
import { getMultiSigContract, getProvider } from "../utils"

export const useWalletEventListener = () => {
  const { wallet } = useGetMe().myBusiness
  const [multiSigContract, setMultiSigContract] = useState<MultiSigWallet | null>(null)
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout>()
  const fetchWalletBalance = useFetchWalletBalance()

  const [shouldListen, setShouldListen] = useRecoilState(walletListenSelector)
  const [txAlert, setTxAlert] = useRecoilState(transactionAlertAtom)

  const [findTransaction, { data, loading }] = useFindTransactionByTxIdLazyQuery()

  // init multiSigContract
  useEffect(() => {
    if (multiSigContract) return
    getProvider().then((provider) => {
      if (!multiSigContract && wallet?.multiSigAddress) {
        setMultiSigContract(getMultiSigContract(wallet.multiSigAddress, provider))
      }
    })
  }, [wallet])

  // register listeners
  useEffect(() => {
    if (multiSigContract && shouldListen) {
      multiSigContract.once("Execution", async (args) => {
        const transactionId = ethers.utils.formatUnits(args, "wei")

        let tries = 0
        // poll for transaction for 10 seconds then abort
        const intervalId = setInterval(() => {
          findTransaction({ variables: { txId: transactionId } })
          if (tries === 10) clearInterval(intervalId)
          tries++
        }, 10000)
        setIntervalId(intervalId)
      })

      multiSigContract.once("ExecutionFailure", async (args) => {
        const transactionId = ethers.utils.formatUnits(args, "wei")

        let tries = 0
        // poll for transaction for 10 seconds then abort
        const intervalId = setInterval(() => {
          findTransaction({ variables: { txId: transactionId } })
          if (tries === 10) clearInterval(intervalId)
          tries++
        }, 10000)
        setIntervalId(intervalId)
      })

      multiSigContract.once("Submission", () => {
        setTxAlert((prevState) => ({
          ...prevState,
          state: "CONFIRMING",
        }))
      })
      setShouldListen(false)
    }
  }, [shouldListen, multiSigContract])

  // update toast
  useEffect(() => {
    if (data?.findTransactionByTxId?.id && wallet && !loading && intervalId) {
      const transaction = data.findTransactionByTxId
      if (transaction.status === "FAILED")
        setTxAlert((prevState) => ({
          ...prevState,
          state: "FAILED",
          transactionId: transaction.id,
        }))
      else
        setTxAlert((prevState) => ({
          ...prevState,
          state: "CONFIRMED",
          transactionId: transaction.id,
        }))
      clearInterval(intervalId)
      multiSigContract?.removeAllListeners()
      fetchWalletBalance(wallet.id)
    }
  }, [data, loading])
}
