import { Business, useMeQuery, User, Wallet } from "../generated/graphql"
import { useAuth } from "../services/auth/AuthProvider"

export const useGetMe = () => {
  const { hasFetchedToken } = useAuth()
  const query = useMeQuery({ skip: !hasFetchedToken })
  const me = (query.data?.me ?? {}) as User
  const myBusiness = (me.business ?? {}) as Business
  const myWallet = (myBusiness.wallet ?? {}) as Wallet
  const refetchMe = query.refetch

  return { me, myBusiness, myWallet, refetchMe, ...query }
}
