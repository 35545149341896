const spinnerStyles: SpinnerProps = {
  position: { base: "absolute", md: "inherit" },
  top: { base: 0, md: 10 },
  right: { base: 2, md: 10 },
}

import { Box, Container, Flex, Spinner, Stack, SpinnerProps } from "@chakra-ui/react"
import { useEffect } from "react"
import { useHistory } from "react-router"
import { useRecoilState } from "recoil"
import Pagination from "../../../components/Pagination"
import { PurpleBackdrop } from "../../../components/PurpleBackdrop"
import { useSearchListingsQuery } from "../../../generated/graphql"
import { listingQuerySelector } from "../../../store/listing"
import { DirectoryGrid } from "./DirectoryGrid"
import { ListingFulfillmentFilter, ListingTypeFilter, MarketFilter } from "./FilterMenus"
import ListingSearchBar from "./ListingSearchBar"

export const ListingDirectory = (props) => {
  const [{ page, pageSize, marketName }, setQuery] = useRecoilState(listingQuerySelector)
  const { listings, total, loading, called } = useListingDirectoryData()
  const history = useHistory()
  const changePage = (page: number) => {
    history.push({ search: `?page=${page}` })
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const page = parseInt(params.get("page") ?? "0")
    setQuery((prevState) => ({ ...prevState, page }))
  }, [location.search])

  return (
    <>
      <Container maxW="container.xl" px={0}>
        <Stack
          w="full"
          mb={12}
          direction={{ base: "column", md: "row" }}
          alignItems={{ base: "center", md: "flex-end" }}
          spacing={4}
        >
          <ListingSearchBar w={{ base: "320px", md: "500px" }} flexGrow={1} {...props} />
          <Flex>
            <ListingTypeFilter mx={1} />
            <ListingFulfillmentFilter mx={1} />
            <MarketFilter
              mx={1}
              market={marketName}
              setMarket={(name) => setQuery((p) => ({ ...p, marketName: name }))}
            />
          </Flex>
          <Box {...spinnerStyles}>
            <Spinner visibility={loading ? "visible" : "hidden"} />
          </Box>
          <Pagination
            total={total}
            current={page}
            pageSize={8}
            handleChange={changePage}
            pt={{ base: 4, md: "initial" }}
            w={{ base: "initial", md: "350px" }}
            ml={{ base: 0, md: "auto !important" }}
            mr={{ base: 0, md: "24px !important" }}
            pb={1}
          />
        </Stack>
        {loading ? (
          <Box
            position="fixed"
            top="80px"
            right="0"
            left="0"
            bottom="0"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Spinner size="xl" color="blue.500" thickness="4px" />
          </Box>
        ) : (
          <PurpleBackdrop bgColor={"#FBFAFE"}>
            <Container maxW="container.xl" px={{ base: 0, md: 6 }}>
              <DirectoryGrid
                type="listing"
                total={total}
                current={page}
                called={called}
                items={listings}
                loading={loading}
                pageSize={24}
                {...props}
              />
              <Container marginTop="2em">
                <Pagination
                  total={total}
                  current={page}
                  pageSize={pageSize}
                  handleChange={changePage}
                />
              </Container>
            </Container>
          </PurpleBackdrop>
        )}
      </Container>
    </>
  )
}

const useListingDirectoryData = () => {
  const [{ page, pageSize, searchText }] = useRecoilState(listingQuerySelector)
  const query = useSearchListingsQuery({
    variables: {
      page: page,
      limit: pageSize,
      query: searchText,
      filters: "",
    },
  })

  const listings = query.data?.searchListings.listings ?? []
  const total = query.data?.searchListings.total ?? 0

  return { listings, total, ...query }
}
