import { useEffect } from "react"
import { Route, Switch, useLocation } from "react-router-dom"
import { useSearchParam } from "react-use"
import { useAnalytics } from "use-analytics"
import { LoginPage } from "./pages/auth/LoginPage"
import { RequestResetPassword } from "./pages/auth/RequestResetPassword"
import { RegisterPage } from "./pages/auth/RegisterPage"
import Admin from "./pages/auth/components/Admin"
import MarketplaceRoutes from "./pages/marketplace/MarketplaceRoutes"
import CommingSoonPage from "./components/ComingSoonPage"
import { useAuth } from "./services/auth/AuthProvider"
import { useGetMe } from "./store/useGetMe"
import { useSetRecoilState } from "recoil"
import { businessQuerySelector } from "./store/business"
import { listingQuerySelector } from "./store/listing"

const Routes = () => {
  const { loading } = useGetMe()
  const { page } = useAnalytics()
  const location = useLocation()
  const inviteCode = useSearchParam("code")
  const { hasFetchedToken } = useAuth()

  useSetDefaultMarketFilters()

  useEffect(() => {
    if (inviteCode) localStorage.setItem("inviteCode", inviteCode)
  }, [inviteCode])

  useEffect(() => {
    page()
  }, [location, page])

  if (!hasFetchedToken || loading) return null // could return loader, or splash page, etc.
  return (
    <Switch>
      <Route exact path="/" component={LoginPage} />
      <Route exact path="/login" component={LoginPage} />
      <Route path="/request-reset-password" component={RequestResetPassword} />
      <Route path="/register" component={RegisterPage} />
      <Route path="/admin" component={Admin} />
      <Route path="/marketplace" component={MarketplaceRoutes} />
      <Route exact path="/marketplace/listing/edit" component={Admin} />
      <Route path="/dashboard" component={CommingSoonPage} />
      <Route path="/users" component={CommingSoonPage} />
      <Route path="/settings" component={CommingSoonPage} />
      <Route path="/business" component={CommingSoonPage} />
      <Route path="/tools" component={CommingSoonPage} />
      <Route path="/orders" component={CommingSoonPage} />
      <Route path="/transactions" component={CommingSoonPage} />
      <Route path="/ambassadors" component={CommingSoonPage} />
      <Route path="/dashboard" component={CommingSoonPage} />
      <Route path="/listings" component={CommingSoonPage} />
      ambassadors
    </Switch>
  )
}
const useSetDefaultMarketFilters = () => {
  const setListingSearch = useSetRecoilState(listingQuerySelector)
  const setBusinessSearch = useSetRecoilState(businessQuerySelector)
  const marketName = useGetMe().myBusiness?.markets?.[0]?.name

  useEffect(() => {
    if (!marketName) return
    setBusinessSearch((prev) => ({ ...prev, marketName }))
    setListingSearch((prev) => ({ ...prev, marketName }))
  }, [marketName])
}
export default Routes
