/* eslint-disable react/display-name */
import { useToast } from "@chakra-ui/react"
import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { transactionAlertAtom } from "../../store/transactionAlert"
import { useLedgerToast } from "../hooks/useLedgerToast"
import ConfirmingTransactionAlert from "./ConfirmingTransactionAlert"
import SubmittingTransactionAlert from "./SubmittingTransactionAlert"
import ViewTransactionAlert from "./ViewTransactionAlert"

const TransactionAlert = () => {
  const ledgerToast = useLedgerToast()
  const toast = useToast()
  const history = useHistory()
  const toastIdRef = React.useRef<string | number>()
  const transactionAlertState = useRecoilValue(transactionAlertAtom).state
  const transactionId = useRecoilValue(transactionAlertAtom).transactionId

  const closeToast = () => {
    if (toastIdRef.current) toast.close(toastIdRef.current)
  }

  const toTransaction = (transactionId) => {
    history.push(`/wallet/transactions/${transactionId}`)
  }

  useEffect(() => {
    if (!transactionAlertState) {
      closeToast()
      return
    }
    if (transactionAlertState === "SUBMIT") {
      if (!toastIdRef.current) {
        toastIdRef.current = ledgerToast({
          duration: null,
          render: ({ onClose }) => <SubmittingTransactionAlert onClose={onClose} />,
        })
      } else {
        ledgerToast.update(toastIdRef.current, {
          duration: null,
          render: ({ onClose }) => <SubmittingTransactionAlert onClose={onClose} />,
        })
      }
    } else if (transactionAlertState === "CONFIRMING" && toastIdRef.current) {
      ledgerToast.update(toastIdRef.current, {
        duration: null,
        render: ({ onClose }) => <ConfirmingTransactionAlert onClose={onClose} />,
      })
    } else if (transactionAlertState === "CONFIRMED" && transactionId && toastIdRef.current) {
      ledgerToast.update(toastIdRef.current, {
        duration: 5000,
        render: ({ onClose }) => (
          <ViewTransactionAlert
            onClose={onClose}
            success={true}
            onClick={() => toTransaction(transactionId)}
          />
        ),
      })
      toastIdRef.current = ""
    } else if (transactionAlertState === "FAILED" && toastIdRef.current) {
      ledgerToast.update(toastIdRef.current, {
        duration: 5000,
        render: ({ onClose }) => (
          <ViewTransactionAlert
            onClick={() => toTransaction(transactionId)}
            success={false}
            onClose={onClose}
          />
        ),
      })
      toastIdRef.current = ""
    }
  }, [transactionAlertState])
  return <></>
}

export default TransactionAlert
