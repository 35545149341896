import { useMutation } from "@apollo/client"
import { ChevronDownIcon } from "@chakra-ui/icons"
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuDivider,
  MenuItem,
  MenuItemProps,
  MenuList,
  MenuOptionGroup,
} from "@chakra-ui/react"
import { useState } from "react"
import { useCreateMaraket } from "../../../../generated/graphql"
import AddListingModal from "./AddListingModal"

const MarketMenu = (props) => {
  const { allMarkets, handleSelectMarket, currentMarket, isMarketPlace } = props
  const [newMarket, setNewMarket] = useState("")
  const [markets, setMarkets] = useState(allMarkets)
  const [addMarket, { loading, error }] = useMutation(useCreateMaraket)
  if (loading) console.log("Submitting...")
  if (error) console.log("Error", error.message)

  const handleSubmit = () => {
    if (newMarket) {
      setMarkets([
        {
          market: {
            name: newMarket,
          },
        },
        ...markets,
      ])
      setNewMarket("")
    }
    addMarket({
      variables: {
        data: {
          name: newMarket,
        },
      },
    })
  }

  return (
    <Menu closeOnSelect={true}>
      <MenuButton
        colorScheme="blue"
        opacity={isMarketPlace ? 0.5 : 1}
        cursor={isMarketPlace ? "no-drop" : "pointer"}
        rightIcon={<ChevronDownIcon width={"32px"} height={"80px"} />}
        as={Button}
        {...menuButtonStyles}
        _hover={{ background: "#957FEF" }}
        _active={{
          background: "#7161EF",
        }}
        className="menu-styles"
      >
        {currentMarket?.market?.name}
      </MenuButton>

      <MenuList
        maxWidth="220px"
        radius="8px"
        display={isMarketPlace ? "none" : "block"}
        maxH="80vh"
        overflowY={"auto"}
        className="marketlist"
      >
        <AddListingModal
          newMarket={newMarket}
          setNewMarket={setNewMarket}
          handleSubmit={handleSubmit}
        />
        <MenuDivider />

        <MenuOptionGroup type="radio" zIndex={1}>
          {markets?.map(
            (catalog, index) =>
              catalog?.market && (
                <Box key={index}>
                  <MenuItem
                    {...menuItemStyles}
                    _hover={{ bg: "#FBFAFE" }}
                    onClick={() => handleSelectMarket(catalog.id)}
                  >
                    {catalog?.market?.name}
                  </MenuItem>
                </Box>
              ),
          )}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  )
}
const menuItemStyles: MenuItemProps = {
  pl: "3",
  color: "black",
  fontSize: "14px",
  fontWeight: "400",
  w: "full",
  borderRadius: "0",
}
const menuButtonStyles: MenuButtonProps = {
  placeholder: "Market",
  bg: "#957FEF",
  color: "#FFFFFF",
  fontFamily: "Poppins",
  margin: "0px 10px",
  border: "1px solid #BDBDBD",
  boxSizing: "border-box",
  borderRadius: "8px",
  width: "220px",
  justifyContent: "space-between",
  height: "48px",
}
export default MarketMenu
