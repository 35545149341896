import { useMutation } from "@apollo/client"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Text,
  Button,
  useDisclosure,
  Heading,
  useToast,
  ButtonProps,
} from "@chakra-ui/react"
import { FaTrash } from "react-icons/fa"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { UpdateCatalogDocument } from "../../../../generated/graphql"
import { removeListingAtom, SelectedCatalogAtom, SublistIdAtom } from "../../../../store/listing"
import { CatalogMutation } from "../CatalogMutation"

export default function RemoveModal(props) {
  const { id, setRemove, hover, setHover, remove } = props
  const setRemoveListing = useSetRecoilState(removeListingAtom)
  const [catalogInput] = useMutation(UpdateCatalogDocument)
  const selectedCatalog: any = useRecoilValue(SelectedCatalogAtom)
  const sublistId = useRecoilValue(SublistIdAtom)
  const coverPageListingId = localStorage.getItem("sublistId")
  const toast = useToast()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleRemove = (e) => {
    e.stopPropagation()
    setRemove(!remove)
    const updatedCatalog = JSON.parse(JSON.stringify(selectedCatalog))
    updatedCatalog?.lists.map((obj) => {
      if (obj !== undefined) {
        obj.sublists.map((childObj) => {
          if (childObj.id === sublistId || childObj.id === coverPageListingId) {
            childObj.listings = childObj?.listings.filter((list) => list.id !== id)
            return childObj
          }
        })
        return obj
      }
    })
    CatalogMutation(catalogInput, updatedCatalog, toast)
    setRemoveListing({
      state: true,
      id: id,
    })
    onClose()
  }

  const handleRemoveClick = (e) => {
    onOpen(), e.stopPropagation()
  }
  return (
    <>
      <Button
        leftIcon={<FaTrash />}
        {...btnStyles}
        bg={hover ? "red" : "transparent"}
        color={hover ? "white" : "transparent"}
        _hover={{ bg: "red", color: "white" }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={handleRemoveClick}
      >
        Remove from list
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="md">
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Heading fontSize="28px" fontWeight="600" h="55px" mt="4">
              Remove listing
            </Heading>
            <Text fontSize="18px" fontWeight="500" color="#333333" h="55px">
              Remove this listing from the list?
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button w="114px" mr={5} onClick={onClose} bg="#F2F2F2" color="#595959">
              Cancel
            </Button>
            <Button onClick={handleRemove} bg="#FF3C38" w="124px">
              Remove
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
const btnStyles: ButtonProps = {
  h: "37px",
  borderRadius: "16px",
  fontSize: "14px",
  w: "180px",
  mt: "-4.5rem",
}
